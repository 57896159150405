import { fetch } from "../axios.service";
import { WALLET_BASE_URL } from "../../Constants/constant";

export const getAllDepositesApi = (data, options) => {
  return fetch("post", `${WALLET_BASE_URL}/admin/getAllDeposits`, data, options);
};

export const depositeFilterDataApi = (coin, data, options) => {
  return fetch("post", `${WALLET_BASE_URL}/${coin}/admin/deposit_transactions_search/${data.page}`, data, options);
};
