import React, { Component } from "react";
import { Container, Row, Col } from "shards-react";
import PageTitle from "../../../Components/common/PageTitle";
import { connect } from "react-redux";
import Select from 'react-select';
import axios from "axios";
import {
  Table,
  Form,
  Button,
  ListGroup,
  Card,
  Tabs,
  Tab,
  Label,
  Accordion,
  OverlayTrigger,
  Tooltip,
  Nav,
} from "react-bootstrap";
import {
  statisticsFn,
  downlineFn,
  singleTraderInfoFn,
  usersWithdrawTransFn,
  userBalanceFn,
  addNotesFn
} from "../../../Redux/Actions/user/user.action";

import { activeCoinsFn } from "../../../Redux/Actions/dashboard/dashboard.action";

import { Pagination, Icon, Menu, Input, Segment } from "semantic-ui-react";
import UpdateAccDetailModal from "./updateAccDetailModal";
import dateFormat from 'dateformat';
import { offset, limit } from "../../../Constants/constant";
import EditUserForm from "./editUserForm";
import "../../../index.css";
import queryString from "query-string";
import AddNotesModal from "./addNotesModal";

class EditUser extends Component {
  constructor() {
    super();
    this.state = {
      showAccModal: false,
      traderId: "",
      currencyCoins: "",
      page: 1,
      activeItem: "Personal Details",
      showNotesModal: false,
      selectedCoins: "Select Coins"
    };
  }

  componentDidMount = () => {
    let {
      usersWithdrawTransFn,
      statisticsFn,
      downlineFn,
      singleTraderInfoFn,
      activeCoinsFn,
      userBalanceFn,
    } = this.props;

    let traderId = this.props.match.params.traderId;

    this.setState({ traderId: traderId });

    singleTraderInfoFn(traderId);
    activeCoinsFn();

    let transParams = {
      currency: "btc",
      traderID: traderId,
      page: this.state.page,
    };

    usersWithdrawTransFn(transParams);

    let userId = {
      user_id: traderId,
    };
    userBalanceFn(userId);
  };

  updateUserInfo = (values) => {
    alert(JSON.stringify(values));
  };

  pageChange = (e, data) => {
    let page = data.activePage;
    let pageNo = page === 1 ? 0 : (page - 1) * limit;
  };

  getBankAccInfo = () => {
    this.setState({ showAccModal: true });
  };

  confirmAction = (accountId, type, currency) => {
    if (window.confirm("Are you sure want to close this acount?")) {
      alert("Done");
    }

    this.setState({ showAccModal: false });
  };

  editUserInfo = (values) => {
    alert(JSON.stringify(values));
  };

  getValue = (e) => {
    this.setState({
      currencyCoins: e
    })
    let transParams = {
      currency: e,
      traderID: this.state.traderId,
      page: this.state.page,
    };

    this.props.usersWithdrawTransFn(transParams);

  };

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  addNotes = (noteData) => {

    let notesParams = {
      member_id: this.state.traderId,
      note: noteData.notes
    }

    this.props.addNotesFn(notesParams)
    this.setState({ showNotesModal: false })
  };
  onchangeSelectedCoins = () => {
    this.setState({
      selectedCoins: this.state.selectedCoins
    })
  }
  documentsType = (type) => {
    // console.log(type, "dgdgdg")
    if (type !== 6) {

      if (type == 2) {
        return "Passport"
      }
      else if (type == 1) {
        return "Identity Card"
      }
    }


  }
  verifcationStatus = (status) => {
    if (status == 1) {
      return "Submittted"
    }
    else if (status == 1) {
      return "Identity Card"
    } else if (status == 2) {
      return "declined"
    } else if (status == 3) {
      return "Re-submitted"
    }
  }
  render() {
    let {
      statisticsData,
      downlineData,
      singleTraderData,
      usersWithdrawData,
      activeCoins,
      userAllBalance,
      fxtBalance,
      ethBalance,
      btcBalance,
      usdtBalance,
      bchBalance,
    } = this.props;

    let { activeItem } = this.state;

    function preciseTodecimal(num) {
      var exponentialNumber = (num / 10 ** 8);

      const str = exponentialNumber.toString();
      if (str.indexOf("e") !== -1) {
        const exponent = parseInt(str.split("-")[1], 8);
        const result = exponentialNumber.toFixed(8);

        return result;
      } else {
        return exponentialNumber;
      }

    }
    return (

      <Container>
        <Row noGutters className="page-header py-4">
          <Col lg={12}>
            <PageTitle
              title="Traders"
              subtitle=""
              className="text-sm-left mb-3"
            />
          </Col>
        </Row>

        <br />

        <Card>
          <Card.Body>
            <Card.Text>
              <div>
                <Menu pointing>
                  <Menu.Item
                    name="Personal Details"
                    active={activeItem === "Personal Details"}
                    onClick={this.handleItemClick}
                  />

                  <Menu.Item
                    name="Verification"
                    active={activeItem === "Verification"}
                    onClick={this.handleItemClick}
                  />
                  <Menu.Item
                    name="User Balance"
                    active={activeItem === "User Balance"}
                    onClick={this.handleItemClick}
                  />
                  <Menu.Item
                    name="User Transactions"
                    active={activeItem === "User Transactions"}
                    onClick={this.handleItemClick}
                  />
                  {/* <Menu.Item
                    name="User Referral"
                    active={activeItem === "User Referral"}
                    onClick={this.handleItemClick}
                  /> */}

                  {/* <Menu.Item
                    name="Notes"
                    active={activeItem === "Notes"}
                    onClick={this.handleItemClick}
                  /> */}
                </Menu>

                <Segment>
                  {activeItem == "Personal Details" ? (
                    <>
                      {singleTraderData != undefined &&
                        Object.keys(singleTraderData).length > 0 ? (
                        <>
                          <Row>
                            <Col>
                              <label class="font-weight-bold">Name</label>
                              <p>{singleTraderData?.user_firstname} {singleTraderData?.user_lastname}</p>
                            </Col>
                            <Col>
                              <label class="font-weight-bold">
                                Email Address
                              </label>
                              <p>{singleTraderData.email}</p>
                            </Col>
                            {/* <Col>
                              <label class="font-weight-bold">City</label>
                              <p>{singleTraderData.user_city}</p>
                            </Col> */}
                          </Row>
                          <br />
                          <Row>
                            {/* <Col>
                              <label class="font-weight-bold">
                                Phone Number
                              </label>
                              <p>
                                {singleTraderData.auth_phone != null
                                  ? singleTraderData.auth_phone
                                  : "N/A"}
                              </p>
                            </Col> */}
                            <Col>
                              <label class="font-weight-bold">Country</label>
                              <p>{singleTraderData.user_country}</p>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <tbody>
                          <tr>


                            <td colSpan="6" className="text-center">No Record Found</td>
                          </tr>

                        </tbody>
                      )}
                    </>
                  ) : activeItem == "Verification" ? (
                    <>
                      <br />
                      <Row>
                        <Col>
                          <label class="font-weight-bold">ID Type</label>
                        </Col>
                        <Col>
                          <label class="font-weight-bold">
                            Verification status
                          </label>
                        </Col>
                      </Row>
                      <Row>{singleTraderData?.status === null ? "Kyc not submit" : singleTraderData?.documents?.map((item) =>

                        item.type == 2 ? <Col>Identity Card</Col> : item.type == 0 ? <Col>Passport</Col> : ""


                      )}
                        <Col>
                          {singleTraderData?.status == 0 && "Submitted"}
                          {singleTraderData?.status == 1 && "Approved"}
                          {singleTraderData?.status == 2 && "declined"}
                          {singleTraderData?.status == 3 && "Re-submitted"}
                          {/* {this.verifcationStatus(singleTraderData?.status)} */}
                        </Col>

                      </Row>

                    </>
                  ) : activeItem == "User Balance" ? (
                    <Table striped bordered hover responsive>
                      <thead>
                        <tr>
                          <th>Pair</th>
                          <th>Balance</th>
                          <th>Locked Balance</th>
                        </tr>
                      </thead>
                      {userAllBalance.length ? userAllBalance && userAllBalance.map((coins, index) => {

                        return (
                          <tbody>
                            <tr>
                              <td>{coins.coin.toUpperCase()}</td>
                              <td>{preciseTodecimal(coins.balance)}</td>
                              <td>{preciseTodecimal(coins.locked_balance)}</td>
                            </tr>

                          </tbody>

                        )
                      }) : (
                        <tbody>
                          <tr>


                            <td colSpan="3" className="text-center">No Record Found</td>
                          </tr>

                        </tbody>





                      )

                      }
                    </Table>)
                    : activeItem == "User Transactions" ? (
                      <>
                        <Row>
                          <Col xl={2} sm={4} xs={2}>
                            <Form.Group controlId="formGridState">
                              {/* <Form.Label>Kyc status</Form.Label> */}
                              <Form.Control
                                as="select"
                                defaultValue={this.state.currencyCoins}
                                value={this.state.currencyCoins}
                                id="kycStatus"
                                onChange={(e) => this.getValue(e.target.value)}
                              >
                                {this.state.currencyCoins == "" && <option className="d-none" value="">Select Coin</option>}
                                {activeCoins.map((coins, index) => (
                                  <option key={index} value={coins}>{coins.toUpperCase()}</option>
                                ))
                                }

                              </Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                        <br />
                        {usersWithdrawData != undefined &&
                          usersWithdrawData.length > 0 ? (
                          <Table striped bordered hover responsive>
                            <thead>
                              <tr>
                                <th>txid</th>
                                <th>address from</th>
                                <th>address to</th>
                                <th>amount</th>
                                <th>created date</th>
                                <th>status</th>
                              </tr>
                            </thead>

                            {usersWithdrawData.map((withDrawItem, index) => {

                              return <tbody>

                                <tr>
                                  <td>{withDrawItem.tx_id}</td>
                                  <td>{withDrawItem.from_address}</td>
                                  <td>{withDrawItem.to_address}</td>
                                  <td>{preciseTodecimal(withDrawItem.amount)}</td>
                                  <td>{dateFormat(withDrawItem.created_at, "dd-mm-yyyy")}</td>
                                  {withDrawItem.status == 1 ?
                                    <td>Unconfirmed</td> :
                                    withDrawItem.status == 0 ? <td>Waiting for admin approval </td> :
                                      <td>Confirmed</td>}
                                </tr>


                              </tbody>
                            })}
                          </Table>
                        ) : (

                          <Table striped bordered hover responsive>
                            <thead>
                              <tr>
                                <th>txid</th>
                                <th>address from</th>
                                <th>address to</th>
                                <th>amount</th>
                                <th>type</th>
                                <th>created date</th>
                                <th>status</th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr>


                                <td colSpan="7" className="text-center">No Record Found</td>
                              </tr>

                            </tbody>
                          </Table>
                        )}


                        <br />
                      </>
                    ) :
                      activeItem == "User Referral" ? (
                        <>
                          <Table striped bordered hover responsive>
                            <thead>
                              <tr>
                                <th>Level</th>
                                <th>Email Address</th>
                                <th>KYC Status</th>
                                <th>amount</th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr>


                                <td colSpan="4" className="text-center">No Record Found</td>
                              </tr>

                            </tbody>
                          </Table>
                        </>
                      ) : (
                        activeItem == "Notes" && (
                          <>
                            <Row>
                              <Col>
                                <Button
                                  varient="primary"
                                  onClick={() =>
                                    this.setState({ showNotesModal: true })
                                  }
                                >
                                  Add Notes
                            </Button>
                              </Col>
                            </Row>
                            <br />
                            <Table striped bordered hover responsive>
                              <thead>
                                <tr>
                                  <th>Notes</th>
                                  <th>Created At</th>
                                  <th>Updated At</th>
                                  <th>Action</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>


                                  <td colSpan="4" className="text-center">No Record Found</td>
                                </tr>

                              </tbody>
                            </Table>
                          </>
                        )
                      )}
                </Segment>
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
        <AddNotesModal
          show={this.state.showNotesModal}
          closeModal={() => this.setState({ showNotesModal: false })}
          handleNotesForm={this.addNotes}
        />
        <br />


      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    statisticsData: state.user.statisticsData,
    downlineData: state.user.downlineData,
    singleTraderData: state.user.singleTraderData,
    usersWithdrawData: state.user.usersWithdrawData,
    activeCoins: state.dashboard.activeCoins,

    fxtBalance: state.user.fxtBalance,
    ethBalance: state.user.ethBalance ? state.user.ethBalance : 0,
    btcBalance: state.user.btcBalance ? state.user.btcBalance : 0,
    usdtBalance: state.user.usdtBalance ? state.user.usdtBalance : 0,
    bchBalance: state.user.bchBalance,
    userAllBalance: state.user.userAllBalance
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    usersWithdrawTransFn: (data) => dispatch(usersWithdrawTransFn(data)),
    statisticsFn: (data) => dispatch(statisticsFn(data)),
    downlineFn: (data) => dispatch(downlineFn(data)),
    singleTraderInfoFn: (data) => dispatch(singleTraderInfoFn(data)),
    activeCoinsFn: (data) => dispatch(activeCoinsFn(data)),
    userBalanceFn: (data) => dispatch(userBalanceFn(data)),
    addNotesFn: (data) => dispatch(addNotesFn(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
