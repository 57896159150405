import * as types from "../../../Constants/_Types/types.reduxStore";

const initialState = {
  BuyCrypto: [],
  sellCrytpo:[],
  withdrawdata:[],
  Exchanges:{}
};

export default function Pnldata(state = initialState, action) {
  // console.log("action",action.payload)
  switch (action.type) {
    case types.BUY_CRYPTO:
      return { ...state, BuyCrypto: action.payload };
    case types.SELL_CRYPTO:
      return { ...state, sellCrytpo: action.payload };
    case types.With_draw:
        return { ...state, withdrawdata: action.payload };  
    case types.Exchange_trade:
          return { ...state, Exchanges: action.payload };      
    default:
      return state;
  }
}
