import React from "react";
import { Container, Row, Col } from "shards-react";
import { Table, Form, Button, Dropdown } from "react-bootstrap";
import { Pagination, Icon } from "semantic-ui-react";
import { Link, Redirect } from "react-router-dom";
import PageTitle from "../../common/PageTitle";
import {} from "../../../Redux/Actions/user/user.action";
import { getETHUsersFn } from "../../../Redux/Actions/dashboard/dashboard.action";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "rc-datepicker/lib/style.css";

import { connect } from "react-redux";
import "moment/locale/en-gb";
import {
  offset,
  limit,
  USER_TYPE,
  COUNTRY,
  KYC_LIMIT,
  KYC_STATUS,
} from "../../../Constants/constant";
import queryString from "query-string";
import "../user/Users.css";
import "../../../App.css";
import dateFormat from "dateformat";
import ImportCsv from "../../common/common";
import moment from "moment";
import { convertTZ } from "../../common/helper";

class Users extends React.Component {
  constructor() {
    super();
    this.state = {
      articles: [],
      articles1: [],
      begin: 0,
      end: 10,
      activePage: 1,
      searchItem: "",
    };
    this.btnClick = this.btnClick.bind(this);
  }

  async btnClick(event, data) {
    await this.setState({ activePage: data.activePage });
    await this.setState({ begin: this.state.activePage * 10 - 10 });
    await this.setState({ end: this.state.activePage * 10 });
    this.setState({
      articles1: this.state.articles.slice(this.state.begin, this.state.end),
    });
  }

  componentDidMount = () => {
    let { getETHUsersFn } = this.props;
    getETHUsersFn();
  };

  componentWillReceiveProps = (nextProps) => {
    // console.log("nextProps",nextProps)
    let { getETHuser } = this.props;

    this.setState({ articles: getETHuser });
    this.setState({ articles1: getETHuser });
  };

  getFilterInputData = (e, type) => {
    let { searchItem } = this.state;
    this.setState({ searchItem: e.target.value });
    this.setState({ activePage: 1 });
    this.setState({ begin: this.state.activePage * 10 - 10 });
    this.setState({ end: this.state.activePage * 10 });

    const result = this.state.articles1.filter((data, index) => {
      return data?.id.toLowerCase()?.includes(e.target.value?.toLowerCase());
    });
    this.setState({ articles: result });
    //    console.log("result",result)
  };

  render() {
    let { getETHuser } = this.props;
    // let { searchedData, filterData, disabled , articles} = this.state;
    // this.state.articles = [...getETHuser]

    // { console.log("pages", this.state.articles) }
    // console.log(this.state.searchItem)
    // console.log(this.state.end)

    return (
      <div>
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <Col lg={12}>
              <PageTitle
                title="FIRE BLOCKS"
                subtitle=""
                className="text-sm-left mb-3"
              />
            </Col>
          </Row>

          <Row>
            <Col style={{ textAlign: "right" }}>
              <Form.Group>
                <Form.Control
                  type="search"
                  name="search"
                  id="search"
                  value={this.state.searchItem}
                  placeholder="Search Coin"
                  onChange={(e) => this.getFilterInputData(e)}
                  // disabled={disabled}
                />
              </Form.Group>
            </Col>

            <Col style={{ textAlign: "right" }}>
              <ImportCsv
                stateCsvData={this.state.articles}
                file_name={"Submitted Kyc"}
              />
            </Col>
          </Row>

          <Row className="justify-content-end mt-4 p-3 bg-white">
            {getETHuser != undefined && getETHuser.length > 0 ? (
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th className="pl-3 text-nowrap">ID</th>
                    <th className="pl-3 text-nowrap">Coin</th>
                    <th className="pl-3 text-nowrap">Balance</th>
                    <th className="pl-3 text-nowrap">total</th>
                  </tr>
                </thead>
                <tbody>
                  {getETHuser
                    ?.filter((el) => {
                      if (this.state?.searchItem == "") {
                        return el;
                      } else {
                        // console.log("searchItem", el);
                        return el?.id
                          ?.toLowerCase()
                          ?.includes(this.state?.searchItem?.toLowerCase());
                      }
                    })
                    ?.slice(this.state?.begin, this.state?.end)
                    ?.map((getETHuser, i) => {
                      // let getcoin = getETHuser?.id?.split("_")
                      return (
                        <tr>
                          <td>{getETHuser?.id ?? ""}</td>

                          <td className="pl-3 text-nowrap">
                            {getETHuser?.id ?? ""}
                          </td>

                          <td className="pl-3 text-nowrap">
                            {getETHuser?.balance ?? 0}
                          </td>

                          <td className="pl-3 text-nowrap">
                            {getETHuser?.total ?? 0}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            ) : (
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th className="pl-3 text-nowrap">ID</th>
                    <th className="pl-3 text-nowrap">Coin</th>
                    <th className="pl-3 text-nowrap">Balance</th>
                    <th className="pl-3 text-nowrap">total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="4" className="text-center">
                      No Record Found
                    </td>
                  </tr>
                </tbody>
              </Table>
            )}
            {getETHuser.length > 1 ? (
              <Pagination
                defaultActivePage={1}
                activePage={this.state.activePage}
                onPageChange={this.btnClick}
                ellipsisItem={{
                  content: <Icon name="ellipsis horizontal" />,
                  icon: true,
                }}
                firstItem={{
                  content: <Icon name="angle double left" />,
                  icon: true,
                }}
                lastItem={{
                  content: <Icon name="angle double right" />,
                  icon: true,
                }}
                prevItem={{ content: <Icon name="angle left" />, icon: true }}
                nextItem={{ content: <Icon name="angle right" />, icon: true }}
                totalPages={Math.ceil(getETHuser?.length / 10)}
              />
            ) : (
              ""
            )}
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getETHuser: state.dashboard.getETHuser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //   dashboardDetailsFn: (data) => dispatch(dashboardDetailsFn(data))

    getETHUsersFn: (data) => dispatch(getETHUsersFn(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
