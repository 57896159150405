import * as types from "../../../Constants/_Types/types.reduxStore";

const initialState = {
  twoFastatusData: {},
  enableGoogleAuthData: {}
}

export default function Profile(state = initialState, action) {
  switch (action.type) {

    case types.TWO_FASTATUS:
      return { ...state, twoFastatusData: action.payload.twoFastatusData };

    case types.GOOGLE_AUTH_DATA:
      return { ...state, enableGoogleAuthData: action.payload.enableGoogleAuthData };

    case types.ADMIN_PROFILE_DATA:
      return { ...state, adminData: action.payload.adminData };
  
    default:
      return state;
  }
}
