import React, { Component } from "react";
import { Container, Row, Col } from "shards-react";
import PageTitle from "../../../common/PageTitle";
import { connect } from "react-redux";

import { Form, Button, ListGroup, Card, Image } from "react-bootstrap";
import {
  kycActionFn,
  singleKycDataFn,
  getImage,
} from "../../../../Redux/Actions/user/user.action";
import dateFormat from "dateformat";

class Approvekyc extends Component {
  constructor() {
    super();
    this.state = {
      user_id: "",
      offset: 0,
      limit: 2,
      kyc_id: "",
      imagePath: [],
      nationalImage: "",
    };
  }

  componentDidMount = () => {
    let { singleKycDataFn, singleKycData } = this.props;
    let { userId } = this.props.match.params;
    this.setState({ user_id: userId });

    singleKycDataFn(userId).then((res) => {
      this.getSelfieImage(singleKycData.selfiePath);
      this.getImage(singleKycData.documents);
    });
  };

  getImage = (imagePath) => {
    let { singleKycData } = this.props;
    let imageData = [];
    singleKycData.documents?.map((element) => {
      var partialPath =
        "https://stage-bitqik.s3.ap-south-1.amazonaws.com" + element.s3_path;

      if (!!partialPath) {
        let newpath =
          "/" + partialPath.substring(partialPath.indexOf("uploads"));
        this.props.getImage({ image: newpath }).then((res) => {
          // let documentImages = []
          // documentImages.push(res.data.data)
          // this.setState({
          //   imagePath:documentImages
          // })

          //element['image_url'] = res.data.data.split('?')[0];
          //element['image_encrypt'] = res.data.data.split('?')[1];
          this.saveImgUrl(res.data.data, element.type);

          // imageData.push({imgPath:res.data.data,type:element.type})
          //  this.setState({ imagePath: res.data.data });
        });
        // .catch((e) => console.log(e));
      }
    });

    // this.setState({imagePath : singleKycData?.documents})
  };

  getSelfieImage = (imagePath) => {
    let { singleKycData } = this.props;

    if (imagePath != null) {
      var partialPath =
        "https://stage-bitqik.s3.ap-south-1.amazonaws.com" +
        singleKycData.selfiePath;
      if (!!partialPath) {
        let newpath =
          "/" + partialPath.substring(partialPath.indexOf("uploads"));
        this.props.getImage({ image: newpath }).then((res) => {
          this.setState({ nationalImage: res.data.data });
        });
        // .catch((e) => console.log(e));
      }
    }
  };

  saveImgUrl = (imgUrl, type) => {
    let previousData = this.state.imagePath;
    this.setState({
      imagePath: [...previousData, { imgPath: imgUrl, type: type }],
    });
  };

  updateUserInfo = (values) => {
    values.user_id = this.state.user_id;
    this.props.editUserInfo(values);
  };

  updatekycDetail = (values) => {
    /* let kycData = {
            kyc_id:this.state.kyc_id,
            user_id:this.state.user_id
        }
        this.props.updateUserKycInfo(kycData) */
    alert("KYC FORM BUTTON");
  };

  approveKycStatus = () => {
    let kycData = {
      kyc_id: this.state.kyc_id,
      user_id: this.state.user_id,
      kyc_status: "1",
    };
    this.props.updateUserKycInfo(kycData);
  };

  rejectKycStatus = () => {
    let kycData = {
      kyc_id: this.state.kyc_id,
      user_id: this.state.user_id,
      kyc_status: "0",
    };
    this.props.updateUserKycInfo(kycData);
  };

  approveKycAction = (user_id) => {
    let { kycActionFn } = this.props;
    let kycActionParams = {
      id: user_id,
      isDeclined: "no",
    };
    kycActionFn(kycActionParams, this.props.history);
  };

  rejectKycAction = (user_id) => {
    let { kycActionFn } = this.props;
    let kycActionParams = {
      id: user_id,
      isDeclined: "yes",
    };
    kycActionFn(kycActionParams, this.props.history);
  };

  render() {
    let { singleKycData } = this.props;

    return (
      <Container fluid className="main-content-container px-4 pb-4">
        <Row>
          <Col>
            {/* Page Header */}
            <Row noGutters className="page-header py-4">
              <PageTitle
                sm="4"
                title="User Information"
                subtitle=""
                className="text-sm-left"
              />
            </Row>

            <Row className="bg-white py-3 px-3">
              <Col>
                <Card style={{ width: "18rem" }}>
                  {this.state.nationalImage ? (
                    <Card.Img variant="top" src={this.state.nationalImage} />
                  ) : (
                    <Card.Img
                      variant="top"
                      src="https://devtalk.blender.org/uploads/default/optimized/2X/c/cbd0b1a6345a44b58dda0f6a355eb39ce4e8a56a_2_690x460.png"
                    />
                  )}
                  <Card.Body>
                    <Card.Title>
                      {singleKycData?.firstname} {singleKycData?.lastname}
                    </Card.Title>
                    <Card.Text>{singleKycData?.email}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              {/* <Col>
                <Card style={{ width: '18rem' }}>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Button
                          variant="outline-primary"
                          onClick={() =>
                            this.approveKycAction(singleKycData?.users_id)
                          }
                        >
                          Approve
                        </Button>{' '}
                      </Col>
                      <Col>
                        <Button
                          variant="outline-danger"
                          onClick={() =>
                            this.rejectKycAction(singleKycData?.users_id)
                          }
                        >
                          Reject
                        </Button>{' '}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col> */}
              <Col></Col>
            </Row>
            <br />
            <br />

            <Row className="bg-white py-3 px-3">
              <Col>
                <Card>
                  <Card.Body>
                    <Row>
                      <Col>
                        <label class="font-weight-bold">City</label>
                        <p>{singleKycData?.city}</p>
                      </Col>
                      <Col>
                        <label class="font-weight-bold">Country</label>
                        <p>{singleKycData?.country_code}</p>
                      </Col>
                      <Col>
                        <label class="font-weight-bold">DOB</label>
                        <p>{dateFormat(singleKycData?.dob, "dd-mm-yyyy")}</p>
                      </Col>
                      <Col>
                        <label class="font-weight-bold">Gender</label>
                        <p>{singleKycData?.gender == 0 ? "Male" : "Female"}</p>
                      </Col>
                      <Col>
                        <label class="font-weight-bold">
                          Zip Code/Postal Code
                        </label>
                        <p>{singleKycData?.zip}</p>
                      </Col>
                      <Col>
                        {/* <label className="font-weight-bold ">Phone</label>
                        <p style={{ margin: '1px' }}>
                          {singleKycData?.mobile_no
                            ? singleKycData?.mobile_no
                            : '-'}
                        </p> */}
                      </Col>
                      <Col>
                        {singleKycData != undefined &&
                          singleKycData?.documents &&
                          singleKycData?.documents.length > 0 &&
                          (singleKycData?.documents[0].type == 0 ? (
                            <>
                              <label className="font-weight-bold ">
                                Passport number
                              </label>
                            </>
                          ) : (
                            <label className="font-weight-bold ">
                              Identity number
                            </label>
                          ))}
                        {singleKycData != undefined &&
                          singleKycData?.documents &&
                          singleKycData?.documents.length > 0 && (
                            <p style={{ margin: "1px" }}>
                              {singleKycData?.documents[0].document_number}
                            </p>
                          )}
                      </Col>
                    </Row>
                    <br />
                    <br />

                    {singleKycData != undefined &&
                      Object.keys(singleKycData).length > 0 &&
                      singleKycData?.documents &&
                      singleKycData.documents.length > 0 && (
                        <Row flex-wrap>
                          {this.state.imagePath &&
                            this.state.imagePath.map((docs) => {
                              // if (!docs?.imagePath) {
                              //   return;
                              // }
                              return (
                                <>
                                  <Col lg={6}>
                                    <h3>
                                      {docs?.type == 0
                                        ? "Passport Front"
                                        : docs?.type == 1
                                        ? "National ID"
                                        : docs?.type == 2
                                        ? "Identity Front"
                                        : docs?.type == 3
                                        ? "Identity Back"
                                        : docs?.type == 4
                                        ? "Passport Back"
                                        : docs?.type == 6
                                        ? "Selfie with ID Card"
                                        : docs?.type == 5
                                        ? "Selfie with Passport"
                                        : "Video"}
                                    </h3>
                                    <Form.Group
                                      controlId="formBasicEmail"
                                      style={{ margin: "20px" }}
                                    >
                                      <img
                                        style={{ height: "200px" }}
                                        src={docs?.imgPath}
                                        /* onClick={() =>
                                         this.setState({
                                           modalShow: true,
                                           kycFilePath: kycDta.file_path,
                                           kycFileName: kycDta.file_name,
                                         })
                                       } */
                                      />

                                      {/* )} */}
                                    </Form.Group>
                                  </Col>
                                </>
                              );
                            })}
                        </Row>
                      )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    singleKycData: state.user.singleKycData,
    /* 
        users: state.user.users,
        singleKycData: state.user.singleKycData */
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    singleKycDataFn: (data) => dispatch(singleKycDataFn(data)),
    kycActionFn: (data, history) => dispatch(kycActionFn(data, history)),
    getImage: (data) => dispatch(getImage(data)),
    /* 
        getAllUsersFn: (data) => dispatch(getAllUsersFn(data)),
        editUserInfo: (data) => dispatch(editUserInfo(data)),
        singleKycDataFn: (data) => dispatch(singleKycDataFn(data)), */
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Approvekyc);
