import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "shards-react";
import { Table, Form } from "react-bootstrap";
import { Pagination, Icon } from "semantic-ui-react";
import PageTitle from "../../common/PageTitle";
import Tabss from './tab'

import {
  getBuyData
} from "../../../Redux/Actions/pnl/pnl.action"
import "react-datepicker/dist/react-datepicker.css";
import "rc-datepicker/lib/style.css";

import { connect } from "react-redux";
import "moment/locale/en-gb";
import "../user/Users.css";
import "../../../App.css";
import { useSelector, useDispatch } from "react-redux";
import ImportCsv from "../../common/common";

const BuyCryptocomponet = () => {
  const dispatch = useDispatch();
  const buycryptogetdata = useSelector((state) => state.Pnl.BuyCrypto.Buydata)
  const [buydata, setbuyData] = useState([])
  const [total_profit, setTotal_profit] = useState(0)


  console.log("buydata", buydata)

  useEffect(() => {

    dispatch(getBuyData())

  }, [])

  useEffect(() => {
    let arr = [];
    buycryptogetdata?.forEach((ele, index) => {
      arr.push({ "Pair": ele.pair_name, "Profit": ele.feeValue})
      setbuyData(arr)
    })
    let sum = buycryptogetdata?.reduce((a, v) => a = a + v.usdConversion ? v.usdConversion : 0, 0)
    setTotal_profit(sum)

  }, [buycryptogetdata])

  console.log("total_profit", total_profit)

  return (
    <div>
      <Tabss />
      <div className="mt-3">
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <Col lg={12}>

              <PageTitle
                title="Buy Crypto"
                subtitle=""
                className="text-sm-left mb-3"
              />
            </Col>
          </Row>


          <Row>
            {/* <Col style={{ textAlign: "right" }}>
            <Form.Group>
              <Form.Control
                type="search"
                name="search"
                id="search"
                // value={this.state.searchItem}
                placeholder="Search Coin"
              // onChange={(e) => this.getFilterInputData(e)}
              // disabled={disabled}
              />
            </Form.Group>
          </Col> */}

            <Col style={{ textAlign: "right" }}>
              <ImportCsv
                stateCsvData={buydata ?? []}
                file_name={"Buy Crypto"}
              />
            </Col>
          </Row>
         

          <Row className="justify-content-end mt-4 p-3 bg-white">
          {/* <h4>Total Profit: {total_profit ? total_profit?.toFixed(2) : 0} &nbsp;USD</h4> */}
            {buycryptogetdata != undefined && buycryptogetdata.length > 0 ? (
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th className="pl-3 text-nowrap">Pair</th>
                    <th className="pl-3 text-nowrap">Profit</th>
                    {/* <th className="pl-3 text-nowrap">USD</th> */}
                  </tr>
                </thead>
                <tbody>

                  {buycryptogetdata?.map((ele, i) => {

                    // let getcoin = getETHuser?.id?.split("_")
                    return (
                      <tr>
                        <td>{ele?.pair_name ?? ""}</td>

                        <td className="pl-3 text-nowrap">
                          {(ele?.feeValue)?.toFixed(2) ?? ""} &nbsp; {(ele?.pair_name)?.slice(0, 3)}
                        </td>
{/* 
                        <td className="pl-3 text-nowrap">
                          {ele?.usdConversion ? (ele?.usdConversion)?.toFixed(2) : 0}
                        </td> */}

                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <Table striped bordered hover responsive>
                <thead>
                  <tr>

                    <th className="pl-3 text-nowrap">Pair</th>
                    <th className="pl-3 text-nowrap">Profit</th>
                    {/* <th className="pl-3 text-nowrap">USD</th> */}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="4" className="text-center">
                      No Record Found
                    </td>
                  </tr>
                </tbody>
              </Table>
            )}
            {/* {buydata.length > 1 ? (
            <Pagination
              defaultActivePage={1}
              activePage={this.state.activePage}
              onPageChange={this.btnClick}
              ellipsisItem={{
                content: <Icon name="ellipsis horizontal" />,
                icon: true,
              }}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
              totalPages={Math.ceil((this.state.articles1?.length) / 10)}
            />
          ) : (
            ""
          )} */}
          </Row>
        </Container>
      </div>
    </div>
  );
}


export default BuyCryptocomponet
