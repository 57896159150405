import { fetch } from "../axios.service";
import {
  USERS_BASE_URL,
  TRADING_BASE_URL,
  SWAP_URL,
  WALLET_BASE_URL,
  TRADING_NEW_BASE_URL,
} from "../../Constants/constant";

export const getPairListApi = (data, options) => {
  return fetch(
    "post",
    `${TRADING_NEW_BASE_URL}/order/admin/pair-list-admin`,
    data,
    options
  );
};

export const getSwapOrderListApi = (data, options) => {
  return fetch(
    "post",
    `${SWAP_URL}/buySellOrdersHistory/${data.page}`,
    data,
    options
  );
};

export const getSwapSellOrderListApi = (data, options) => {
  return fetch(
    "post",
    `${SWAP_URL}/ordersHistorySell/${data.page}`,
    data,
    options
  );
};

export const getImageUrlApi = (data, options) => {
  return fetch("post", `${SWAP_URL}/getFile`, data, options);
};

export const verifiedOrder = (data, options) => {
  return fetch("post", `${SWAP_URL}/transaction_verification`, data, options);
};
export const tradeSummaryApi = (type, options) => {
  return fetch(
    "get",
    `${TRADING_NEW_BASE_URL}/order/admin/tradeSummary/${type}`,
    {},
    options
  );
};
export const getorderListPair = (filterseletedData, options) => {
  //console.log(options, "options")
  return fetch(
    "get",
    `${TRADING_NEW_BASE_URL}/order/admin/orders_listing?page=${filterseletedData.page}&limit=${filterseletedData.limit}&status=${filterseletedData.status}&pairName=${filterseletedData.pair}&side=${filterseletedData.type}&email=${filterseletedData.email}&startDate=${filterseletedData.fromDate}&endDate=${filterseletedData.toDate}`,
    {},
    options
  );
};

export const cancelOrder = (data, options) => {
  return fetch("post", `${SWAP_URL}/cancelSellOrder`, data, options);
};

export const getCountPendingSell = (options) => {
  return fetch("get", `${SWAP_URL}/getCountPendingSell`, {}, options);
};
