//import { SMALLESTUNIT, totallimit } from "../constant";
import * as moment from "moment";

//var BigNumber = require("big-number");

export const convertExponentialToDecimalTotal = (exponentialNumber) => {
    // sanity check - is it exponential number

    if (!exponentialNumber) {
        return false;
    }

    const str = exponentialNumber.toString();

    if (str.indexOf("e") !== -1) {
        const exponent = parseInt(str.split("-")[1], 10);
        // Unfortunately I can not return 1e-8 as 0.00000001, because even if I call parseFloat() on it,
        // it will still return the exponential representation
        // So I have to use .toFixed()
        const result = exponentialNumber.toFixed(20);

        let value = result;

        if (value <= 0) {
            value = "0.0";
        } else {
            let firstBlncTest = String(value).split(".", 2);
            if (firstBlncTest.length > 1) {
                if (firstBlncTest[1].length > 8) {
                    let presc3 = firstBlncTest[1].substring(0, 8);

                    value = firstBlncTest[0] + "." + presc3;

                    if (value <= 0) value = "0.0";
                }
            }
        }

        return value;
    } else {
        return exponentialNumber;
    }
};

export const convertTZ = (date, tzString) => {
    var data = new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
            "en-US",
            { timeZone: tzString }
        )
    );
    return data;
}