import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "shards-react";
import { Table, Form } from "react-bootstrap";
import { Pagination, Icon } from "semantic-ui-react";
import PageTitle from "../../common/PageTitle";
import Tabss from './tab'

import {
  getExchangesData
} from "../../../Redux/Actions/pnl/pnl.action"
import "react-datepicker/dist/react-datepicker.css";
import "rc-datepicker/lib/style.css";

import { connect } from "react-redux";
import "moment/locale/en-gb";
import "../user/Users.css";
import "../../../App.css";
import { useSelector, useDispatch } from "react-redux";
import ImportCsv from "../../common/common";

const Exchnagescomponet = () => {
  const dispatch = useDispatch();
  let exchangesgetdata = {}
  exchangesgetdata = useSelector((state) => state.Pnl.Exchanges)
  const [buydata, setbuyData] = useState([])
  const [selldata, setsellData] = useState([])
  const [total_profit, setTotal_profit] = useState(0)
  const [total_profitsell, setTotal_profitsell] = useState(0)
  // console.log("buy",buy)

  // console.log("buydata", exchangesgetdata)

  useEffect(() => {

    dispatch(getExchangesData())

  }, [])

  useEffect(() => {
    let arr = [];
    let arrsell = [];
    exchangesgetdata?.Exchnageswdata?.buy?.forEach((ele, index) => {
      arr.push({ "Pair": ele?.symbol?.toUpperCase(), "Profit": ele?.fee  })
      console.log("selll",arr)
      setbuyData(arr)
    })
    exchangesgetdata?.Exchnageswdata?.sell?.forEach((ele, index) => {
      arrsell.push({ "Pair": ele?.symbol?.toUpperCase(), "Profit": ele?.fee  })
      setsellData(arrsell)
    })
    let sumsell = exchangesgetdata?.Exchnageswdata?.sell?.reduce((a, v) => a = a + v.usd_price ? v.usd_price : 0, 0)
    setTotal_profitsell(sumsell)

    let sum = exchangesgetdata?.Exchnageswdata?.buy?.reduce((a, v) => a = a + v.usd_price ? v.usd_price : 0, 0)
    setTotal_profit(sum)

  }, [exchangesgetdata])

  console.log(buydata,selldata)
  // console.log(exchangesgetdata?.Exchnageswdata?.buy)
  // console.log(exchangesgetdata?.Exchnageswdata?.sell)
  return (
    <div>
      <Tabss />
      <div className="mt-3">
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <Col lg={12}>

              <PageTitle
                title="Exchange"
                subtitle=""
                className="text-sm-left mb-3"
              />
            </Col>
          </Row>




          <Row className="justify-content-end mt-4 p-3 bg-white">

            <Col className="col-xl-6 col-md-6 col-sm-12" >

              <Row>
                <h2>Buy</h2>
                <Col style={{ textAlign: "right" }}>
                  <ImportCsv
                    stateCsvData={buydata ?? []}
                    file_name={"Buy_PNL_Report"}
                  />

                </Col>


              </Row>
              <Col className='mb-2' style={{ textAlign: "right" }}>
               {/* <h4>Total Profit: {total_profit ? total_profit :0} &nbsp;USD</h4>  */}

              </Col>


              {exchangesgetdata?.Exchnageswdata?.buy != undefined && exchangesgetdata?.Exchnageswdata?.buy.length > 0 ? (
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th className="pl-3 text-nowrap">Pair</th>
                      <th className="pl-3 text-nowrap">Profit</th>
                      {/* <th className="pl-3 text-nowrap">USD</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {exchangesgetdata?.Exchnageswdata?.buy?.map((ele, i) => {

                      let getcoin = ele?.symbol?.split("_")
                      return (
                        <tr>
                          <td>{ele.symbol ?? ""}</td>

                          <td className="pl-3 text-nowrap">
                            {ele?.fee ?? ""} &nbsp; {(getcoin[0].toUpperCase())}
                          </td>

                          {/* <td className="pl-3 text-nowrap">
                            {(ele?.usd_price)?.toFixed(2) ?? 0}
                          </td> */}

                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th className="pl-3 text-nowrap">Pair</th>
                      <th className="pl-3 text-nowrap">Profit</th>
                      {/* <th className="pl-3 text-nowrap">USD</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan="4" className="text-center">
                        No Record Found
                      </td>
                    </tr>
                  </tbody>
                </Table>
              )}

            </Col>

            <Col className="col-xl-6 col-md-6 col-sm-12" >
            <Row>
                <h2>&nbsp; &nbsp;Sell</h2>
                <Col style={{ textAlign: "right" }}>
                  <ImportCsv
                    stateCsvData={selldata}
                    file_name={"Sell_PNL_Report"}
                  />

                </Col>


              </Row>
              <Col className='mb-2' style={{ textAlign: "right" }}>
               {/* <h4>Total Profit: {total_profitsell ? total_profitsell : 0} &nbsp;USD</h4>  */}

              </Col>
              {exchangesgetdata?.Exchnageswdata?.sell != undefined && exchangesgetdata?.Exchnageswdata?.sell?.length > 0 ? (
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th className="pl-3 text-nowrap">Pair</th>
                      <th className="pl-3 text-nowrap">Profit</th>
                      {/* <th className="pl-3 text-nowrap">USD</th> */}
                    </tr>
                  </thead>
                  <tbody>

                    {exchangesgetdata?.Exchnageswdata?.sell?.map((ele, i) => {

                      let getcoin = ele?.symbol?.split("_")
                      return (
                        <tr>
                          <td>{ele.symbol ?? ""}</td>

                          <td className="pl-3 text-nowrap">
                            {ele?.fee ?? ""} &nbsp; {(getcoin[0].toUpperCase())}
                          </td>

                          {/* <td className="pl-3 text-nowrap">
                            {(ele?.usd_price)?.toFixed(2) ?? 0}
                          </td> */}


                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>

                      <th className="pl-3 text-nowrap">Pair</th>
                      <th className="pl-3 text-nowrap">Profit</th>
                      {/* <th className="pl-3 text-nowrap">USD</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan="4" className="text-center">
                        No Record Found
                      </td>
                    </tr>
                  </tbody>
                </Table>
              )}

            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}


export default Exchnagescomponet
