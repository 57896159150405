import { fetch } from "../axios.service";
import {
  WALLET_BASE_URL,
  TRADING_BASE_URL,
  SWAP_URL,
  TRADING_NEW_BASE_URL
} from "../../Constants/constant";

/* export const getPairListApi = ( options) => {
  return fetch("get", `${WALLET_BASE_URL}/trade/pair-list-admin`, {}, options);
};

 */
export const currencyApi = (coin, options) => {
  return fetch(
    "get",
    `${WALLET_BASE_URL}/${coin}/admin/currency_detials`,
    {},
    options
  );
};

export const toggleCurrencyPair = (data, options) => {
  // console.log("data====>",data);
  return fetch(
    "post",
    `${TRADING_NEW_BASE_URL}/order/admin/adminUpdatePairStatus`,
    data,
    options
  );
};

export const currencyPairApi = (data, options) => {
  return fetch(
    "post",
    `${TRADING_NEW_BASE_URL}/order/admin/pair-list-admin`,
    data,
    options
  );
};
export const swapPairApi = (data, options) => {
  return fetch("get", `${SWAP_URL}/marketPairsList`, {}, options);
};

export const editSwapPairApi = (data, options) => {
  return fetch("post", `${SWAP_URL}/updateMarketPair`, data, options);
};

export const editSwapPairSellApi = (data, options) => {
  return fetch("post", `${SWAP_URL}/updateMarketPairSell`, data, options);
};

export const EditFeeApi = (data, options) => {
  return fetch("post", `${TRADING_NEW_BASE_URL}/order/admin/updatePair`, data, options);
};

export const getSwapEditFeeData = (data, options) => {
  return fetch("get", `${SWAP_URL}/getPair/${data.id}`, {}, options);
};

export const getPairById = (data, options) => {
  return fetch("post", `${TRADING_NEW_BASE_URL}/order/admin/getpairById`, data, options);
};

export const enableSwapPairs = (data, options) => {
  return fetch("post", `${SWAP_URL}/update_status_pair`, data, options);
};

export const buyCrypto = (data, options) => {
  return fetch("get", `${SWAP_URL}/pnl/buyCrypto`, {}, options);
};

export const sellCrypto = (data, options) => {
  return fetch("get", `${SWAP_URL}/pnl/sellCrypto`, {}, options);
};

export const withdrawPnl = (data, options) => {
  return fetch("get", `${WALLET_BASE_URL}/pnl/withdraw`, {}, options);
};

export const OrderExchnagesPnl = (data, options) => {
  return fetch("get", `${TRADING_NEW_BASE_URL}/order/admin/pnl/trade`, {}, options);
};





export const EditFeeApiCurrency = (data, options) => {
  return fetch(
    "post",
    `${WALLET_BASE_URL}/${data.coin}/admin/update_currency`,
    data,
    options
  );
};
