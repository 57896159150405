import * as userService from "../../../Services/API/deposite.service";
import { toast } from "../../../Components/Toast/toast.component";
import * as types from "../../../Constants/_Types/types.reduxStore";
import { startLoader, stopLoader } from "../Loader/loader.action";
import { BASEURL, SUCCESS_MESSAGE } from "../../../Constants/constant";
import { logoutUser } from "../user/user.action";
//import { reset } from 'redux-form';

const getAllDeposites = (getAllDeposites, totalRecords) => {
  return {
    type: types.DEPOSITE_TRANS,
    payload: {
      getAllDeposites: getAllDeposites,
      totalRecords: totalRecords,
    },
  };
};

export function depositeFilterDataFn(coin, data) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .depositeFilterDataApi(coin, data, options)
      .then((res) => {
        dispatch(getAllDeposites(res.data?.data, res.data.totalRecords));
        dispatch(stopLoader());
      })
      .catch((error) => {
        if (error?.status == 401) {
          dispatch(logoutUser("401"));
        }
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function depositeCSVFilterDataFn(coin, data) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    //  dispatch(startLoader());
    return userService
      .depositeFilterDataApi(coin, data, options)
      .then((res) => {
        return res.data.data;
        // dispatch(getAllDeposites(res.data?.data, res.data.totalRecords));
        // dispatch(stopLoader());
      })
      .catch((error) => {
        if (error?.status == 401) {
          dispatch(logoutUser("401"));
        }
        toast.error(error?.data?.message);
        //dispatch(stopLoader());
      });
  };
}

export function getAllDepositesFn(data) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .getAllDepositesApi(data, options)
      .then((res) => {
        dispatch(getAllDeposites(res.data?.data, res.data.totalRecords));
        dispatch(stopLoader());
      })
      .catch((error) => {
        if (error?.status == 401) {
          dispatch(logoutUser("401"));
        }
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function getAllCSVDepositesFn(data) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    //  dispatch(startLoader());
    return userService
      .getAllDepositesApi(data, options)
      .then((res) => {
        return res.data.data;
        //  dispatch(getAllDeposites(res.data?.data,res.data.totalRecords));
        //dispatch(stopLoader());
      })
      .catch((error) => {
        if (error?.status == 401) {
          dispatch(logoutUser("401"));
        }
        toast.error(error?.data?.message);
        //  dispatch(stopLoader());
      });
  };
}
