import React from "react";
import { Container, Row, Col } from "shards-react";
import { Table, Form, Button, Dropdown, Card, Modal } from "react-bootstrap";
import PageTitle from "../../common/PageTitle";
import { Pagination } from 'semantic-ui-react';
import { connect } from "react-redux";
import "moment/locale/en-gb";
import "rc-datepicker/lib/style.css";
import "../../../App.css";
import { offset, limit, USER_TYPE, COUNTRY } from '../../../Constants/constant';

import { getSubadminListFn, deleteSubadminFn, changePassword, unlockUser } from "../../../Redux/Actions/subadmin/subadmin.action";
import { Icon } from "semantic-ui-react";
import { Link } from 'react-router-dom';
import password from './../../../images/password.png'
import ForgotPasswordModal from "../../modal/ForgotPassword";

class Subadmin extends React.Component {
  constructor() {
    super();
    this.state = {
      totalDataCount: 0,
      page: 1,
      deletedId: 0,
      currentpage:1,
      showImageModal: false,
      modalData: "",
      search: ""
    };
  }

  componentDidMount = () => {
    let { getSubadminListFn } = this.props;
    let searchedParams = { page: this.state.page };
    getSubadminListFn(searchedParams);

  };
  pageChange = (e, data) => {

    let page = data.activePage;
    this.setState({currentpage:data.activePage})
    this.setState({ page });
    let pageNo = page === 1 ? 0 : (page - 1) * limit;

    let searchedParams = { page: page };
    this.props.getSubadminListFn(searchedParams);

  };

  handleClose = () => {
    this.setState({
      deletedataPopup: false,
    });
  };
  handleDeletepopup = (id) => {
    this.setState({
      deletedataPopup: true,
      deletedId: id,
    });
  };

  deleteSubadmin = (data) => {

    let deleteData = {
      id: data
    }
    this.props.deleteSubadminFn(deleteData).then(() => {
      let { getSubadminListFn } = this.props;
      let searchedParams = { page: this.state.page };
      getSubadminListFn(searchedParams);
      this.setState({
        deletedataPopup: false,
      });
    });

  }

  changePassword = (passworddata) => {
    ///console.log("called insss", data)
    let data = {
      password: passworddata.password,
      email: this.state.modalData.email
    }
    this.props.changePassword(data).then(res => {
      // console.log(res)
      this.setState({ showImageModal: false })
    })
  }

  unlockUser = (emaill) => {
    let data = {
      email: emaill
    }

    this.props.unlockUser(data).then((res) => {

      let searchedParams = { page: this.state.page };
      this.props.getSubadminListFn(searchedParams);
    })
  }
  getFilterInputData = (e, type) => {
    let { filterData } = this.state;

    if (type == "search") {
      this.setState({ search: e.target.value })
      // filterData.is_internal = e.target.value;
    }

    //this.setState({ filterData: filterData });
  };

  searchData = () => {
    let searchedParams =
    {
      page: this.state.page,
      search: this.state.search.trim(),
      deactivate: ""

    };
    this.props.getSubadminListFn(searchedParams);
  }

  clearSearch = () => {
    this.state.search = "";
    let searchedParams =
    {
      page: this.state.page,
      search: "",
      deactivate: ""

    };
    this.props.getSubadminListFn(searchedParams);
  }

  render() {
    let { getAllSubadmin, totalRecords } = this.props;

    return (
      <div>
        < ForgotPasswordModal data={this.state.modalData} setPassword={this.changePassword} show={this.state.showImageModal}
          setShow={() => this.setState({ showImageModal: false })} />
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <Col lg={12}>
              <PageTitle
                title="Sub-Admin-Management"
                subtitle=""
                className="text-sm-left mb-3"
              />
            </Col>
          </Row>
          <Row>
            <Col xl={2} sm={4} xs={2}>
              <Form.Group>
                <Form.Control
                  type="search"
                  name="email"
                  id="email"
                  value={this.state.search}
                  placeholder="Enter Email"
                  onChange={(e) => this.getFilterInputData(e, "search")}
                //disabled={enableFilterInput}
                />
              </Form.Group>
            </Col>
            <Col xl={2} sm={4} xs={12} text-nowrap className="text-nowrap">
              <Button
                variant="primary"
                type="SUBMIT"
                onClick={this.state.page = 1, this.searchData}
              >
                Search
              </Button>
              <Button
                variant="danger"
                className="ml-2"
                onClick={this.clearSearch}
              >
                Reset
              </Button>
            </Col>
          </Row>

          <Card>
            <Card.Header>

              <Button onClick={() => this.props.history.push('/auth/sub-admin-management/sub-admin-new')}>Add New</Button>
            </Card.Header>
            <Card.Body>
              <Row className="justify-content-end mt-4 p-3 bg-white">
                {getAllSubadmin != undefined && getAllSubadmin.length > 0 ? (
                  <Table striped bordered hover responsive>
                    <thead>
                      <tr>
                        <th className="pl-3 text-nowrap">Serial No.</th>
                        <th className="pl-3 text-nowrap">Email Address</th>
                        <th className="pl-3 text-nowrap">Status</th>
                        <th className="pl-3 text-nowrap">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getAllSubadmin.map((subadminData, i) => {
                        //let adminId = subadminData.admin_users_id.split("-")[0];
                        return (
                          <tr key={subadminData?.admin_users_id}>
                            <td className="pl-3 text-nowrap">
                              <p
                                style={{
                                  whiteSpace: "nowrap",
                                  //width: "100px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                
                                {(this.state.currentpage - 1) * 10 + i + 1}
                              </p>
                            </td>

                            <td className="pl-3 text-nowrap"> {subadminData?.email} </td>

                            <td className="pl-3 text-nowrap">{subadminData?.status == 1 && "Active"}</td>

                            <td className="pl-3 text-nowrap">

                              <Link to={`/auth/sub-admin-management/sub-admin-edit/${subadminData?.admin_users_id}`}>
                                <Icon name="pencil alternate" />
                              </Link>
                              {" "}|{" "}


                              <Icon onClick={() => this.handleDeletepopup(subadminData.admin_users_id)} name="trash alternate" style={{ color: "red", cursor: "pointer" }} />
                              {" "}|{" "}
                              <img onClick={() => this.setState({ showImageModal: true, modalData: { email: subadminData?.email } })} style={{ cursor: "pointer" }} src={password}></img>
                              {subadminData.retry_count == 5 && (
                                <>
                                  <Button
                                    variant="danger"
                                    className="mx-3"
                                    onClick={() =>
                                      this.unlockUser(subadminData?.email)
                                    }
                                  >
                                    UNLOCK
                              </Button>{" "}

                                </>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                ) : (
                  <Table striped bordered hover responsive>
                    <thead>
                      <tr>
                        <th className="pl-3 text-nowrap">ID</th>
                        <th className="pl-3 text-nowrap">Email Address</th>
                        <th className="pl-3 text-nowrap">Status</th>
                        <th className="pl-3 text-nowrap">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <p>No Record Found</p>
                    </tbody>
                  </Table>
                )}
                {totalRecords > 10 ?
                  <Pagination
                    defaultActivePage={1}
                    onPageChange={this.pageChange}
                    // ellipsisItem={{
                    //   content: <Icon name="ellipsis horizontal" />,
                    //   icon: true,
                    // }}
                    firstItem={{
                      content: <Icon name="angle double left" />,
                      icon: true,
                    }}
                    lastItem={{
                      content: <Icon name="angle double right" />,
                      icon: true,
                    }}
                    prevItem={{ content: <Icon name="angle left" />, icon: true }}
                    nextItem={{ content: <Icon name="angle right" />, icon: true }}
                    totalPages={Math.ceil(totalRecords / limit)}
                  /> : ""
                }

              </Row>
              {this.state.deletedataPopup && (
                <Modal
                  show={this.state.deletedataPopup}
                  onHide={this.handleClose}
                  keyboard={false}
                  className="logoutModal"
                >
                  <Modal.Header closeButton>
                    {/* <Modal.Title>Modal title</Modal.Title> */}
                  </Modal.Header>
                  <Modal.Body>Are you sure want to delete ?</Modal.Body>
                  <Modal.Footer className="mx-auto">
                    <Button variant="secondary" onClick={this.handleClose}>
                      Close
                </Button>
                    <Button
                      variant="primary"
                      tag={Link}
                      onClick={() => this.deleteSubadmin(this.state.deletedId)}
                    >
                      Delete
                </Button>
                  </Modal.Footer>
                </Modal>
              )}
            </Card.Body>
          </Card>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {

  return {
    //pairListData: state.orders.pairListData,
    getAllSubadmin: state.subadmin?.getAllSubadmin,
    totalRecords: state.subadmin?.totalRecords,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // getPairListFn: (data) => dispatch(getPairListFn(data))
    getSubadminListFn: (data) => dispatch(getSubadminListFn(data)),
    deleteSubadminFn: (data) => dispatch(deleteSubadminFn(data)),
    changePassword: (data) => dispatch(changePassword(data)),
    unlockUser: (data) => dispatch(unlockUser(data))

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Subadmin);
