
import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser, checkIPTokenFn } from "../../Redux/Actions/user/user.action";
import { DefaultLayout } from "../../Components/layouts";
//import { logoutUser } from "../../../Redux/Actions/user/user.action";


const AuthGuard = ({ component: Component, ...rest }) => {
  const { token, role, loginStatus, checkIPTokenFn, userDetails, logoutUser, history } = rest;

  const isAuthenticated = token != null ? true : false;
  let path = rest.path.split("/")[1];

  useEffect(() => {
    if (!!token) {
      let data = {
        token: token
      }
      var response = checkIPTokenFn(data);
      if (loginStatus == false) {
        // logoutUser();
      }
    }

  })


  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated === true ? (
          <DefaultLayout>
            <Component {...props} />
          </DefaultLayout>
        ) : (

          <Redirect
            to={{
              pathname: "/",
              state: {
                from: props.location
              }
            }}

          />
        )
      }
    />
  );
}


const mapStateToProps = state => {
  return {
    token: state.auth.tokens,
    loginStatus: state.user.loginData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logoutUser: () => dispatch(logoutUser()),
    checkIPTokenFn: (data) => dispatch(checkIPTokenFn(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthGuard);

