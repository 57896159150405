import * as userService from "../../../Services/API/profile.service";
import { toast } from "../../../Components/Toast/toast.component";
import * as types from "../../../Constants/_Types/types.reduxStore";
import { startLoader, stopLoader } from "../Loader/loader.action";
import { BASEURL, SUCCESS_MESSAGE } from "../../../Constants/constant";
import { logoutUser } from "../user/user.action";
//import { reset } from 'redux-form';

const get2fastatus = (twoFastatusData) => {
  return {
    type: types.TWO_FASTATUS,
    payload: {
      twoFastatusData: twoFastatusData,
    },
  };
};

const getAdminData = (adminData) => {
  return {
    type: types.ADMIN_PROFILE_DATA,
    payload: {
      adminData: adminData,
    },
  };
};

const enableGoogleAuth = (enableGoogleAuthData) => {
  return {
    type: types.GOOGLE_AUTH_DATA,
    payload: {
      enableGoogleAuthData: enableGoogleAuthData,
    },
  };
};

export function disableGoogleValidateFn(data, history) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .disableGoogleValidateApi(data, options)
      .then((res) => {
        /* 
        dispatch(enableGoogleAuth(res.data.data)) */
        history.push("/auth/admin-dashboard-section/admin-profile");
        dispatch(stopLoader());
      })
      .catch((error) => {
        if (error?.status == 401) {
          dispatch(logoutUser("401"));
        }
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function enableGoogleValidateFn(data, history) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .enableGoogleValidateApi(data, options)
      .then((res) => {
        /* 
        dispatch(enableGoogleAuth(res.data.data)) */
        history.push("/auth/admin-dashboard-section/admin-profile");
        dispatch(stopLoader());
      })
      .catch((error) => {
        if (error?.status == 401) {
          dispatch(logoutUser("401"));
        }
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function enableGoogleAuthFn(data) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .enableGoogleAuthApi(data, options)
      .then((res) => {
        dispatch(enableGoogleAuth(res.data.data));
        dispatch(stopLoader());
      })
      .catch((error) => {
        if (error?.status == 401) {
          dispatch(logoutUser("401"));
        }
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function changePasswordFn(data) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .changePasswordApi(data, options)
      .then((res) => {
        dispatch(stopLoader());
        toast.success(res.data.message);
        window.localStorage.clear();
      })
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        if (error?.status == 401) {
          dispatch(logoutUser("401"));
        }
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function get2fastatusFn(data) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .get2fastatusApi(data, options)
      .then((res) => {
        dispatch(get2fastatus(res.data.data));
        dispatch(stopLoader());
      })
      .catch((error) => {
        if (error?.status == 401) {
          dispatch(logoutUser("401"));
        }
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function getAdminProfile(data) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .getAdminProfile(data, options)
      .then((res) => {
        dispatch(getAdminData(res.data.data));
        dispatch(stopLoader());
      })
      .catch((error) => {
        if (error?.status == 401) {
          dispatch(logoutUser("401"));
        }
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function updateAdminProfile(data, history) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .updateAdminProfile(data, options)
      .then((res) => {
        history.push("/auth/admin-dashboard-section/admin-profile");
        //dispatch(getAdminData(res.data.data));
        dispatch(stopLoader());
      })
      .catch((error) => {
        if (error?.status == 401) {
          dispatch(logoutUser("401"));
        }
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}
