import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link, useParams } from "react-router-dom";
import * as Yup from "yup";
import { toast } from "../../../Components/Toast/toast.component";
import { editFeeLimit } from "../../../Redux/Actions/currency/currency.action";
import { currencyApi } from "../../../Services/API/currency.service";

function EditCurrencyForm(props) {
  const token = useSelector((state) => state?.auth?.tokens);

  const { coinname } = useParams();
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      minWithdrawFee: "",
      withKycLimit: "",
      withoutKycLimit: "",
      withdrawFee: "",
    },
    validationSchema: Yup.object({
      // minWithdrawFee: Yup.number().test(
      //   'is-decimal',
      //   'minWithdrawFee limit accept value 0.01..',
      //   (value) => (value >withdrawFee)
      // ),
      withdrawFee: Yup.number().required("withdrawFee is Required"),
      withKycLimit: Yup.number().required("withKycLimit is Required"),
      withoutKycLimit: Yup.number().required("withoutKycLimit is Required"),
      minWithdrawFee: Yup.number().required("Minimum withdraw fee is required"),
      min_approval_amount: Yup.number().required(
        "Minimum approval amount is required"
      ),
    }),
    onSubmit: (values) => {
      values.withdrawStatus = 1;
      values.coin = coinname;

      let finaData = {
        minWithdrawFee: +values.minWithdrawFee,
        withKycLimit: +values.withKycLimit,
        withoutKycLimit: +values.withoutKycLimit,
        withdrawFee: +values.withdrawFee,
        coin: values.coin,
        withdrawStatus: values.withdrawStatus,
        min_approval_amount: values.min_approval_amount,
      };

      if (finaData.withdrawFee > finaData.minWithdrawFee) {
        toast.error(
          "Withdraw fee should be less then minimum withdraw Amount."
        );
        return;
      }

      if (finaData.withdrawFee == finaData.minWithdrawFee) {
        toast.error("Minimum withdraw amount cannot be same as Withdraw fees.");
        return;
      }

      if (finaData.withKycLimit < finaData.minWithdrawFee) {
        toast.error(
          "Minimum withdraw amount cannot be greater then withdraw Limit With KYC"
        );
        return;
      }

      // if (finaData.withoutKycLimit < finaData.minWithdrawFee) {
      //   toast.error("Minimum withdraw amount cannot be greater then withdraw Limit Without Kyc");
      //   return;
      // }

      props.handleEditUserForm(finaData, history);
    },
  });

  useEffect(() => {
    let options = {
      "api-access-token": token,
    };

    currencyApi(coinname, options)
      .then((res) => {
        formik.setFieldValue(
          "withdrawFee",
          Number(res?.data?.data?.withdraw_fee) / 10 ** 8
        );
        formik.setFieldValue(
          "withKycLimit",
          Number(res?.data?.data?.with_kyc_withdraw_limit) / 10 ** 8
        );
        formik.setFieldValue(
          "withoutKycLimit",
          Number(res?.data?.data?.without_kyc_withdraw_limit) / 10 ** 8
        );
        formik.setFieldValue(
          "minWithdrawFee",
          Number(res?.data?.data?.minimum_withdraw) / 10 ** 8
        );
        formik.setFieldValue(
          "min_approval_amount",
          Number(res?.data?.data?.min_approval_amount) / 10 ** 8
        );
      })
      .catch((error) => {});
  }, []);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row>
        <Col xs={12} xl={6}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Withdraw Fee</Form.Label>
            <Form.Control
              id="withdrawFee"
              name="withdrawFee"
              placeholder="Withdraw Fee"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              maxLength={9}
              pattern="[+-]?\d+(?:[.,]\d+)?"
              value={formik.values.withdrawFee}
            />
            {/* <input
                id="withdrawFee"
                name="withdrawFee"
                placeholder="Withdraw Fee"
                type="number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                maxLength={9}
                pattern="[+-]?\d+(?:[.,]\d+)?"
                value={formik.values.withdrawFee}
              /> */}
            {formik.touched.withdrawFee && formik.errors.withdrawFee ? (
              <p style={{ color: "red" }}> {formik.errors.withdrawFee}</p>
            ) : null}
          </Form.Group>
        </Col>

        <Col xs={12} xl={6}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Withdraw Limit With KYC</Form.Label>
            <Form.Control
              id="withKycLimit"
              name="withKycLimit"
              type="number"
              placeholder="withdraw limit with kyc"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              maxLength={9}
              pattern="[+-]?\d+(?:[.,]\d+)?"
              value={formik.values.withKycLimit}
            />
            {formik.touched.withKycLimit && formik.errors.withKycLimit ? (
              <p style={{ color: "red" }}> {formik.errors.withKycLimit}</p>
            ) : null}
          </Form.Group>
        </Col>
        <Col xs={12} xl={6}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Withdraw Limit Without Kyc</Form.Label>
            <Form.Control
              id="withoutKycLimit"
              name="withoutKycLimit"
              type="number"
              placeholder="withdraw limit without kyc"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              maxLength={9}
              pattern="[+-]?\d+(?:[.,]\d+)?"
              value={formik.values.withoutKycLimit}
            />
            {formik.touched.withoutKycLimit && formik.errors.withoutKycLimit ? (
              <p style={{ color: "red" }}> {formik.errors.withoutKycLimit}</p>
            ) : null}
          </Form.Group>
        </Col>

        <Col xs={12} xl={6}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Min Withdraw Amount</Form.Label>
            <Form.Control
              id="minWithdrawFee"
              name="minWithdrawFee"
              type="number"
              placeholder="Min Withdraw Amount"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              maxLength={9}
              pattern="[+-]?\d+(?:[.,]\d+)?"
              value={formik.values.minWithdrawFee}
            />
            {formik.touched.minWithdrawFee && formik.errors.minWithdrawFee ? (
              <p style={{ color: "red" }}> {formik.errors.minWithdrawFee}</p>
            ) : null}
          </Form.Group>
        </Col>

        <Col xs={12} xl={6}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Minimum Approval</Form.Label>
            <Form.Control
              id="min_approval_amount"
              name="min_approval_amount"
              type="number"
              placeholder="Minimum Approval"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              maxLength={9}
              pattern="[+-]?\d+(?:[.,]\d+)?"
              value={formik.values.min_approval_amount}
            />
            {formik.touched.min_approval_amount &&
            formik.errors.min_approval_amount ? (
              <p style={{ color: "red" }}>
                {formik.errors.min_approval_amount}
              </p>
            ) : null}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col className={"d-flex justify-content-center mt-2"}>
          <Button variant="primary" type="submit">
            Update
          </Button>

          <Link variant="primary" to="/auth/currency-manage">
            <Button variant="danger" className="ml-2">
              Cancel
            </Button>
          </Link>
        </Col>
      </Row>
      {/* <Button variant="primary" type="submit">
        {" "}
        Update{" "}
      </Button>
      <Link
        style={{ margin: "20px" }}
        variant="primary"
        to="/auth/currency-manage"
      >
        {" "}
        Cancel{" "}
      </Link> */}
    </Form>
  );
}
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleEditUserForm: (data, history) =>
      dispatch(editFeeLimit(data, history)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditCurrencyForm);
