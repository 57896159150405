import * as types from "../../../Constants/_Types/types.reduxStore";

const initialState = {
  pairListData: [],
};

export default function Pair(state = initialState, action) {
  switch (action.type) {
    case types.PAIR_LIST:
      return {
        ...state,
        pairListData: action.payload.pairListData,
        totalRecords: action.payload.totalRecords,
      };

    case types.SWAP_ORDER_DATA:
      return {
        ...state,
        swapOrderListData: action.payload.swapOrderListData,
        totalRecords: action.payload.totalRecords,
      };
    
      case types.SWAP_ORDER_SELL_DATA:
      return {
        ...state,
        swapOrderListSellData: action.payload.swapOrderListData,
        sellTotalRecords: action.payload.totalRecords,
      };

    default:
      return state;
  }
}
