import * as userService from "../../../Services/API/orders.service";
import { toast } from "../../../Components/Toast/toast.component";
import * as types from "../../../Constants/_Types/types.reduxStore";
import { startLoader, stopLoader } from "../Loader/loader.action";
import { BASEURL, SUCCESS_MESSAGE } from "../../../Constants/constant";

const getPairList = (pairListData, totalRecords) => {
  return {
    type: types.PAIR_LIST,
    payload: {
      pairListData: pairListData,
      totalRecords: totalRecords,
    },
  };
};

const getSwapOrderList = (swapOrderListData, totalRecords) => {
  return {
    type: types.SWAP_ORDER_DATA,
    payload: {
      swapOrderListData: swapOrderListData,
      totalRecords: totalRecords,
    },
  };
};

const getSwapSellOrderList = (swapOrderListData, totalRecords) => {
  return {
    type: types.SWAP_ORDER_SELL_DATA,
    payload: {
      swapOrderListData: swapOrderListData,
      totalRecords: totalRecords,
    },
  };
};

export function getPairListFn(data) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };

    dispatch(startLoader());
    return userService
      .getPairListApi(data, options)
      .then((res) => {
        dispatch(getPairList(res.data?.data.PairList, res.data.totalRecords));
        dispatch(stopLoader());
        return res.data.data;
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function getSwapOrderListFn(data) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };

    dispatch(startLoader());
    return userService
      .getSwapOrderListApi(data, options)
      .then((res) => {
        dispatch(getSwapOrderList(res.data?.data, res.data.total_records));
        dispatch(stopLoader());
        return res.data.data;
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function getSwapOrderCSVListFn(data) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };

    // dispatch(startLoader());
    return userService
      .getSwapOrderListApi(data, options)
      .then((res) => {
        // dispatch(getSwapOrderList(res.data?.data, res.data.total_records));
        // dispatch(stopLoader());
        return res.data.data;
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function getSwapSellOrderListFn(data) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };

    dispatch(startLoader());
    return userService
      .getSwapSellOrderListApi(data, options)
      .then((res) => {
        dispatch(getSwapSellOrderList(res.data?.data, res.data.total_records));
        dispatch(stopLoader());
        return res.data.data;
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function getSwapSellOrderCSVListFn(data) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };

    // dispatch(startLoader());
    return userService
      .getSwapSellOrderListApi(data, options)
      .then((res) => {
        //dispatch(getSwapSellOrderList(res.data?.data, res.data.total_records));
        //  dispatch(stopLoader());
        return res.data.data;
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function verifiedOrderFn(data) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };

    dispatch(startLoader());
    return userService
      .verifiedOrder(data, options)
      .then((res) => {
        // dispatch(getSwapOrderList(res.data?.data, res.data.total_records));
        dispatch(stopLoader());
        return res.data.data;
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function cancelOrderFn(data) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };

    dispatch(startLoader());
    return userService
      .cancelOrder(data, options)
      .then((res) => {
        // dispatch(getSwapOrderList(res.data?.data, res.data.total_records));
        dispatch(stopLoader());
        return res.data.data;
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function getPendingSell() {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    // dispatch(startLoader());
    return userService
      .getCountPendingSell(options)
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        // dispatch(stopLoader());
      });
  };
}
