import React from 'react';
import { Container, Row, Col } from 'shards-react';
import {
  Table,
  Form,
  Button,
  Dropdown,
  Card,
  Label,
  Image,
} from 'react-bootstrap';
import PageTitle from '../../common/PageTitle';
import Avatar from '../../../images/user-profile/default-profile-pic.png';
import { connect } from 'react-redux';
import 'moment/locale/en-gb';
import 'rc-datepicker/lib/style.css';
import '../../../App.css';
import './profile.css';

import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { get2fastatusFn,getAdminProfile } from '../../../Redux/Actions/profile/profile.actions';

class Users extends React.Component {
  constructor() {
    super();
    this.state = {
      user_profile_img: '',
      src: '',
    };
  }
 
  componentDidMount = () => {
    this.props.get2fastatusFn();
    this.props.getAdminProfile();
  };

  changePassword = () => {
    this.props.history.push('/auth/admin-dashboard-section/change-password');
  };

  editProfile = () => {
    this.props.history.push('/auth/admin-dashboard-section/edit-profile');
  }

  render() {
    let { twoFastatusData, adminData } = this.props;
   
    return (
      <div>
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <Col lg={12}>
              <PageTitle
                title="Profile"
                subtitle=""
                className="text-sm-left mb-3"
              />
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <Card>
                <Card.Body>
                <Card.Title>Name</Card.Title>
                <Card.Text>{adminData?.firstname + ' ' + adminData?.lastname}</Card.Text>
                  <Card.Title>Email</Card.Title>
                  <Card.Text>{adminData?.email}</Card.Text>
                  <Card.Title>Mobile</Card.Title>
                  <Card.Text>{adminData?.mobile}</Card.Text>
                </Card.Body>
                <Button onClick={this.editProfile}>
                      Edit
                    </Button>
              </Card>
            </Col>

            <Col md={8}>
              <Card>
                <Card.Body>
                  <div className="d-flex justify-content-between align-items-start">
                    <div>
                      <h4 className="text-primary mb-0">Change Password</h4>
                      <p>
                        This password is required for login, Never give your
                        password away.{' '}
                      </p>
                      <br />
                    </div>

                    <Button onClick={this.changePassword}>
                      Change Password
                    </Button>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between align-items-start">
                    <div>
                      <h4 className="text-primary mb-0">
                        Google Authentication
                      </h4>
                      <p>Used for secure login. </p>
                      <br />
                    </div>

                    <Button
                      onClick={() =>
                        this.props.history.push(
                          '/auth/admin-dashboard-section/google-authentication-setting'
                        )
                      }
                    >
                      {!twoFastatusData?.is_google_2fa_active
                        ? 'Enable'
                        : 'Disable'}
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {

  return {
    //pairListData: state.orders.pairListData,
    twoFastatusData: state.profile.twoFastatusData,
    adminData: state.profile.adminData
    
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // getPairListFn: (data) => dispatch(getPairListFn(data))
    get2fastatusFn: (data) => dispatch(get2fastatusFn(data)),
    getAdminProfile:()=>dispatch(getAdminProfile())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
