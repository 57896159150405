import React, { useState, useRef } from 'react';
import { Row, Col } from 'shards-react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import _ from 'lodash/fp';
import { useForm } from 'react-hook-form';
import HidePassword from '../profile/images/eye_close.png';
import showPassword from '../profile/images/eye_open.png';
import { Image } from 'semantic-ui-react';
import LoginIcon from '../../../images/icon-login.svg';
import Logo from '../../../images/admin-bitqik.png';

function Login(props) {
  const { register, handleSubmit, errors } = useForm();
  const [showpassword, setshowpassword] = useState(false);
  const formRef = useRef();

  const onSubmit = (data) => {
    props.handleFormSubmit(data);
  };

  let showmodal = true;
  return (
    <>
      <div>
        <div className="Login">
          {/* <loaderComponent></loaderComponent> */}

          <Modal
            show={/* this.state. */ showmodal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="Login"
          >
            <Modal.Body
              style={{
                boxShadow: '15px 15px 53px 0px rgba(1,32,58,1)',
              }}
            >
              <Row>
                <Col lg={6} className="p-5">
                  <Image src={Logo} className="w-50 mb-5" />
                  <Modal.Title
                    className="w-100 text-black"
                    id="contained-modal-title-vcenter"
                  >
                    Log in
                  </Modal.Title>
                  <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Email address</Form.Label>
                      <input
                        id="email"
                        //type="email"
                        name="email"
                        ref={register({
                          required: true,
                          //maxLength: 20,
                          pattern: /^\S+@\S+$/i,
                        })}
                        className="form-control"
                      />

                      {_.get('email.type', errors) === 'required' && (
                        <p style={{ color: 'red' }}>This field is required</p>
                      )}

                      {_.get('email.type', errors) === 'pattern' && (
                        <p style={{ color: 'red' }}>Email address not valid</p>
                      )}
                    </Form.Group>
                    <Form.Label>Password</Form.Label>
                    <Form.Group controlId="formBasicPassword">
                      <input
                        id="password"
                        type={showpassword ? 'text' : 'password'}
                        name="password"
                        ref={register({
                          required: true,
                          //pattern: /^[A-Za-z]+$/i
                        })}
                        className="form-control"
                      />
                      <a
                        className="showPassword"
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();
                          setshowpassword(!showpassword);
                        }}
                      >
                        {showpassword ? (
                          <img
                            className="light_icon"
                            src={showPassword}
                            alt=""
                          />
                        ) : (
                          <img
                            className="blue_icon"
                            src={HidePassword}
                            alt=""
                          />
                        )}
                      </a>
                      {_.get('password.type', errors) === 'required' && (
                        <p style={{ color: 'red' }}>This field is required</p>
                      )}
                    </Form.Group>
                    {/* <Row>
                    <Col className="recaptch_block d-flex justify-content-center">
                      <ReCAPTCHA
                        theme="light"
                        sitekey={capthaKey}
                        onExpired={() => resetCaptcha()}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row> */}
                    <div className="text-center mt-4">
                      <Button
                        id="loginsubmit"
                        variant="primary"
                        className="pl-5 pr-5"
                        block
                        type="submit"
                      >
                        <Spinner
                          id="spinner"
                          style={{ display: 'none' }}
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />{' '}
                        <span id="submit-login">Submit</span>{' '}
                      </Button>
                    </div>
                  </Form>
                </Col>
                <Col
                  style={{
                    backgroundColor: '#F8F8F8',
                    borderRadius: "0.5rem"
                  }}
                  lg={6}
                  className={'login-icon-box'}
                >
                  <Row className="justify-content-center item-align-center h-100">
                    <Col className="m-auto">
                      <Image src={LoginIcon} className="" />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default Login;
