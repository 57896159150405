import React, { useEffect } from "react";

import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { getPairById } from "../../../Services/API/currency.service";

import { useFormik } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { editFee } from "../../../Redux/Actions/currency/currency.action";
const EditCurrencyForm = (props) => {
  const token = useSelector((state) => state?.auth?.tokens);
  const pairId = props.match.params.pairId;
  const pairCoin = props.match.params.pairCoin;

  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      minTradeLimit: "",
      liquidity_fee: "",
      binance_fee: "",
      amount_decimal: "",
      price_decimal: "",
    },
    validationSchema: Yup.object({
      minTradeLimit: Yup.number().required("Minimum trade limit is Required"),
      liquidity_fee: Yup.number().required("Trade Fee is Required"),
      binance_fee: Yup.number().required("Binance Fee is Required"),
      amount_decimal: Yup.number().required("Binance Fee is Required"),
      price_decimal: Yup.number()
        .integer("Decimal value not allowed ! ")
        .required("Binance Fee is Required"),
    }),
    onSubmit: (values) => {
      let { handleEditUserForm } = props;
      values.pairId = pairId;
      values.pair = pairCoin;
      values.buyFee = values.liquidity_fee;
      values.sellFee = values.binance_fee;

      handleEditUserForm(values, history).then((res) => {
        // history.push("/admin/auth/currency-pairs");
        // window.location.reload();
      });
    },
  });

  useEffect(() => {
    let options = {
      "api-access-token": token,
    };

    getPairById({ pairKey: window.location.pathname.split("/")[6] }, options)
      .then((res) => {
        formik.setFieldValue(
          "minTradeLimit",
          Number(res?.data?.data[0].minTradeLimit) / 10 ** 8
        );
        formik.setFieldValue(
          "liquidity_fee",
          Number(res?.data?.data[0].liquidity_fee) / 10 ** 8
        );
        formik.setFieldValue(
          "binance_fee",
          Number(res?.data?.data[0].binance_fee) / 10 ** 8
        );
        formik.setFieldValue(
          "amount_decimal",
          res?.data?.data[0].amount_decimal
        );
        formik.setFieldValue("price_decimal", res?.data?.data[0].price_decimal);
      })
      .catch((error) => {});
  }, []);

  return (
    <Container fixed="lg" className={"py-4"}>
      <Card style={{ minHeight: "50dvh" }}>
        <Card.Body>
          <Row className="page-header pb-3 justify-content-space-between">
            <Col>
              <h3 className="page-title">{"Edit Fee"}</h3>
            </Col>
          </Row>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col xs={12} xl={6}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Min Trade Limit</Form.Label>
                  {/* <label htmlFor="firstName">First Name</label> */}
                  <Form.Control
                    id="minTradeLimit"
                    name="minTradeLimit"
                    type="number"
                    placeholder="Min Trade Limit"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    maxLength={9}
                    pattern="[+-]?\d+(?:[.,]\d+)?"
                    value={formik.values.minTradeLimit}
                  />
                  {formik.touched.minTradeLimit &&
                  formik.errors.minTradeLimit ? (
                    <p style={{ color: "red" }}>
                      {" "}
                      {formik.errors.minTradeLimit}
                    </p>
                  ) : null}
                </Form.Group>
              </Col>
              <Col xs={12} xl={6}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Trade Fee</Form.Label>
                  <Form.Control
                    id="liquidity_fee"
                    name="liquidity_fee"
                    placeholder="Trade Fee"
                    type="number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.liquidity_fee}
                  />
                  {formik.touched.liquidity_fee &&
                  formik.errors.liquidity_fee ? (
                    <p style={{ color: "red" }}>
                      {" "}
                      {formik.errors.liquidity_fee}
                    </p>
                  ) : null}
                </Form.Group>
              </Col>

              <Col xs={12} xl={6}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Binance Fee</Form.Label>
                  <Form.Control
                    id="binance_fee"
                    name="binance_fee"
                    placeholder="Binance Trade Fee"
                    type="number"
                    disabled="true"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.binance_fee}
                  />
                  {formik.touched.binance_fee && formik.errors.binance_fee ? (
                    <p style={{ color: "red" }}> {formik.errors.binance_fee}</p>
                  ) : null}
                </Form.Group>
              </Col>

              <Col xs={12} xl={6}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Amount Decimal</Form.Label>
                  <Form.Control
                    id="amount_decimal"
                    name="amount_decimal"
                    placeholder="Amount decimal"
                    type="number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.amount_decimal}
                  />
                  {formik.touched.amount_decimal &&
                  formik.errors.amount_decimal ? (
                    <p style={{ color: "red" }}>
                      {" "}
                      {formik.errors.amount_decimal}
                    </p>
                  ) : null}
                </Form.Group>
              </Col>

              <Col xs={12} xl={6}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Price Decimal</Form.Label>
                  <Form.Control
                    id="price_decimal"
                    name="price_decimal"
                    placeholder="Price decimal"
                    type="number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.price_decimal}
                  />
                  {formik.touched.price_decimal &&
                  formik.errors.price_decimal ? (
                    <p style={{ color: "red" }}>
                      {" "}
                      {formik.errors.price_decimal}
                    </p>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col className={"d-flex justify-content-center mt-2"}>
                <Button variant="primary" type="submit">
                  {" "}
                  Update{" "}
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};
const mapStateToProps = (state) => {
  return {
    token: state.auth.tokens,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleEditUserForm: (data, history) => dispatch(editFee(data, history)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditCurrencyForm);
