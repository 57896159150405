import React, { useEffect } from 'react';
import Routes from './Routes/routes';
import { GlobalDebug } from './Constants/remove-console';

export default () => {
  useEffect(() => {
    (process.env.NODE_ENV === 'production' ||
      process.env.REACT_APP_ENV === 'STAGING') &&
      GlobalDebug(false);
  }, []);
  return <Routes />;
};
