import { fetch } from "../axios.service";
import {
  USERS_BASE_URL,
  TRADING_BASE_URL,
  WALLET_BASE_URL,
} from "../../Constants/constant";

export const withdrawListApi = (data, options) => {
  return fetch(
    "post",
    `${WALLET_BASE_URL}/admin/getAllWithdraws`,
    data,
    options
  );
};

export const withdrawFilterApi = (coin, data, options) => {
  console.log(data, "aaa");
  return fetch(
    "post",
    `${WALLET_BASE_URL}/${coin}/admin/withdraw_transactions_search/${data.page}`,
    data,
    options
  );
};
export const permissionRequest = (coin, data, options) => {
  return fetch(
    "post",
    `${WALLET_BASE_URL}/${coin}/admin/withdraw-approval-request`,
    data,
    options
  );
};

export const getCountPendingWithdraws = (options) => {
  return fetch(
    "get",
    `${WALLET_BASE_URL}/admin/getCountPendingWithdraws`,
    options
  );
};
