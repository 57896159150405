import React from 'react';
import { Container, Row, Col } from 'shards-react';
import {
  Table,
  Form,
  Button,
  Dropdown,
  Card,
  Label,
  Input,
} from 'react-bootstrap';
import PageTitle from '../../common/PageTitle';
import { connect } from 'react-redux';
import 'moment/locale/en-gb';
import 'rc-datepicker/lib/style.css';
import '../../../App.css';

import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import QR from 'qrcode-base64';
import QRCode from 'qrcode.react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  enableGoogleAuthFn,
  get2fastatusFn,
  enableGoogleValidateFn,
  disableGoogleValidateFn,
} from '../../../Redux/Actions/profile/profile.actions';
import './googleAuth.css';
import { toast } from '../../Toast/toast.component';

class Users extends React.Component {
  constructor() {
    super();
    this.state = {
      token: '',
      secretKeyCopied: '',
      value: '',
      copied: false,
      accept: false,
    };
  }

  componentDidMount = () => {
    let params = { issuer: 'bitqik' };
    this.props.get2fastatusFn();
    this.props.enableGoogleAuthFn(params);
  };

  changePassword = () => {
    this.props.history.push('/auth/admin-dashboard-section/change-password');
  };

  enableGoogleAuth = () => {
    let enableGoogleParams = {
      secret: this.props.enableGoogleAuthData?.secret,
      token: this.state.token,
    };
    this.props.enableGoogleValidateFn(enableGoogleParams, this.props.history);
  };

  disableGoogleAuth = () => {
    let disableGoogleAuthParams = {
      token: this.state.token,
    };
    this.props.disableGoogleValidateFn(
      disableGoogleAuthParams,
      this.props.history
    );
  };
  googlecheckenabled = (e) => {
    this.setState({
      secretKeyCopied: this.state.token,
    });
    if (e.target.checked == false) {
      this.setState({ accept: false });
    } else {
      this.setState({ accept: true });
    }
  };

  render() {
    let { enableGoogleAuthData, twoFastatusData } = this.props;
    return (
      <div>
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <Col lg={12}>
              <PageTitle
                title="Enable Google Authentication"
                subtitle=""
                className="text-sm-left mb-3"
              />
            </Col>
          </Row>

          {!twoFastatusData?.is_google_2fa_active ? (
            <div style={{ textAlign: 'center' }}>
              <Card>
                <Card.Body>
                  <img src={enableGoogleAuthData?.qrImgUrl} />
                </Card.Body>

                <Form.Group controlId="formBasicEmail" className="w-50 mx-auto">
                  <Form.Label>Verify Token</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Token"
                    onChange={(e) =>
                      this.setState({ token: e.target.value, copied: false })
                    }
                    required
                  />
                </Form.Group>
                <br />
                <Form.Group
                  controlId="formBasicEmail"
                  className="w-50 mx-auto copy_btn"
                >
                  <Form.Control
                    type="text"
                    value={enableGoogleAuthData?.secret}
                    disabled
                  />
                  <CopyToClipboard
                    text={enableGoogleAuthData?.secret}
                    onCopy={() =>
                      this.setState({
                        copied: true,
                      })
                    }
                  >
                    <Button>Copy</Button>
                  </CopyToClipboard>
                </Form.Group>
                {this.state.copied ? (
                  <span style={{ color: 'green' }}>
                    {toast.copied('Copied')}
                  </span>
                ) : null}
                <div key={`default-checkbox`} className="mb-3">
                  <Form.Check
                    type="checkbox"
                    id={`default-checkbox`}
                    label="Make sure you have saved your key"
                    disabled={!this.state.token}
                    onChange={this.googlecheckenabled}
                  />
                  <Button
                    style={{ margin: '10px' }}
                    onClick={this.enableGoogleAuth}
                    disabled={!this.state.accept || !this.state.token}
                  >
                    Submit
                  </Button>
                  <Button
                    onClick={() => this.props.history.push('/auth/dashboard')}
                  >
                    Cancel
                  </Button>
                </div>
              </Card>
            </div>
          ) : (
            <>
              <div style={{ textAlign: 'center' }}>
                <Card>
                  <Form.Group
                    controlId="formBasicEmail"
                    className="w-50 mx-auto"
                  >
                    <Form.Label>
                      Enter Token To Disable Google Authenticator
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Token"
                      onChange={(e) => this.setState({ token: e.target.value })}
                      required
                    />
                  </Form.Group>
                  <br />
                  <div className="mb-3">
                    <Button
                      style={{ margin: '10px' }}
                      onClick={this.disableGoogleAuth}
                    >
                      Update
                    </Button>
                    <Button
                      onClick={() => this.props.history.push('/auth/dashboard')}
                    >
                      Cancel
                    </Button>
                  </div>
                </Card>
              </div>
            </>
          )}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //pairListData: state.orders.pairListData,,
    enableGoogleAuthData: state.profile.enableGoogleAuthData,
    twoFastatusData: state.profile.twoFastatusData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // getPairListFn: (data) => dispatch(getPairListFn(data))
    enableGoogleAuthFn: (data) => dispatch(enableGoogleAuthFn(data)),
    get2fastatusFn: (data) => dispatch(get2fastatusFn(data)),
    disableGoogleValidateFn: (data, history) =>
      dispatch(disableGoogleValidateFn(data, history)),
    enableGoogleValidateFn: (data, history) =>
      dispatch(enableGoogleValidateFn(data, history)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
