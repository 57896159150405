import * as userService from "../../../Services/API/currency.service";
import { toast } from "../../../Components/Toast/toast.component";
import * as types from "../../../Constants/_Types/types.reduxStore";
import { startLoader, stopLoader } from "../Loader/loader.action";
import { BASEURL, SUCCESS_MESSAGE } from "../../../Constants/constant";
//import { reset } from 'redux-form';

const buyCryptodata = (BuyData) => {
    // console.log(BuyData)
  return {
    type: types.BUY_CRYPTO,
    payload: {
      Buydata: BuyData,
    },
  };
};

const SellCryptodata = (SellData) => {
  // console.log(BuyData)
return {
  type: types.SELL_CRYPTO,
  payload: {
    Selldata:SellData,
  },
};
};

const Withdrawdata = (WithdrawData) => {
  // console.log(BuyData)
  console.log("WithdrawData",WithdrawData)
return {
  type: types.With_draw,
  payload: {
    WithdrawData:WithdrawData,
  },
};
};

const Exchnageswdata = (Exchnageswdata) => {
  console.log("Exchnageswdata",Exchnageswdata)
return {
  type: types.Exchange_trade,
  payload: {
    Exchnageswdata:Exchnageswdata,
  },
};
};


export function getBuyData(data, history) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .buyCrypto(data, options)
      .then((res) => {
        dispatch(buyCryptodata(res?.data?.result));
        toast.success(res?.data?.message);
        dispatch(stopLoader());
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function getSellData(data, history) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .sellCrypto(data, options)
      .then((res) => {
        dispatch(SellCryptodata(res?.data?.result));
        toast.success(res?.data?.message);
        dispatch(stopLoader());
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}


export function getWithdrawData(data, history) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .withdrawPnl(data, options)
      .then((res) => {
        console.log("res.data",res.data)
        dispatch(Withdrawdata(res?.data?.data));
        toast.success(res?.data?.message);
        dispatch(stopLoader());
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function getExchangesData(data, history) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .OrderExchnagesPnl(data, options)
      .then((res) => {
        console.log("res.data",res.data)
        dispatch(Exchnageswdata(res?.data));
        toast.success(res?.data?.message);
        dispatch(stopLoader());
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}
