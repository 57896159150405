import React from "react";
import { Button, Card, Col, Container, Row, Table } from "react-bootstrap";

import {
  currencyAuthFn,
  currencyFn,
} from "../../../Redux/Actions/currency/currency.action";
import { activeCoinsFn } from "../../../Redux/Actions/dashboard/dashboard.action";

import "moment/locale/en-gb";
import "rc-datepicker/lib/style.css";
import { connect } from "react-redux";
import "../../../App.css";
import { toast } from "../../../Components/Toast/toast.component";
import { MoneyFormat } from "../../../Constants/constant";
import CurrencyAuthModal from "../currency/currencyAuth.modal";
import "./currencyStyle.css";

class Users extends React.Component {
  constructor() {
    super();
    this.state = {
      currencyData: [],
      showAuthModal: false,
      coiname: "",
      backdrop: "/",
    };
  }
  // new component did mount
  componentDidMount = async () => {
    const { activeCoinsFn, currencyFn } = this.props;

    try {
      const response = await activeCoinsFn();

      // Sort the response
      const sortedCoins = response.sort((a, b) => a.localeCompare(b));

      // Use Promise.all to handle multiple asynchronous operations concurrently
      const currencyData = await Promise.all(
        sortedCoins.map(async (coinData) => {
          const coinDta = await currencyFn(coinData);
          return coinDta;
        })
      );

      // Update state once with the entire result
      this.setState({ currencyData });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  getData = (value) => {
    let { currencyAuthFn } = this.props;
    if ("Tn$E4y6Ghj^gUmC#" === value) {
      currencyAuthFn(true);
      this.props.history.push(
        `/auth/currency-manage/edit-fee-limit/${this.state.coiname}`
      );
    } else {
      currencyAuthFn(false);
      toast.error("This is incorrect Password please try again.");
    }
  };

  render() {
    let { currencyData } = this.state;

    return (
      <div>
        <Container fixed="lg" className={"py-4"}>
          <Card>
            <Card.Body>
              <Row className="page-header pb-3 justify-content-start">
                <Col>
                  <h3 className="page-title">{"Currency Management"}</h3>
                </Col>
              </Row>
              <Row flex-wrap>
                {currencyData.map((crncy) => {
                  return (
                    <>
                      <Col xs={12} xl={6}>
                        <Card border="warning" className="mb-4">
                          <Card.Body>
                            <Row className=" pb-3 justify-content-start">
                              <Col>
                                <h5 className="page-title">{crncy?.name}</h5>
                              </Col>
                              <Col className={"d-flex justify-content-end"}>
                                <Button
                                  size="sm"
                                  className="custom-btn-outlined-primary"
                                  variant="outline-warning"
                                  onClick={() =>
                                    this.setState({
                                      showAuthModal: this.state.showAuthModal
                                        ? false
                                        : true,
                                      pairId:
                                        crncy?.tarding_pair_id &&
                                        crncy?.tarding_pair_id,
                                      coiname:
                                        crncy.name && crncy.name.toLowerCase(),
                                    })
                                  }
                                  disabled={this.state.showAuthModal}
                                >
                                  Set Limit
                                </Button>
                              </Col>
                            </Row>
                            <Table bordered size="sm">
                              <tbody>
                                <tr>
                                  <td>
                                    <span className="text-muted">
                                      Min Withdraw Amount:
                                    </span>
                                  </td>
                                  <td>
                                    <span className="text-muted">
                                      Withdraw Limit With KYC:
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <span className="text-bold">
                                      {MoneyFormat(crncy?.minimum_withdraw)}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="text-bold">
                                      {MoneyFormat(
                                        crncy?.with_kyc_withdraw_limit
                                      )}
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <span className="text-muted">
                                      Withdraw Limit Without KYC:
                                    </span>
                                  </td>
                                  <td>
                                    <span className="text-muted">
                                      Withdraw Fee:
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <span className="text-bold">
                                      {MoneyFormat(
                                        crncy?.without_kyc_withdraw_limit
                                      )}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="text-bold">
                                      {MoneyFormat(crncy?.withdraw_fee)}
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan={2}>
                                    <span className="text-muted">
                                      Minimum Approval ( For External
                                      Transactions ):
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan={2}>
                                    <span className="text-bold">
                                      {MoneyFormat(crncy?.min_approval_amount)}
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Card.Body>
                        </Card>
                      </Col>
                    </>
                  );
                })}
              </Row>
            </Card.Body>
          </Card>

          {/* <Row noGutters className="page-header py-4">
            <Col lg={12}>
              <PageTitle
                title="Currency-Management"
                subtitle=""
                className="text-sm-left mb-3"
              />
            </Col>
          </Row> */}

          <CurrencyAuthModal
            show={this.state.showAuthModal}
            backdrop={this.state.backdrop}
            handleClose={() => {
              this.setState({ showAuthModal: false });
            }}
            getPassword={this.getData}
          />
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //pairListData: state.orders.pairListData,
    activeCoins: state.dashboard.activeCoins,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // getPairListFn: (data) => dispatch(getPairListFn(data)),
    activeCoinsFn: (data) => dispatch(activeCoinsFn(data)),
    currencyFn: (data) => dispatch(currencyFn(data)),
    currencyAuthFn: (data) => dispatch(currencyAuthFn(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
