import React from 'react';
import { connect } from 'react-redux';
import { deviceAuthenticateFn } from '../../../Redux/Actions/user/user.action';
import BitQikLogo from '../../../images/user-profile/logoo.svg';
import { decryption } from '../../../Services/axios.service';
import { REACT_APP_URL } from '../../../Constants/constant'

// import { LOGIN_BASE_URL } from "./../../../Constants/constant";
// const ENCDECRYPTKEY="5c6f809520bcdaac956e20098d4b4d66"
const LOGIN_BASE_URL = REACT_APP_URL;

class AuthenticateDevice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
    };
  }

  componentDidMount = () => {
    const data = { hash: this.state.id };
    this.props.deviceAuthenticateFn(data);

    // const uri = BASIC_URI;
    // const encodeduri = encodeURIComponent(uri);
    // console.log("encoded uri ",encodeduri);

    // try {
    //   console.log("decoded uri . ", decodeURIComponent(encodeduri));
    // } catch (error) {
    //   console.log("error", console.error(error))
    // }

    decryption(data);
  };

  render() {
    return (
      <div>
        <div
          style={{
            overflow: 'hidden',
            top: '0',
            height: '64px',
            paddingLeft: '50px',
            paddingTop: '20px',
            backgroundColor: 'white',
            position: 'fixed',
            width: '100%',
          }}
        >
          <div>
            <a href={LOGIN_BASE_URL}>
              <img src={BitQikLogo} alt="BLACKBOX Dashboard" />
            </a>
          </div>
        </div>

        <div
          style={{
            marginTop: '100px',
            backgroundColor: '#FFFAA0',
            position: 'fixed',
            height: '600px',
            width: '100%',
          }}
        >
          <div
            className="container-fluid"
            style={{
              paddingTop: '35px',
              paddingBottom: '35px',
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
            }}
          >
            <div
              className="container pd-0 "
              style={{
                boxShadow: '-2px 9px 23px 0 rgba(75, 64, 64, .09)',
                backGround: 'white',
                width: '100%',
                minWidth: '300px',
                boxSizing: 'border-box',
                position: 'relative',
                margin: '20px auto 50px',
                borderRadius: '1px !important',
                padding: '25px 45px',
                zIndex: '2',
                backgroundColor: '#ffffff',
              }}
            >
              <div className="left-side ">
                <div className="text-center" style={{ marginBottom: '20px' }}>
                  <h2
                    style={{
                      fontSize: '18px',
                      color: '#3f3f3f',
                      fontWeight: 400,
                      marginBottom: '0px',
                    }}
                  >
                    New Device is authorized successfully
                  </h2>
                </div>
                <p
                  style={{
                    fontSize: '17px',
                    textAlign: 'center',
                    fontWeight: 400,
                  }}
                >
                  You have successfully authorised a new device, Please try to
                  log in with it again.<br></br>
                  <a
                    href={LOGIN_BASE_URL}
                    style={{
                      color: '#ffc425',
                      display: 'block',
                      textAlign: 'center',
                      fontWeight: '700',
                      marginTop: '10px',
                      textDecoration: 'none',
                    }}
                  >
                    Log In &nbsp;
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            marginTop: '1rem',
            padding: '1rem',
            backgroundColor: 'white',
            position: 'fixed',
            bottom: '0',
            left: '0',
            width: '100%',
            color: 'gray',
          }}
        >
          <p>
            © @2022 bitqik
            <br></br>

          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    deviceVerification: state.deviceVerification,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deviceAuthenticateFn: (data) => dispatch(deviceAuthenticateFn(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticateDevice);
