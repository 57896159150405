import React, { useEffect } from "react";
import { Container, Row } from "shards-react";
import PageTitle from "../common/PageTitle";
import {
  getDashboardCountsFn,
  getUsersFn,
  getActiveUsersFn,
  // tradeByMonthFn,
  tradeVolumeFn,
  activeCoinsFn,
  getETHUsersFn,
  getWalletBinancedataFn,
} from "../../Redux/Actions/dashboard/dashboard.action";
import { connect } from "react-redux";
import { Card, Col, FormLabel, Table } from "react-bootstrap";
import HomePage from "./Login/HomePage";
import { Link } from "react-router-dom";

import { Bar, Line } from "react-chartjs-2";
import { startLoader } from "../../Redux/Actions/Loader/loader.action";

const datas = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const data = [
  { year: "", population: 55 },
  { year: "1960", population: 7 },
];

class Dashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      USDT: [],
      FireBlock: [],
      offset: 0,
      limit: 10,
      data,
      volumeData: [],
    };
  }

  componentWillReceiveProps = (nextProps) => {
    //nextProps.tradeByMonthFn();
    let { tradeVolume, getwalletbinance, getETHuser } = nextProps;
    // console.log("getwalletbinance",getwalletbinance)
    let getusdt = getwalletbinance.filter((ele, index) => ele.asset === "USDT");
    let getfireblock = getETHuser.filter(
      (ele, index) => ele.id === "BTC" || ele.id === "BTC_TEST"
    );
    this.setState({ FireBlock: getfireblock });
    this.setState({ USDT: getusdt });

    let data = [];
    let x = {};
    if (tradeVolume.volume !== this.props.tradeVolume.volume) {
      tradeVolume?.volume.map((dta) => {
        x.volume = dta;
        data.push(x == null ? 0 : x);
      });
    }

    this.setState({ volumeData: data });
  };
  /* 
      const PENDIND = "0";
      const SUBMITTED = "1";
      const REJECTED = "2";
      const SUCCESS = "3";

  */

  componentDidMount = () => {
    let {
      getDashboardCountsFn,
      getUsersFn,
      getActiveUsersFn,
      // tradeByMonthFn,
      tradeVolumeFn,
      activeCoinsFn,
      getETHUsersFn,
      getWalletBinancedataFn,
    } = this.props;
    getDashboardCountsFn();
    getUsersFn();
    getActiveUsersFn();
    //  tradeByMonthFn();
    tradeVolumeFn();
    activeCoinsFn();
    getETHUsersFn();
    getWalletBinancedataFn();
  };

  pageChange = (e, data) => {
    let page = data.activePage;
    let pageNo = page === 1 ? 0 : (page - 1) * this.state.limit;
    // alert(pageNo)
  };

  render() {
    let {
      dashboardCounts,
      getUser,
      getActiveUser,
      tradeVolume,
      tradeVolumeAmount,
      //tradeByMonth,
      activeCoins,
      totalRecords,
    } = this.props;
    const { data: chartData, volumeData } = this.state;

    const dataSet = {
      labels: [""],
      datasets: [
        {
          label: "NEW USERS",
          backgroundColor: "lightgreen",
          borderColor: "lightgreen",
          borderWidth: 2,
          data: [totalRecords],
        },
        {
          label: "ACTIVE USERS",
          backgroundColor: "skyblue",
          borderColor: "skyblue",
          borderWidth: 2,
          data: [
            getActiveUser.data && getActiveUser.data[0]
              ? getActiveUser.data[0]?.activeUser
              : 0,
          ],
        },
      ],
    };

    // var finalTrade = {};
    // console.log(tradeByMonth, "tradeByMonth");
    // for (var i = 0; i < tradeByMonth.length; i++) {
    //   for (var j in tradeByMonth[i]) {
    //     if (!finalTrade[j]) {
    //       finalTrade[j] = [];
    //     }
    //     finalTrade[j].push(tradeByMonth[i][j]);
    //   }
    // }

    // const dataSetRevenue = {
    //   labels: [
    //     "Jan",
    //     "Feb",
    //     "Mar",
    //     "Apr",
    //     "May",
    //     "Jun",
    //     "Jul",
    //     "Aug",
    //     "Sep",
    //     "Oct",
    //     "Nov",
    //     "Dec",
    //   ],
    //   datasets: [
    //     {
    //       label: "Withdraw",
    //       fill: true,
    //       lineTension: 0.5,
    //       backgroundColor: "#daf9f7",
    //       borderColor: "#06d79c",
    //       borderWidth: 2,
    //       data: [null].concat(finalTrade?.sumVolume),
    //     },
    //     {
    //       label: "Trade",
    //       fill: true,
    //       lineTension: 0.5,
    //       backgroundColor: "#0271b8",
    //       borderColor: "#0271b8",
    //       borderWidth: 2,
    //       data: finalTrade?.tradesCount,
    //     },
    //   ],
    // };

    const dataSetTrade = {
      labels: tradeVolume?.pair_key,
      datasets: [
        {
          label: "Trade",
          backgroundColor: "lightgreen",
          borderColor: "lightgreen",
          borderWidth: 2,
          data: tradeVolume?.tardes,
        },
      ],
    };

    const dataSetVolume = {
      labels: tradeVolume?.pair_key,
      datasets: [
        {
          label: "Volume",
          backgroundColor: "skyblue",
          borderColor: "skyblue",
          borderWidth: 2,
          data: tradeVolume?.volume,
        },
      ],
    };
    return (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle
            title="Dashboard"
            /* subtitle="Admin" */
            className="text-sm-left mb-3"
            style={{ marginLeft: "10px" }}
          />
        </Row>

        <br />

        <Card className="mb-2">
          <Card.Body>
            <Row className="py-4 px-2">
              <Col lg={3} md={6} className="d-flex mb-3">
                <Card
                  className="w-100"
                  style={{ width: "15rem", backgroundColor: "#0047AB" }}
                >
                  <Link to="/auth/traders">
                    <Card.Body>
                      <Card.Title>
                        <h4 className="text-white">TOTAL TRADERS </h4>
                      </Card.Title>
                      <Card.Text>
                        <h1 className="text-white">
                          {" "}
                          {dashboardCounts?.totalTraders
                            ? dashboardCounts.totalTraders
                            : 0}{" "}
                        </h1>
                      </Card.Text>
                    </Card.Body>
                  </Link>
                </Card>
              </Col>

              <Col lg={3} md={6} className="d-flex mb-3">
                <Card
                  className="w-100"
                  style={{ width: "15rem", backgroundColor: "#20c997" }}
                >
                  <Link to="/auth/order-list">
                    <Card.Body>
                      <Card.Title>
                        <h4 className="text-white"> FULFILLED ORDERS</h4>
                      </Card.Title>
                      <Card.Text>
                        <h1 className="text-white">
                          {" "}
                          {dashboardCounts?.completedOrders &&
                          dashboardCounts?.completedOrders !== "Not Available"
                            ? dashboardCounts.completedOrders
                            : 0}{" "}
                        </h1>
                      </Card.Text>
                    </Card.Body>
                  </Link>
                </Card>
              </Col>

              <Col lg={3} md={6} className="d-flex mb-3">
                <Card
                  className="w-100"
                  style={{ width: "15rem", backgroundColor: "#5D3FD3" }}
                >
                  <Link to="/auth/order-list">
                    <Card.Body>
                      <Card.Title>
                        <h4 className="text-white">PENDING ORDERS</h4>
                      </Card.Title>
                      <Card.Text>
                        <h1 className="text-white">
                          {" "}
                          {dashboardCounts?.pendingOrders &&
                          dashboardCounts.pendingOrders !== "Not Available"
                            ? dashboardCounts.pendingOrders
                            : 0}{" "}
                        </h1>
                      </Card.Text>
                    </Card.Body>
                  </Link>
                </Card>
              </Col>

              <Col lg={3} md={6} className="d-flex mb-3">
                <Card
                  className="w-100"
                  style={{ width: "15rem", backgroundColor: "#52e3b8" }}
                >
                  <Link to="/auth/submittedKyc">
                    <Card.Body>
                      <Card.Title>
                        <h4 className="text-white">PENDING KYC's</h4>
                      </Card.Title>
                      <Card.Text>
                        <h1 className="text-white">
                          {" "}
                          {dashboardCounts?.pendingKyc
                            ? dashboardCounts.pendingKyc
                            : 0}{" "}
                        </h1>
                      </Card.Text>
                    </Card.Body>
                  </Link>
                </Card>
              </Col>

              <Col lg={3} md={6} sm={12} className="d-flex mb-3">
                <Card
                  className="w-100"
                  style={{ width: "15rem", backgroundColor: "#0047AB" }}
                >
                  <Link to="/auth/binance">
                    <Card.Body>
                      <Card.Title>
                        <h4 className="text-white">BINANCE</h4>
                      </Card.Title>
                      <Card.Text>
                        <h1 className="text-white">
                          {" "}
                          {this.state.USDT.length > 0
                            ? this.state.USDT[0].free
                            : 0}{" "}
                          <sup> USDT</sup>
                        </h1>
                      </Card.Text>
                    </Card.Body>
                  </Link>
                </Card>
              </Col>
              <Col lg={3} md={6} sm={12} className="d-flex mb-3">
                <Card
                  className="w-100"
                  style={{ width: "15rem", backgroundColor: "#5D3FD3" }}
                >
                  <Link to="/auth/fireblocks">
                    <Card.Body>
                      <Card.Title>
                        <h4 className="text-white">FIRE BLOCKS</h4>
                      </Card.Title>
                      <Card.Text>
                        <h1 className="text-white">
                          {" "}
                          {this.state.FireBlock.length > 0
                            ? this.state.FireBlock[0].balance
                            : 0}{" "}
                          <sup> BTC</sup>
                        </h1>
                      </Card.Text>
                    </Card.Body>
                  </Link>
                </Card>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Row className="graphRow">
          <Col lg={6} md={12}>
            <Card className="commonCard_style">
              <Card.Title>
                <h3 style={{ marginLeft: "70px", marginTop: "20px" }}>
                  {" "}
                  NEW USERS Vs ACTIVE USERS{" "}
                </h3>
              </Card.Title>
              <Row style={{ padding: "10px" }}>
                <Col>
                  <div>
                    <Bar
                      data={dataSet}
                      options={{
                        title: {
                          display: true,
                          text: "NEW USERS Vs ACTIVE USERS",
                          fontSize: 20,
                        },
                        legend: {
                          display: true,
                          position: "top",
                        },
                      }}
                    />
                  </div>
                </Col>

                {/* <Col >
              <div style={{ marginLeft: "30px", marginTop: "30px"}}>
                <h3 style={{ marginLeft: "0px", marginTop: "-70px" }}>REVENUE</h3>
               
                  {console.log(dataSetRevenue,finalTrade,"dataSetRevenue")}
                <div>
                  <Line
                    data={dataSetRevenue}
                    width={700}
                    height={300}
                    options={{
                      title:{
                        display:true,
                        text:'REVENUE',
                        fontSize:20
                      },
                      legend:{
                        display:'false',
                        position:'bottom',
                        usePointStyle:'true'
                      }
                    }}
                  />
                </div>
              </div>
            </Col> */}
              </Row>
            </Card>
          </Col>
          <Col lg={6} md={12}>
            <Card className="commonCard_style">
              <Card.Title>
                <h3 style={{ marginLeft: "10px" }}> TRADE COMPARISION </h3>
              </Card.Title>
              <Row>
                <Col>
                  <div>
                    <Bar
                      data={dataSetTrade}
                      options={{
                        title: {
                          display: true,
                          text: "TRADE COMPARISION",
                          fontSize: 20,
                        },
                        legend: {
                          display: true,
                          position: "top",
                        },
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col lg={6} md={12}>
            <Card className="commonCard_style">
              <Card.Title>
                <h3 style={{ marginLeft: "10px" }}> VOLUME COMPARISION </h3>
              </Card.Title>
              <Row>
                <Col>
                  <div>
                    <Bar
                      data={dataSetVolume}
                      options={{
                        title: {
                          display: true,
                          text: "VOLUME COMPARISION",
                          fontSize: 20,
                        },
                        legend: {
                          display: true,
                          position: "top",
                        },
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dashboardCounts: state.dashboard.dashboardCounts,
    getUser: state.dashboard.getUser,
    getActiveUser: state.dashboard.getActiveUser,
    totalRecords: state.dashboard.totalRecords,
    tradeVolume: state.dashboard.tradeVolume,
    // tradeByMonth: state.dashboard.tradeByMonth,
    activeCoins: state.dashboard.activeCoins,
    getwalletbinance: state.dashboard.getwalletbinance,
    getETHuser: state.dashboard.getETHuser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDashboardCountsFn: (data) => dispatch(getDashboardCountsFn(data)),
    getUsersFn: (data) => dispatch(getUsersFn(data)),
    getActiveUsersFn: (data) => dispatch(getActiveUsersFn(data)),
    tradeVolumeFn: (data) => dispatch(tradeVolumeFn(data)),
    // tradeByMonthFn: (data) => dispatch(tradeByMonthFn(data)),
    activeCoinsFn: (data) => dispatch(activeCoinsFn(data)),
    getETHUsersFn: (data) => dispatch(getETHUsersFn(data)),
    getWalletBinancedataFn: (data) => dispatch(getWalletBinancedataFn(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
