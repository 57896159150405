import React from "react";
import { Container, Row, Col } from "shards-react";
import { Table, Form, Button, Dropdown, Modal } from "react-bootstrap";
import { Pagination, Icon } from "semantic-ui-react";
import { Link, Redirect } from "react-router-dom";
import PageTitle from "../../../common/PageTitle";
import {
  getUsersFn,
  getActiveUsersFn,
  userActionFn,
  sendAuthKeyFn,
  filterUsersFn,
  submittedKycFn,
  getKycCountFn,
  deleteApprovedKycFn,
} from "../../../../Redux/Actions/user/user.action";
import { DatePicker } from "rc-datepicker";
import { connect } from "react-redux";
import "moment/locale/en-gb";
import "rc-datepicker/lib/style.css";
import {
  offset,
  limit,
  USER_TYPE,
  COUNTRY,
} from "../../../../Constants/constant";
import queryString from "query-string";
import "../../user/Users.css";
import "../../../../App.css";
import Moment from "react-moment";
import dateFormat from "dateformat";

import ImportCsv from "../../../common/common";

class Users extends React.Component {
  constructor() {
    super();
    this.state = {
      disabled: true,
      stateCsvData: [],
      totalDataCount: 0,
      page: 1,
      searchData: "",
      deletedataPopup: false,
      deletedId: "",
    };
  }

  componentWillReceiveProps = (nextProps) => {
    let { submittedKycData } = nextProps;
    let statementCsvData = [];
    if (submittedKycData !== undefined && submittedKycData.length > 0) {
      submittedKycData.map((stateData) => {
        let data = {
          Name:
            stateData.firstname != null || stateData.lastname != null
              ? stateData?.firstname + " " + stateData?.lastname
              : "N/A",
          Email: stateData.email != null ? stateData?.email : "N/A",
          "Kyc Submitted Date":
            stateData.submitted_at != null
              ? stateData.submitted_at.split("T")[0]
              : "N/A",
        };

        statementCsvData.push(data);
      });
      this.setState({ stateCsvData: statementCsvData });
    } else {
      this.setState({
        stateCsvData: "",
        // dataLength: nextProps.customerDataLength,
      });
    }
  };

  componentDidMount = () => {
    let values = queryString.parse(this.props.location.search);
    let {
      getUsersFn,
      getActiveUsersFn,
      userActionFn,
      submittedKycFn,
      getKycCountFn,
    } = this.props;
    let searchedParams = { page: this.state.page, search: "", status: "1" };

    //getUsersFn(searchedParams);
    submittedKycFn(searchedParams);
    getActiveUsersFn();
    getKycCountFn({ status: "1" });
  };

  search = () => {};

  clearSearch = () => {
    let searchedParams = { page: this.state.page, search: "", status: "1" };
    this.setState({
      searchedData: "",
    });
    //getUsersFn(searchedParams);
    this.props.submittedKycFn(searchedParams);
  };

  pageChange = (e, data) => {
    let page = data.activePage;
    let pageNo = page === 1 ? 0 : (page - 1) * limit;

    let searchedParams = {
      page: page,
      status: "1",
    };
    this.props.submittedKycFn(searchedParams);
  };

  searchValues = () => {
    let { submittedKycFn } = this.props;

    let searchedData = {
      page: this.state.page,
      search: this.state.searchedData,
      status: "1",
    };

    submittedKycFn(searchedData);
  };

  keyEnter = (e) => {
    this.searchValues();
  };

  getSearchData = (e) => {
    this.setState({ searchedData: e.target.value });
    if (e.target.value == "") {
      this.setState({ disabled: true });
    } else {
      this.setState({ disabled: false });
    }
  };

  deleteKycData = (userId) => {
    let searchedParams = { page: this.state.page, search: "", status: "1" };
    let { deleteApprovedKycFn, submittedKycFn } = this.props;
    deleteApprovedKycFn(userId).then(() => {
      submittedKycFn(searchedParams);
      this.setState({
        deletedataPopup: false,
      });
    });
  };
  handleClose = () => {
    this.setState({
      deletedataPopup: false,
    });
  };
  handleDeletepopup = (id) => {
    this.setState({
      deletedataPopup: true,
      deletedId: id,
    });
  };
  render() {
    let { usersList, activeUsers, totalRecords, submittedKycData, kycCount } =
      this.props;

    return (
      <div>
        <Container fluid className="main-content-container px-4">
          {/* Page Header */}
          <Row noGutters className="page-header py-4">
            <Col lg={12}>
              <PageTitle
                title="Approved KYC"
                subtitle=""
                className="text-sm-left mb-3"
              />
            </Col>
          </Row>

          <Row className="users_row">
            <Col xl={2} sm={4} xs={2}>
              <Form.Group>
                <Form.Control
                  type="search"
                  name="search"
                  id="search"
                  value={this.state.searchedData}
                  placeholder="Search"
                  onChange={(e) => this.getSearchData(e)}
                  // onKeyPress={(e) => this.keyEnter(e)}
                />
              </Form.Group>
            </Col>
            <Col xl={2} sm={4} xs={12} text-nowrap className="text-nowrap">
              <Button
                variant="primary"
                type="submit"
                // disabled={this.state.disabled}
                onClick={(e) => this.keyEnter(e)}
              >
                Search
              </Button>

              <Button
                variant="danger"
                className="ml-2"
                onClick={this.clearSearch}
              >
                Reset
              </Button>
            </Col>
          </Row>
          <Row>
            <Col></Col>
            <Col style={{ textAlign: "right" }}>
              <ImportCsv
                stateCsvData={this.state.stateCsvData}
                file_name={"users"}
              />
            </Col>
          </Row>

          <Row className="justify-content-end mt-4 p-3 bg-white">
            {submittedKycData != undefined && submittedKycData.length > 0 ? (
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th className="pl-3 text-nowrap">Name</th>
                    <th className="pl-3 text-nowrap">Email Address</th>
                    <th className="pl-3 text-nowrap">KYC Submitted Date</th>
                    <th className="pl-3 text-nowrap">KYC Approved Date</th>
                    <th className="pl-3 text-nowrap">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {submittedKycData.map((submitKycData) => {
                    return (
                      <tr>
                        <td className="pl-3 text-nowrap">
                          {submitKycData.firstname} {submitKycData.lastname}
                        </td>

                        <td className="pl-3 text-nowrap">
                          {submitKycData.email}
                        </td>

                        <td className="pl-3 text-nowrap">
                          {dateFormat(submitKycData.submitted_at, "dd/mm/yyyy")}
                        </td>

                        <td className="pl-3 text-nowrap">
                          {dateFormat(submitKycData.submitted_at, "dd/mm/yyyy")}
                        </td>

                        <td className="pl-3 text-nowrap">
                          <Link
                            to={`/auth/approvekycDetail/${submitKycData.users_id}`}
                          >
                            <Icon style={{ color: "GoldenRod" }} name="eye" />{" "}
                          </Link>
                          |{" "}
                          <Link
                            to={`/auth/member-approved-edit/${submitKycData.users_id}`}
                          >
                            <Icon name="pencil alternate" />{" "}
                          </Link>
                          |{" "}
                          <Link>
                            <Icon
                              style={{ color: "FireBrick" }}
                              name="trash alternate"
                              onClick={() =>
                                this.handleDeletepopup(submitKycData.users_id)
                              }
                            />{" "}
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th className="pl-3 text-nowrap">Name</th>
                    <th className="pl-3 text-nowrap">Email Address</th>
                    <th className="pl-3 text-nowrap">KYC Submitted Date</th>
                    <th className="pl-3 text-nowrap">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <p>No Record Found</p>
                </tbody>
              </Table>
            )}

            {totalRecords < 10 ? (
              <Pagination
                defaultActivePage={1}
                onPageChange={this.pageChange}
                ellipsisItem={{
                  content: <Icon name="ellipsis horizontal" />,
                  icon: true,
                }}
                firstItem={{
                  content: <Icon name="angle double left" />,
                  icon: true,
                }}
                lastItem={{
                  content: <Icon name="angle double right" />,
                  icon: true,
                }}
                prevItem={{ content: <Icon name="angle left" />, icon: true }}
                nextItem={{ content: <Icon name="angle right" />, icon: true }}
                totalPages={Math.ceil(kycCount / limit)}
              />
            ) : (
              ""
            )}
          </Row>
          {this.state.deletedataPopup && (
            <Modal
              show={this.state.deletedataPopup}
              onHide={this.handleClose}
              keyboard={false}
              className="logoutModal"
            >
              <Modal.Header closeButton>
                {/* <Modal.Title>Modal title</Modal.Title> */}
              </Modal.Header>
              <Modal.Body>Are you sure want to delete ?</Modal.Body>
              <Modal.Footer className="mx-auto">
                <Button variant="secondary" onClick={this.handleClose}>
                  Close
                </Button>
                <Button
                  variant="primary"
                  tag={Link}
                  onClick={() => this.deleteKycData(this.state.deletedId)}
                >
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    usersList: state.user.usersList,
    activeUsers: state.user.activeUsers,
    totalRecords: state.user.totalRecords,
    submittedKycData: state.user.submittedKycData,
    kycCount: state.user.kycCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //   dashboardDetailsFn: (data) => dispatch(dashboardDetailsFn(data))
    getUsersFn: (data) => dispatch(getUsersFn(data)),
    getActiveUsersFn: (data) => dispatch(getActiveUsersFn(data)),
    userActionFn: (data) => dispatch(userActionFn(data)),
    sendAuthKeyFn: (data) => dispatch(sendAuthKeyFn(data)),
    filterUsersFn: (data) => dispatch(filterUsersFn(data)),
    submittedKycFn: (data) => dispatch(submittedKycFn(data)),
    getKycCountFn: (data) => dispatch(getKycCountFn(data)),
    deleteApprovedKycFn: (data) => dispatch(deleteApprovedKycFn(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
