import React, { useState } from 'react';
import _ from 'lodash/fp';
import {  Button, Spinner, Form,Modal, } from 'react-bootstrap';
import { Row, Col } from 'shards-react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { google2faAuthenticateFn } from '../../../Redux/Actions/user/user.action';
import { Image } from 'semantic-ui-react';
import LoginIcon from '../../../images/icon-login.svg';
import Logo from '../../../images/admin-bitqik.png';

function GoogleLogin(props) {
  const { errors, register, handleSubmit } = useForm();
  const { token, SetToken } = useState('');

  const onSubmit = (data) => {
    const tempObj = { token: data.token };
    props.google2faAuthenticateFn(tempObj);
  };
  let showmodal = true;
  return (
    <div>
      <div className="Login">
        <Modal
          show={/* this.state. */ showmodal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="Login"
        >
          <Modal.Body
            style={{
              boxShadow: '15px 15px 53px 0px rgba(1,32,58,1)',
            }}
          >
            <Row>
              <Col lg={6} className="p-5">
                <Image src={Logo} className="w-50 mb-5" />
                <Modal.Title
                  className="w-100 text-black"
                  id="contained-modal-title-vcenter"
                >
                  Google Authenticator
                </Modal.Title>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group controlId="formBasicText">
                    <input
                      id="text"
                      type="text"
                      name="token"
                      ref={register({
                        required: true,
                      })}
                      placeholder="Enter your token here"
                      className="form-control"
                    />

                    {_.get('token.type', errors) === 'required' && (
                      <p style={{ color: 'red' }}>Token is required</p>
                    )}
                  </Form.Group>

                  <div className="text-center mt-4">
                    <Button
                      variant="primary"
                      className="pl-10 pr-10 form-control"
                      type="submit"
                    >
                      <Spinner
                        id="spinner"
                        style={{ display: 'none' }}
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />{' '}
                      LOGIN
                    </Button>
                  </div>
                </Form>
              </Col>
              <Col
                style={{
                  backgroundColor: '#F8F8F8',
                }}
                lg={6}
                className={'login-icon-box'}
              >
                <Row className="justify-content-center item-align-center h-100">
                  <Col className="m-auto">
                    <Image src={LoginIcon} className="" />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    //    token: state.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    google2faAuthenticateFn: (data) => dispatch(google2faAuthenticateFn(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GoogleLogin);
