
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser } from "../../Redux/Actions/user/user.action";
import { DefaultLayout } from "../../Components/layouts";

const WithdrawGuard = ({ component: Component, ...rest }) => {
  const { token, role, userDetails, logoutUser, history, withdrawAuth } = rest;
  
  const isAuthenticated = withdrawAuth
  let path = rest.path.split("/")[1];
  return (
    <Route
      {...rest}
      render={ props =>
        isAuthenticated === false ? (
            <>
            <Component {...props} />
          </>
        ) : (
          <>
             <Redirect
              to={{
             pathname: "/auth/withdrawal_request",
                state: {
                  from: props.location
                }
              }}
            
          />

</>
          )
      }
    />
  );
}


const mapStateToProps = state => {
  return {
    withdrawAuth: state.withdraw.withdrawAuth
  };
};



export default connect(mapStateToProps)(WithdrawGuard);

