import * as userService from "../../../Services/API/withdraw.service";
import { toast } from "../../../Components/Toast/toast.component";
import * as types from "../../../Constants/_Types/types.reduxStore";
import { startLoader, stopLoader } from "../Loader/loader.action";
import { BASEURL, SUCCESS_MESSAGE } from "../../../Constants/constant";
//import { reset } from 'redux-form';

const withdrawList = (withdrawListData, totalRecords) => {
  return {
    type: types.WITHDRAW_LIST,
    payload: {
      withdrawListData: withdrawListData,
      totalRecords: totalRecords,
    },
  };
};

const withdrawAuth = (withdrawAuth) => {
  return {
    type: types.WITHDRAW_AUTH,
    payload: {
      withdrawAuth: withdrawAuth,
    },
  };
};
const permissionLIst = (permissionData) => {
  return {
    type: types.PERMISSION_LISTING,
    payload: {
      permissionLIstData: permissionData,
    },
  };
};

export function withdrawAuthFn(isAuth) {
  return (dispatch, getState) => {
    dispatch(withdrawAuth(isAuth));
  };
}

export function withdrawFilterDataFn(coin, data) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .withdrawFilterApi(coin, data, options)
      .then((res) => {
        dispatch(withdrawList(res.data?.data, res.data.totalRecords));
        dispatch(stopLoader());
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function withdrawCSVFilterDataFn(coin, data) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    // dispatch(startLoader());
    return userService
      .withdrawFilterApi(coin, data, options)
      .then((res) => {
        return res.data.data;
        // dispatch(withdrawList(res.data?.data, res.data.totalRecords));
        //dispatch(stopLoader());
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        return false;
        // dispatch(stopLoader());
      });
  };
}

export function withdrawListFn(data) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .withdrawListApi(data, options)
      .then((res) => {
        dispatch(withdrawList(res.data?.data, res.data.totalRecords));
        dispatch(stopLoader());
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function withdrawCsvListFn(data) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    //  dispatch(startLoader());
    return userService
      .withdrawListApi(data, options)
      .then((res) => {
        return res.data.data;
        //dispatch(withdrawList(res.data?.data, res.data.totalRecords));
        // dispatch(stopLoader());
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        return false;
        // dispatch(stopLoader());
      });
  };
}
export function applypermissionRequest(coin, permissiondata) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .permissionRequest(coin, permissiondata, options)
      .then((res) => {
        toast.success(res.data?.message);
        dispatch(permissionLIst(res.data?.data));
        dispatch(stopLoader());
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function getPendingWithdraw() {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    // dispatch(startLoader());
    return userService
      .getCountPendingWithdraws(options)
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        // dispatch(stopLoader());
      });
  };
}
