import dateFormat from "dateformat";
import React from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Icon, Pagination } from "semantic-ui-react";
import { activeCoinsFn } from "../../../Redux/Actions/dashboard/dashboard.action";
import {
  applypermissionRequest,
  getPendingWithdraw,
  withdrawCSVFilterDataFn,
  withdrawCsvListFn,
  withdrawFilterDataFn,
  withdrawListFn,
} from "../../../Redux/Actions/withdrawTransactions/withdrawTrans.action";

import "moment/locale/en-gb";
import "rc-datepicker/lib/style.css";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import "../../../App.css";
import { toast } from "../../../Components/Toast/toast.component";
import {
  DEPOSITE_STATUS,
  limit,
  MoneyFormat,
  TRANSACTIONTYPE_STATUS,
} from "../../../Constants/constant";
import {
  depositeFilterDataFn,
  getAllDepositesFn,
} from "../../../Redux/Actions/DepositeTrans/deposite.action";
import CurrencyAuthModal from "../currency/currencyAuth.modal";
import { convertTZ } from "./../../common/helper";
import "./withdrawStyle.css";

class WithdrawTransaction extends React.Component {
  constructor() {
    super();
    this.state = {
      disabled: true,
      stateCsvData: [],
      withdrawCSVList: [],
      totalDataCount: 0,
      enableFilterInput: true,
      page: 1,
      coin: "",
      showAuthModal: false,
      backdrop: "static",
      transactiontype: "",
      filterData: {
        address: "",
        email: "",
        coin: "eth",
        status: "",
        txId: "",
        fromDate: "",
        toDate: "",
        is_internal: "",
        limit: limit,
      },
      filterOtion: false,
      setsearchcoin: "",
      resetcoin: "ETH",
      resetdata: false,
      approvedrequest: {
        withdraw_id: "",
        coin: "eth",
        isApproved: "",
        password: "",
        amount: "",
        symbol: "",
        reason: "",
        requestToggle: "",
      },
      // add is loading csv
      isLoadingCSV: false,
      isDateRangeInvalid: false,
    };
    this.csvInstance = React.createRef();
  }

  componentDidMount = () => {
    // turn on password dialog
    this.state.showAuthModal = true;
    let { activeCoinsFn } = this.props;
    activeCoinsFn();
    this.setState({
      coin: "",
      enableFilterInput: false,
      filterOtion: false,
      pageload: true,
      page: 1,
    });
    this.searchValues();
    // this.props.getPendingWithdraw();
    // const listener = (event) => {
    //   if (event.code === "Enter" || event.code === "NumpadEnter") {
    //     event.preventDefault();
    //     this.searchValues();
    //     // callMyFunction();
    //   }
    // };
    // document.addEventListener("keydown", listener);
    // return () => {
    //   document.removeEventListener("keydown", listener);
    // };
  };
  getData = (value) => {
    // const history = useHistory();
    // let { currencyAuthFn } = this.props;
    if ("Tn$E4y6Ghj^gUmC#" === value) {
      this.props.history.push("/auth/withdrawal_transactions");
      this.setState({
        showAuthModal: false,
      });
      // window.location.replace('withdrawal_transactions');
    } else {
      // currencyAuthFn(false);
      toast.error("This is incorrect Password please try again.");
    }
  };

  closeModal = () => {
    this.setState({
      showAuthModal: false,
    });
    this.props.history.push("/auth/dashboard");
  };
  clearSearch = () => {
    let { withdrawListFn } = this.props;
    let withdrawData = {
      page: this.state.page,
    };
    withdrawListFn(withdrawData).then(() => {
      this.setState({
        resetcoin: "",
        coin: "",
        enableFilterInput: false,
        filterOtion: false,
        transactiontype: "",
      });
      let data = this.state.filterData;
      data["coin"] = "";
      data["email"] = "";
      data["address"] = "";
      data["txId"] = "";
      data["status"] = "";
      data["fromDate"] = "";
      data["toDate"] = "";
      data["is_internal"] = "";

      this.setState({
        filterData: data,
        isDateRangeInvalid: false,
      });
    });
  };
  handleSearch = () => {
    // set date range valid
    const { fromDate, toDate } = this.state.filterData;
    if ((fromDate && toDate) || (!fromDate && !toDate)) {
      this.setState(
        { ...this.state, page: 1, isDateRangeInvalid: false },
        () => {
          this.searchValues();
        }
      );
      return;
    }
    this.setState({ ...this.state, isDateRangeInvalid: true });
    // this.setState({ ...this.state, page: 1 }, () => {
    //   this.searchValues();
    // });
  };

  // on change page size
  handleChangePageSize = (pageSize) => {
    this.setState(
      { filterData: { ...this.state.filterData, limit: pageSize } },
      () => {
        // call back to load new user list with filter state
        this.searchValues();
      }
    );
  };

  pageChange = async (e, data) => {
    let page = data.activePage;

    //  await this.setState({ page: page });
    this.setState(
      {
        page: page,
      },
      () => this.searchValues()
    );
  };
  aluesbegin = () => {
    this.setState({
      page: 1,
    });
    setTimeout(() => {
      this.searchValues();
    });
  };

  searchValues = () => {
    this.state.withdrawCSVList = "";
    // this.setState({ page: 1 })
    let { withdrawFilterDataFn } = this.props;
    let { filterData } = this.state;

    let filterDatas = {
      address: filterData.address,
      email: filterData.email,
      status: filterData.status,
      txId: filterData.txid,
      page: this.state.page,
      fromDate: filterData.fromDate
        ? dateFormat(filterData.fromDate, "yyyy-mm-dd")
        : "",
      toDate: filterData.toDate
        ? dateFormat(filterData.toDate, "yyyy-mm-dd 23:59:59")
        : "",
      limit: filterData.limit,
      is_internal: filterData.is_internal,
    };

    if (!this.state.coin) {
      filterDatas.isCoin = 1;
      withdrawFilterDataFn("eth", filterDatas);
      // .then((res) => {
      //   filterDatas["page"] = 1;
      //   filterDatas["limit"] = this.props.totalRecords;
      //   // console.log(this.props.totalRecords, "filterDatas");
      //   withdrawCSVFilterDataFn("eth", filterDatas).then((response) => {
      //     this.setState({
      //       withdrawCSVList: response,
      //     });
      //     this.getCSVList();
      //   });
      // });
      this.setState({
        filterOtion: true,
        setsearchcoin: this.state.coin,
      });
    } else {
      filterDatas.isCoin = 0;
      withdrawFilterDataFn(this.state.coin, filterDatas);
      // .then((res) => {
      //   filterDatas["page"] = 1;
      //   filterDatas["limit"] = this.props.totalRecords;
      //   // console.log(filterDatas, "filterDatas");
      //   withdrawCSVFilterDataFn(this.state.coin, filterDatas).then(
      //     (response) => {
      //       this.setState({
      //         withdrawCSVList: response,
      //       });
      //       this.getCSVList();
      //       // console.log(this.state.withdrawCSVList, "withdraw");
      //     }
      //   );
      // });
      this.setState({
        filterOtion: true,
        setsearchcoin: this.state.coin,
      });
    }
  };

  // export as csv function
  getWithdrawCSVData = () => {
    this.setState({ CSVDataList: "", isLoadingCSV: true });
    let { filterData } = this.state;

    let filterParams = {
      address: filterData.address,
      email: filterData.email,
      status: filterData.status,
      txId: filterData.txid,
      page: 1,
      fromDate: filterData.fromDate
        ? dateFormat(filterData.fromDate, "yyyy-mm-dd")
        : "",
      toDate: filterData.toDate
        ? dateFormat(filterData.toDate, "yyyy-mm-dd 23:59:59")
        : "",
      is_internal: filterData.is_internal,
      limit: this.props.totalRecords,
    };
    this.props
      .withdrawCSVFilterDataFn(this.state.coin || "eth", {
        ...filterParams,
        isCoin: !this.state.coin ? 1 : 0,
      })
      .then((res) => {
        const formattedData = res.map((stateData, index) => ({
          "#": index + 1,
          DateTime: dateFormat(
            convertTZ(stateData?.created_at, "Asia/Vientiane"),
            "dd-mm-yyyy h:MM:ss TT"
          ),
          email: stateData.email || "--",
          "Tx Type": stateData?.is_internal,
          Coin: stateData.coin ? stateData.coin : this.state.coin.toUpperCase(),
          Amount: stateData.amount ? MoneyFormat(stateData.amount) : "--",
          Fee: `${MoneyFormat(stateData?.fee || 0)}`,
          "Address From": stateData.from_address || "--",
          "Address To": stateData.to_address || "--",
          "Tx ID": stateData.tx_id || "--",
          "Approved Date": dateFormat(
            convertTZ(stateData?.created_at, "Asia/Vientiane"),
            "dd-mm-yyyy h:MM:ss TT"
          ),
          "Approved by":
            stateData.admin_firstname != null ||
            stateData.admin_lastname != null
              ? stateData.admin_firstname + " " + stateData.admin_lastname
              : "--",
          Status:
            stateData.status == 1
              ? "UNCONFIRMED"
              : stateData.status == 2
              ? "CONFIRMED"
              : stateData.status == 3
              ? "DECLINED"
              : stateData.status == 4 ||
                stateData.status == 5 ||
                (stateData.status == 6 && "FAILED"),
        }));
        this.setState({ stateCsvData: formattedData, isLoadingCSV: false });
      })
      .catch((err) => {
        this.setState({ isLoadingCSV: false });
      });
  };
  // trigger csv download after fetched data
  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.stateCsvData.length &&
      this.csvInstance.current &&
      this.csvInstance.current.link
    ) {
      setTimeout(() => {
        this.csvInstance.current.link.click();
        this.setState({ stateCsvData: [], isLoadingCSV: false });
      });
    }
  }

  getFilterInputData = (e, type) => {
    let { filterData } = this.state;

    if (type == "coin") {
      //filterData.coin = e.target.value;
      if (e.target.value !== "") {
        this.setState({ enableFilterInput: false });
        this.setState({ coin: e.target.value });
        filterData.coin = e.target.value;
      } else {
        this.setState({ enableFilterInput: true });
      }
    } else if (type == "email") filterData.email = e.target.value;
    else if (type == "address") {
      filterData.address = e.target.value;
    } else if (type == "status") {
      filterData.status = e.target.value;
    } else if (type == "txid") {
      filterData.txid = e.target.value;
    } else if (type == "fromDate") {
      filterData.fromDate = e;
    } else if (type == "toDate") {
      filterData.toDate = e;
    } else if (type == "transactiontype") {
      this.setState({ transactiontype: e.target.value });
      filterData.is_internal = e.target.value;
    }

    this.setState({ filterData: filterData });
  };

  getCSVList = () => {
    let statementCsvData = [];
    if (
      this.state.withdrawCSVList !== undefined &&
      this.state.withdrawCSVList.length > 0
    ) {
      // console.log(this.state.withdrawCSVList, "this.state.withdrawCSVList");
      this.state.withdrawCSVList.map((stateData) => {
        let data = {
          "Tx ID": stateData.tx_id,
          Coin: stateData.coin ? stateData.coin : this.state.coin.toUpperCase(),
          "Tx Type": stateData?.is_internal,
          Fee: stateData?.fee / 10 ** 8,
          email: stateData.email,
          Amount: stateData.amount / 100000000,
          "Address From": stateData.from_address,
          "Address To": stateData.to_address,
          "Approved by":
            stateData.admin_firstname != null ||
            stateData.admin_lastname != null
              ? stateData.admin_firstname + " " + stateData.admin_lastname
              : "--",
          Date: dateFormat(stateData?.created_at, "dd-mm-yyyy"),
          Time: dateFormat(
            convertTZ(stateData?.created_at, "Asia/Vientiane"),
            "h:MM:ss TT"
          ),
          Status:
            stateData.status == 1
              ? "UNCONFIRMED"
              : stateData.status == 2
              ? "CONFIRMED"
              : stateData.status == 3
              ? "DECLINED"
              : stateData.status == 4 ||
                stateData.status == 5 ||
                (stateData.status == 6 && "FAILED"),
        };

        statementCsvData.push(data);
      });
      this.setState({
        stateCsvData: statementCsvData,
        // dataLength: nextProps.customerDataLength,
      });
    } else {
      this.setState({
        stateCsvData: "",
        // dataLength: nextProps.customerDataLength,
      });
    }
  };

  approveRequestHandle = (e, withdrwal_id, coin, amount, networkid) => {
    if (coin == "" || coin == undefined) {
      coin = this.state.coin;
    }
    let applypermission = {
      withdraw_id: withdrwal_id,
      isApproved: e,
      password: "2R:H8udp7{);-:+L",
      amount: amount,
      symbol: coin,
      reason: e,
      network_id: networkid,
    };
    // console.log(this.state.coin);
    this.props.permissionapplyfn(coin, applypermission).then(() => {
      this.searchValues();
      // this.props.withdrawListFn(this.state.page);
    });
    this.setState({
      approvedrequest: applypermission,
    });
  };
  render() {
    let { totalRecords, activeCoins, allDeposites, withdrawListData } =
      this.props;
    let { filterData } = this.state;

    function preciseTodecimal(num) {
      var exponentialNumber = num / 10 ** 8;

      const str = exponentialNumber.toString();
      if (str.indexOf("e") !== -1) {
        const exponent = parseInt(str.split("-")[1], 8);
        const result = exponentialNumber.toFixed(8);

        return result;
      } else {
        return exponentialNumber;
      }
    }
    //alert(totalRecords);

    let { enableFilterInput } = this.state;
    return (
      <div>
        <Container fixed="lg" className={"py-4"}>
          <Card>
            <Card.Body>
              <Row className="page-header pb-3 justify-content-space-between">
                <Col>
                  <h3 className="page-title">{"Withdraw Transaction"}</h3>
                </Col>
                <Col className={"d-flex justify-content-end"}>
                  {/* react csv */}
                  <>
                    <div onClick={this.getWithdrawCSVData}>
                      <Button
                        variant="white"
                        disabled={this.state.isLoadingCSV}
                      >
                        <Icon name="download" />
                        {this.state.isLoadingCSV
                          ? "Loading..."
                          : "Export as CSV"}
                      </Button>
                    </div>
                    {this.state.stateCsvData.length ? (
                      <CSVLink
                        data={this.state.stateCsvData}
                        filename={`withdraw${new Date().toISOString()}.csv`}
                        ref={this.csvInstance}
                      />
                    ) : undefined}
                  </>
                </Col>
              </Row>
              <Row>
                <Col lg={3} md={4} xs={12}>
                  <Form.Group>
                    <Form.Label>Tx ID</Form.Label>
                    <Form.Control
                      type="search"
                      name="txid"
                      id="txid"
                      autoComplete="off"
                      value={filterData.txid}
                      placeholder="Transaction Id"
                      onChange={(e) => this.getFilterInputData(e, "txid")}
                    />
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} xs={12}>
                  <Form.Group>
                    <Form.Label>Coin</Form.Label>
                    <Form.Control
                      as="select"
                      name="coin"
                      id="coin"
                      defaultValue={this.state.coin}
                      value={this.state.coin}
                      onChange={(e) => this.getFilterInputData(e, "coin")}
                    >
                      {this.state.coin == "" && (
                        <option className="d-none" value="">
                          Select Coin
                        </option>
                      )}
                      {activeCoins != undefined &&
                        activeCoins &&
                        activeCoins.map((coins) => {
                          return (
                            <option value={coins}>{coins.toUpperCase()}</option>
                          );
                        })}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} xs={12}>
                  <Form.Group>
                    <Form.Label>Tx Type</Form.Label>
                    <Form.Control
                      as="select"
                      name="transactiontype"
                      id="transactiontype"
                      defaultValue={this.state.transactiontype}
                      value={this.state.transactiontype}
                      onChange={(e) =>
                        this.getFilterInputData(e, "transactiontype")
                      }
                    >
                      {this.state.transactiontype == "" && (
                        <option className="d-none" value="">
                          All
                        </option>
                      )}

                      {TRANSACTIONTYPE_STATUS.map((status) => {
                        return (
                          <option value={status.value}>{status.name}</option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} xs={12}>
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="search"
                      name="email"
                      id="email"
                      value={filterData.email}
                      placeholder="Email"
                      onChange={(e) => this.getFilterInputData(e, "email")}
                      disabled={enableFilterInput}
                    />
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} xs={12}>
                  <Form.Group>
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="search"
                      name="address"
                      id="address"
                      value={filterData.address}
                      placeholder="Address"
                      onChange={(e) => this.getFilterInputData(e, "address")}
                      disabled={enableFilterInput}
                    />
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} xs={12}>
                  <Form.Group>
                    <Form.Label>From Date</Form.Label>

                    <DatePicker
                      dateFormat="dd-MM-yyyy"
                      selected={filterData.fromDate ? filterData.fromDate : ""}
                      placeholderText="DD/MM/YYYY"
                      className="form-control"
                      onChange={(e) => this.getFilterInputData(e, "fromDate")}
                    />
                    <Form.Text type="danger" muted>
                      {this.state.isDateRangeInvalid &&
                      !this.state.filterData.fromDate ? (
                        <span style={{ color: "red" }}>
                          Please select from date
                        </span>
                      ) : (
                        ""
                      )}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} xs={12}>
                  <Form.Group>
                    <Form.Label>To Date</Form.Label>
                    <DatePicker
                      disabled={!filterData?.fromDate}
                      minDate={new Date(filterData.fromDate)}
                      dateFormat="dd-MM-yyyy"
                      selected={filterData.toDate ? filterData.toDate : ""}
                      placeholderText="DD/MM/YYYY"
                      className="form-control"
                      onChange={(e) => this.getFilterInputData(e, "toDate")}
                    />
                    <Form.Text muted>
                      {this.state.isDateRangeInvalid &&
                      !this.state.filterData.toDate ? (
                        <span style={{ color: "red" }}>
                          Please select to date
                        </span>
                      ) : (
                        ""
                      )}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} xs={12}>
                  <Form.Group>
                    <Form.Label>Status</Form.Label>
                    <Form.Control
                      as="select"
                      defaultValue="Status"
                      name="status"
                      id="status"
                      value={this.state.filterData.status}
                      onChange={(e) => this.getFilterInputData(e, "status")}
                      disabled={enableFilterInput}
                    >
                      {this.state.filterData.status == "" && (
                        <option className="d-none" value="">
                          All
                        </option>
                      )}

                      {DEPOSITE_STATUS.map((status) => {
                        return (
                          <option value={status.value}>{status.name}</option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className={"d-flex justify-content-center mt-2"}>
                  <Button
                    variant="primary"
                    type="submit"
                    // disabled={this.state.disabled}
                    onClick={this.handleSearch}
                  >
                    Search
                  </Button>

                  <Button
                    variant="danger"
                    className="ml-2"
                    onClick={this.clearSearch}
                  >
                    Reset
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="mt-4">
            <Card.Body>
              <Table bordered hover responsive size="sm">
                <thead>
                  <tr
                    style={{
                      height: "3rem",
                      color: "#3d5170",
                    }}
                  >
                    <th className="align-middle">#</th>
                    <th className="align-middle">DateTime</th>
                    <th className="text-center align-middle">Action</th>
                    <th className="align-middle">Email ID</th>
                    <th className="align-middle">Tx Type</th>
                    <th className="align-middle">Coin</th>
                    <th className="align-middle">Amount</th>
                    <th className="align-middle">Fee</th>
                    <th className="align-middle">Address From</th>
                    <th className="align-middle">Address To</th>
                    <th className="align-middle">Tx ID</th>
                    <th className="align-middle">Approved Date</th>
                    <th className="align-middle">Approved By</th>

                    {/* <th className="align-middle">Date</th>
                    <th className="align-middle">Time</th> */}
                  </tr>
                </thead>
                <tbody>
                  {withdrawListData.length > 0 ? (
                    withdrawListData.map((depositData, key) => {
                      return (
                        <tr key={`withdraw-${key}`}>
                          <td className="text-center align-middle">
                            {(this.state.page - 1) *
                              this.state.filterData.limit +
                              (key + 1)}
                          </td>
                          <td className="align-middle">
                            {dateFormat(
                              convertTZ(
                                depositData?.created_at,
                                "Asia/Vientiane"
                              ),
                              "dd/mm/yyyy h:MM:ss TT"
                            )}
                          </td>
                          <td className="align-middle text-center">
                            {depositData.status == 1 ? (
                              <Badge variant="warning">UNCONFIRMED</Badge>
                            ) : depositData.status == 2 ? (
                              <Badge variant="success">CONFIRMED</Badge>
                            ) : depositData.status == 3 ? (
                              <Badge variant="danger">DECLINED</Badge>
                            ) : depositData.status == 4 ||
                              depositData.status == 5 ||
                              depositData.status == 6 ? (
                              <Badge variant="danger">FAILED</Badge>
                            ) : depositData.status == 0 ? (
                              <Form.Group className="d-flex align-middle m-0">
                                <button
                                  className="btn btn-success mr-1"
                                  onClick={() =>
                                    this.approveRequestHandle(
                                      "approved",
                                      depositData.id,
                                      depositData.coin,
                                      depositData.amount,
                                      depositData.network_id
                                    )
                                  }
                                >
                                  Approve
                                </button>
                                <button
                                  className="btn btn-danger"
                                  onClick={() =>
                                    this.approveRequestHandle(
                                      "declined",
                                      depositData.id,
                                      depositData.coin,
                                      depositData.amount,
                                      depositData.network_id
                                    )
                                  }
                                >
                                  Reject
                                </button>
                              </Form.Group>
                            ) : (
                              ""
                            )}
                          </td>
                          <td className="align-middle">
                            <Link
                              to={`/auth/trader-view/${depositData.userId}`}
                            >
                              {depositData.email}
                            </Link>
                          </td>
                          <td className="align-middle">
                            {depositData?.is_internal}
                          </td>
                          {depositData.coin ? (
                            <td className="align-middle">{depositData.coin}</td>
                          ) : (
                            <td className="align-middle">
                              {this.state.resetdata == true
                                ? this.state.resetcoin
                                : this.state.filterOtion == true
                                ? this.state.setsearchcoin.toUpperCase()
                                : this.state.filterOtion == false
                                ? this.state.resetcoin.toUpperCase()
                                : this.state.coin.toUpperCase()}
                            </td>
                          )}
                          <td className="align-middle">
                            {depositData.amount
                              ? MoneyFormat(depositData.amount)
                              : "--"}
                          </td>
                          <td className="align-middle">
                            {depositData?.fee
                              ? MoneyFormat(depositData?.fee)
                              : "--"}
                          </td>
                          <td className="align-middle">
                            {depositData.from_address}
                          </td>
                          <td className="align-middle">
                            {depositData.to_address}
                          </td>
                          <td className="align-middle">{depositData.tx_id}</td>
                          <td className="align-middle">
                            {depositData?.approvedAt
                              ? dateFormat(
                                  convertTZ(
                                    depositData?.approvedAt,
                                    "Asia/Vientiane"
                                  ),
                                  "dd/mm/yyyy h:MM:ss TT"
                                )
                              : ""}
                          </td>
                          <td className="align-middle">
                            {`${depositData.admin_firstname || "--"} ${
                              depositData.admin_lastname || "--"
                            }`}
                          </td>

                          {/* <td>
                            {depositData.admin_firstname != null ||
                            depositData.admin_lastname != null
                              ? depositData.admin_firstname +
                                " " +
                                depositData.admin_lastname
                              : "--"}
                          </td> */}

                          {/* <td>
                            {dateFormat(
                              convertTZ(
                                depositData?.created_at,
                                "Asia/Vientiane"
                              ),
                              "h:MM:ss TT"
                            )}
                          </td> */}
                        </tr>
                      );
                    })
                  ) : (
                    <tr style={{ height: "8rem" }}>
                      <td colSpan="11" className="text-center align-middle">
                        No Record Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <Row className="justify-content-space-between mt-2">
                <Col>
                  <Dropdown>
                    <Dropdown.Toggle
                      size="sm"
                      variant="outline-secondary"
                      id="pagesize-dropdown"
                    >
                      <Icon name="caret up" />
                      {`Show ${this.state.filterData.limit} items/page`}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => this.handleChangePageSize(50)}
                      >
                        Show 50 items
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => this.handleChangePageSize(100)}
                      >
                        Show 100 items
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => this.handleChangePageSize(150)}
                      >
                        Show 150 items
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => this.handleChangePageSize(250)}
                      >
                        Show 250 items
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => this.handleChangePageSize(500)}
                      >
                        Show 500 items
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                <Col className={"d-flex justify-content-end"}>
                  <Pagination
                    size="mini"
                    activePage={this.state.page}
                    onPageChange={this.pageChange}
                    ellipsisItem={{
                      content: <Icon name="ellipsis horizontal" />,
                      icon: true,
                    }}
                    firstItem={{
                      content: <Icon name="angle double left" />,
                      icon: true,
                    }}
                    lastItem={{
                      content: <Icon name="angle double right" />,
                      icon: true,
                    }}
                    prevItem={{
                      content: <Icon name="angle left" />,
                      icon: true,
                    }}
                    nextItem={{
                      content: <Icon name="angle right" />,
                      icon: true,
                    }}
                    totalPages={Math.ceil(
                      totalRecords / this.state.filterData.limit
                    )}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
        <CurrencyAuthModal
          show={this.state.showAuthModal}
          backdrop={this.state.backdrop}
          handleClose={this.closeModal}
          getPassword={this.getData}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    totalRecords: state.withdraw.totalRecords,
    activeCoins: state.dashboard.activeCoins,
    allDeposites: state.deposite.allDeposites,
    withdrawListData: state.withdraw.withdrawListData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    activeCoinsFn: (data) => dispatch(activeCoinsFn(data)),
    getAllDepositesFn: (data) => dispatch(getAllDepositesFn(data)),
    depositeFilterDataFn: (coin, data) =>
      dispatch(depositeFilterDataFn(coin, data)),
    withdrawListFn: (data) => dispatch(withdrawListFn(data)),
    withdrawFilterDataFn: (coin, data) =>
      dispatch(withdrawFilterDataFn(coin, data)),
    permissionapplyfn: (coin, data) =>
      dispatch(applypermissionRequest(coin, data)),
    withdrawCsvListFn: (data) => dispatch(withdrawCsvListFn(data)),
    withdrawCSVFilterDataFn: (coin, data) =>
      dispatch(withdrawCSVFilterDataFn(coin, data)),
    getPendingWithdraw: () => dispatch(getPendingWithdraw()),
    //withdrawCsvListFn:(data)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithdrawTransaction);
