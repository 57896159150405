import React from "react";
import { Container, Row, Col } from "shards-react";
import { Table, Form } from "react-bootstrap";
import { Pagination, Icon } from "semantic-ui-react";
import PageTitle from "../../common/PageTitle";

import { getWalletBinancedataFn } from "../../../Redux/Actions/dashboard/dashboard.action";
import "react-datepicker/dist/react-datepicker.css";
import "rc-datepicker/lib/style.css";

import { connect } from "react-redux";
import "moment/locale/en-gb";
import "../user/Users.css";
import "../../../App.css";
import ImportCsv from "../../common/common";

class Users extends React.Component {
  constructor() {
    super();
    this.state = {
      articles: [],
      articles1: [],
      begin: 0,
      end: 10,
      activePage: 1,
      searchItem: "",
    };
    this.btnClick = this.btnClick.bind(this);
  }

  async btnClick(event, data) {
    await this.setState({ activePage: data.activePage });
    await this.setState({ begin: this.state.activePage * 10 - 10 });
    await this.setState({ end: this.state.activePage * 10 });
    this.setState({
      articleDatas: this.state.articles.slice(this.state.begin, this.state.end),
    });
  }

  componentDidMount = () => {
    let { getWalletBinancedataFn } = this.props;

    getWalletBinancedataFn();
  };
  componentWillReceiveProps = (nextProps) => {
    let { getwalletbinance } = this.props;
    // console.log("getwalletbinance",getwalletbinance)
    const resulte = getwalletbinance.filter((ele, index) => ele.free > 0);
    this.setState({ articles: resulte });
    this.setState({ articles1: resulte });
  };

  getFilterInputData = (e) => {
    this.setState({ searchItem: e.target.value });
    this.setState({ activePage: 1 });
    this.setState({ begin: this.state.activePage * 10 - 10 });
    this.setState({ end: this.state.activePage * 10 });

    const result = this.state.articles1.filter((data, index) => {
      return data?.asset.toLowerCase()?.includes(e.target.value?.toLowerCase());
    });
    this.setState({ articles: result });
  };

  render() {
    let { getwalletbinance } = this.props;

    // console.log("articles", this.state.articles);

    return (
      <div>
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <Col lg={12}>
              <PageTitle
                title="Binance"
                subtitle=""
                className="text-sm-left mb-3"
              />
            </Col>
          </Row>

          <Row>
            <Col style={{ textAlign: "right" }}>
              <Form.Group>
                <Form.Control
                  type="search"
                  name="search"
                  id="search"
                  value={this.state.searchItem}
                  placeholder="Search Coin"
                  onChange={(e) => this.getFilterInputData(e)}
                  // disabled={disabled}
                />
              </Form.Group>
            </Col>

            <Col style={{ textAlign: "right" }}>
              <ImportCsv
                stateCsvData={this.state.articles}
                file_name={"Submitted Kyc"}
              />
            </Col>
          </Row>

          <Row className="justify-content-end mt-4 p-3 bg-white">
            {getwalletbinance != undefined && getwalletbinance.length > 0 ? (
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th className="pl-3 text-nowrap">Coin</th>
                    <th className="pl-3 text-nowrap">Balance</th>
                    <th className="pl-3 text-nowrap">Locked</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.articles1
                    ?.filter((el) => {
                      if (this.state?.searchItem == "") {
                        return el;
                      } else {
                        return el?.asset
                          ?.toLowerCase()
                          ?.includes(this.state?.searchItem?.toLowerCase());
                      }
                    })
                    ?.slice(this.state?.begin, this.state?.end)
                    ?.map((getETHuser, i) => {
                      // let getcoin = getETHuser?.id?.split("_")
                      return (
                        <tr>
                          <td>{getETHuser?.asset ?? ""}</td>

                          <td className="pl-3 text-nowrap">
                            {getETHuser?.free ?? ""}
                          </td>

                          <td className="pl-3 text-nowrap">
                            {getETHuser?.locked ?? 0}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            ) : (
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th className="pl-3 text-nowrap">Coin</th>
                    <th className="pl-3 text-nowrap">Balance</th>
                    <th className="pl-3 text-nowrap">Locked</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="4" className="text-center">
                      No Record Found
                    </td>
                  </tr>
                </tbody>
              </Table>
            )}
            {getwalletbinance.length > 1 ? (
              <Pagination
                defaultActivePage={1}
                activePage={this.state.activePage}
                onPageChange={this.btnClick}
                ellipsisItem={{
                  content: <Icon name="ellipsis horizontal" />,
                  icon: true,
                }}
                firstItem={{
                  content: <Icon name="angle double left" />,
                  icon: true,
                }}
                lastItem={{
                  content: <Icon name="angle double right" />,
                  icon: true,
                }}
                prevItem={{ content: <Icon name="angle left" />, icon: true }}
                nextItem={{ content: <Icon name="angle right" />, icon: true }}
                totalPages={Math.ceil(this.state.articles1?.length / 10)}
              />
            ) : (
              ""
            )}
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getwalletbinance: state.dashboard.getwalletbinance,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getWalletBinancedataFn: (data) => dispatch(getWalletBinancedataFn(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
