import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col } from "shards-react";
import { Table, Form, Button, Dropdown, Card } from "react-bootstrap";
import PageTitle from "../../common/PageTitle";

import { connect } from "react-redux";
import "moment/locale/en-gb";
import "rc-datepicker/lib/style.css";
import "../../../App.css";

import { useForm } from "react-hook-form";
import _ from "lodash/fp";

import { getSingleSubadminFn, updateSubadminFn } from "../../../Redux/Actions/subadmin/subadmin.action";
import { PERMISSONS } from '../../../Constants/constant'

import { get2fastatusFn,getAdminProfile } from '../../../Redux/Actions/profile/profile.actions';

function EditSubadmin(props) {
  const [permission, setPermission] = useState([])
  const [allotedPermission, setallotedPermission] = useState([])
  const [email, setEmail] = useState("")


  const { register, handleSubmit, watch, errors, setValue } = useForm({
    reValidateMode: "onChange",
    defaultValues: {

    },
  });


  useEffect(() => {
    let id = props.match.params.id
    let data = {
      id: id
    }
    props.getSingleSubadminFn(data)
   // props.getAdminProfile()
  }, [])



  useEffect(() => {
    setValue("email", props.singleSubadminData?.email);
    setValue("firstname", props.singleSubadminData?.firstname);
    setValue("mobile", props.singleSubadminData?.mobile);
    setValue("lastname", props.singleSubadminData?.lastname)
    setEmail(props.singleSubadminData?.email)
    let allotedPermissions = props.singleSubadminData?.role
    let permissionArray = allotedPermissions?.split(',').filter(item => item !== "manage_subadmin")

    permissionArray !== undefined && permissionArray.map(dta => {
      setPermission(oldData => [...oldData, dta])
    })
    setallotedPermission(permissionArray)

  }, [props.singleSubadminData])


  const password = useRef({});
  password.current = watch("password", "");



  const onSubmit = (data) => {
    
    let { singleSubadminData } = props
    delete data.confirmPassword
    data.role = permission
    //data.email = singleSubadminData?.email
   // data.accessRole = singleSubadminData?.access_role
    data.id = singleSubadminData?.admin_users_id
    data.status = singleSubadminData?.status
   
    props.updateSubadminFn(data)
  };

  let permissionData = []


  const getPermission = (e) => {

    permissionData.push(e.target.checked ? e.target.name : "")
    permission.map((perDta, i) => {
      if (perDta === e.target.name && !e.target.checked) {
       
        permission.splice(i, 1)
        
      }
    })
    if (e.target.checked) {
      setPermission(oldData => [...oldData, e.target.name])
    }
  
  }


  const getData = () => {
    
  }



  return (
    <div>
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <Col lg={12}>
            <PageTitle
              title="Sub-Admin-Management"
              subtitle=""
              className="text-sm-left mb-3"
            />
          </Col>
        </Row>

        <Card>
          <Card.Header></Card.Header>
          <Card.Body>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>First Name</Form.Label>
                    <input

                      id="firstname"
                      type="firstname"
                      name="firstname"
                      placeholder="First Name"
                      ref={register({
                        //required: true,
                        //maxLength: 20,
                      })}
                      className="form-control"
                    />

                   {/*  {_.get("email.type", errors) === "required" && (
                      <p style={{ color: "red" }}>This field is required</p>
                    )} */}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formBasicPassword">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="lastname"
                      name="lastname"
                      placeholder="Last Name"
                      ref={register({
                        required: true,//validation_messages.password_required,
                        /* pattern: {
                            value: /(?=^.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/,
                            message: 'Password must contain atleast 1 small-case letter, 1 Capital letter, 1 digit, 1 special character and the length should be minium 8 characters.'
                        } */
                      })}
                    />

                    {_.get("lastname.type", errors) === "required" && (
                      <p style={{ color: "red" }}>This field is required</p>
                    )}



                    {/*  {errors.password && <p>{errors.password.message}</p>} */}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formBasicPassword">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="mobile"
                      placeholder="Phone Number"
                      name="mobile"
                      ref={register({
                        required: true,

                      
                      })}
                    />

                    {_.get("mobile.type", errors) === "required" && (
                      <p style={{ color: "red" }}>This field is required</p>
                    )}

                    {errors.mobile && <p style={{ color: "red" }}>{errors.mobile.message}</p>}
                  </Form.Group>


                </Col>
                <Col>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email Address</Form.Label>
                    <input

                      id="email"
                      type="email"
                      name="email"
                      disabled={true}
                      ref={register({
                        //required: true,
                        //maxLength: 20,
                      })}
                      className="form-control"
                    />

                   {/*  {_.get("email.type", errors) === "required" && (
                      <p style={{ color: "red" }}>This field is required</p>
                    )} */}
                  </Form.Group>
                </Col>
             
              </Row>
              <Row>
                 </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formBasicPermission">
                    <Form.Label>Permission</Form.Label>

                    <div key={`default-checkbox`} className="mb-3">
                      {
                        PERMISSONS.map((permissionDta, index) => {

                          return (
                            permissionDta.name !== "manage_subadmin"  && <Form.Check
                              type="checkbox"
                              id={`default-checkbox${index}`}
                              label={permissionDta.label}
                              name={permissionDta.name}
                              onClick={getPermission}
                              defaultChecked={allotedPermission != undefined && allotedPermission.find((x) => {
                                return x === permissionDta.name ? true : false
                              })}
                            //ref={register}
                            />
                          )
                        })
                      }

                    </div>

                  </Form.Group>
                </Col>
                <Col>

                </Col>
                <Col></Col>
              </Row>

              <Button variant="primary" type="submit">
                {" "}
                Update{" "}
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    //pairListData: state.orders.pairListData
    //adminData: state.profile.adminData,
    singleSubadminData: state.subadmin.singleSubadminData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAdminProfile:()=>dispatch(getAdminProfile()),
    // getPairListFn: (data) => dispatch(getPairListFn(data))
    getSingleSubadminFn: (data) => dispatch(getSingleSubadminFn(data)),
    updateSubadminFn: (data) => dispatch(updateSubadminFn(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSubadmin);
