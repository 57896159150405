import * as userService from "../../../Services/API/currency.service";
import { toast } from "../../../Components/Toast/toast.component";
import * as types from "../../../Constants/_Types/types.reduxStore";
import { startLoader, stopLoader } from "../Loader/loader.action";
import { BASEURL, SUCCESS_MESSAGE } from "../../../Constants/constant";
//import { reset } from 'redux-form';

const currencyData = (currencyData) => {
  return {
    type: types.CURRENCY_DATA,
    payload: {
      currencyData: currencyData,
    },
  };
};

const currencyPairData = (currencyPairData) => {
  return {
    type: types.CURRENCY_PAIR_DATA,
    payload: {
      currencyPairData: currencyPairData,
    },
  };
};

const swapPairData = (swapPairData) => {
  return {
    type: types.SWAP_PAIR_DATA,
    payload: {
      swapPairData: swapPairData,
    },
  };
};

const editSwapPairData = (editSwapPairData) => {
  return {
    type: types.EDIT_SWAP_PAIR_DATA,
    payload: {
      editSwapPairData: editSwapPairData,
    },
  };
};

const currencyAuth = (currencyAuth) => {
  return {
    type: types.CURRENCY_AUTH,
    payload: {
      currencyAuth: currencyAuth,
    },
  };
};

const currencyPairAuth = (currencyPairAuth) => {
  return {
    type: types.CURRENCY_PAIR_AUTH,
    payload: {
      currencyPairAuth: currencyPairAuth,
    },
  };
};

export function currencyAuthFn(isAuth) {
  return (dispatch, getState) => {
    dispatch(currencyAuth(isAuth));
  };
}

export function currencyPairAuthFn(isAuth) {
  return (dispatch, getState) => {
    dispatch(currencyPairAuth(isAuth));
  };
}

export function currencyPairFn(coin) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .currencyPairApi(coin, options)
      .then((res) => {
        // console.log("pairlist", res.data?.data?.PairList);
        let sortedData = res.data?.data?.PairList.sort((a, b) => {
          if (a.pair < b.pair) return -1;
          if (a.pair > b.pair) return 1;
          return 0;
        });
        dispatch(currencyPairData(sortedData));
        dispatch(stopLoader());
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function swapPairFn(coin) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .swapPairApi(coin, options)
      .then((res) => {
        let sortedData = res.data?.data.sort((a, b) => {
          if (a.pair < b.pair) return -1;
          if (a.pair > b.pair) return 1;
          return 0;
        });
        dispatch(swapPairData(sortedData));
        dispatch(stopLoader());
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function EnableSwapPairs(data) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .enableSwapPairs(data, options)
      .then((res) => {
        // dispatch(swapPairData(res.data?.data));
        dispatch(stopLoader());
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function currencyFn(coin) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .currencyApi(coin, options)
      .then((res) => {
        dispatch(currencyData(res.data?.data));
        dispatch(stopLoader());
        return res.data?.data;
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}
export function editFeeLimit(data, history) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .EditFeeApiCurrency(data, options)
      .then((res) => {
        toast.success(res?.data?.message);

        history.push("/auth/currency-manage");

        dispatch(stopLoader());
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}
export function editFee(data, history) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .EditFeeApi(data, options)
      .then((res) => {
        toast.success(res?.data?.message);
        setTimeout(() => {
          dispatch(stopLoader());
        }, 1000);
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function editSwapPairFee(data, history) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .editSwapPairApi(data, options)
      .then((res) => {
        toast.success(res?.data?.message);

        // window.location.reload(false);

        setTimeout(() => {
          dispatch(stopLoader());
        }, 500);
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function editSwapPairSellFee(data) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .editSwapPairSellApi(data, options)
      .then((res) => {
        toast.success(res?.data?.message);

        // window.location.reload(false);

        setTimeout(() => {
          dispatch(stopLoader());
        }, 500);
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function getSwapPairData(data, history) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .getSwapEditFeeData(data, options)
      .then((res) => {
        dispatch(editSwapPairData(res?.data?.data));
        toast.success(res?.data?.message);
        // history.push("/auth/currency-pairs");

        dispatch(stopLoader());
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}
