import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col } from "shards-react";
import { Button, Form, Image, Modal } from "react-bootstrap";
import { Input } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import _ from "lodash/fp";

function ForgotPasswordModal({ show, setShow, setPassword, data }) {
  const [input, setInput] = useState("");
  const { register, handleSubmit, watch, errors, setValue } = useForm({
    reValidateMode: "onChange",
    defaultValues: {},
  });

  const onSubmit = (data) => {
    // console.log(data, "data");
    setPassword(data);
    // let { singleSubadminData } = props
    // delete data.confirmPassword
    // data.role = permission
    // // data.accessRole = 1
    // props.addSubadminFn(data, props.history)
  };

  const password = useRef({});
  password.current = watch("password", "");

  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="d-flex justify-content-end pt-1 pr-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          onClick={() => setShow()}
          style={{ cursor: "pointer" }}
          fill="currentColor"
          class="bi bi-x-circle"
          viewBox="0 0 16 16"
        >
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
        </svg>
      </div>
      <Modal.Body className="d-flex justify-content-center">
        <>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Enter Password</Form.Label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    //onChange={(e) => setInput(e.target.value)}
                    placeholder="Enter Password"
                    ref={register({
                      required: true,
                      /* maxLength: 20, */
                    })}
                    className="form-control"
                  />

                  {_.get("password.type", errors) === "required" && (
                    <p style={{ color: "red" }}>This field is required</p>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Confirm Password</Form.Label>
                  <input
                    type="password"
                    name="confirmPassword"
                    id="confirmPassword"
                    // onChange={(e) => setInput(e.target.value)}
                    placeholder="Confirm Password"
                    ref={register({
                      required: true,
                      validate: (value) =>
                        value === password.current ||
                        "The passwords do not match",

                      //pattern: /^[A-Za-z]+$/i

                      /* maxLength: 20, */
                    })}
                    className="form-control"
                  />

                  {_.get("confirmpassword.type", errors) === "required" && (
                    <p style={{ color: "red" }}>This field is required</p>
                  )}
                  {errors.confirmPassword && (
                    <p style={{ color: "red" }}>
                      {errors.confirmPassword.message}
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <div className="col-sm-12 mt-2">
              <Button
                type="submit"
                // onClick={() => {
                //   input && verify({ orderId: data?.orderId, refNumber: input });
                //   setShow();
                // }}
              >
                Submit
              </Button>
            </div>
          </Form>
        </>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={() => setShow()}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
}

export default ForgotPasswordModal;
