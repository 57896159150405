import { fetch } from "../axios.service";
import { USERS_BASE_URL, TRADING_BASE_URL, WALLET_BASE_URL, TRADING_NEW_BASE_URL, WALLET_ETH_BASE_URL } from "../../Constants/constant";

export const getDashboardCountsApi = (options) => {
  return fetch("get", `${USERS_BASE_URL}/admin/dashboard/counts`, {}, options);
};

export const getUsersApi = (data, options) => {
  return fetch("post", `${USERS_BASE_URL}/admin/user/list`, data, options);
};

export const getActiveUsersApi = (options) => {
  return fetch("get", `${TRADING_NEW_BASE_URL}/order/admin/getActiveUsers`, {}, options);
};

export const tradeVolumeApi = (options) => {
  return fetch("get", `${TRADING_NEW_BASE_URL}/order/admin/trades_volume`, {}, options);
};

export const tradeByMonthApi = (options) => {
  return fetch("get", `${TRADING_BASE_URL}/trade/tradesByMonth`, {}, options);
};

export const activeCoinsApi = (options) => {
  return fetch("get", `${WALLET_BASE_URL}/active-coins`, {}, options);
};

export const getdashboardbininshApi = (options) => {
  return fetch("get", `${WALLET_ETH_BASE_URL}/admin/getAdminDetails`, {}, options);
};

export const getwalletbinanceApi = (options) => {
  return fetch("get", `${TRADING_NEW_BASE_URL}/order/admin/getBinanceBalance`, {}, options);
};

/* export const dashboardApi = (data, options) => {
  return fetch("get", `${BANKING_BASE_URL}/admin/dashboardDataBanking`, data, options);
};
 */