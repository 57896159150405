import React, { Component } from "react";
import { Container, Row, Col } from "shards-react";
import PageTitle from "../../../Components/common/PageTitle";
import { connect } from "react-redux"; 
import {
  Table,
  Form,
  Button,
  ListGroup,
  Card,
  Tabs,
  Tab,
  Label,
  Accordion,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Pagination, Icon } from "semantic-ui-react";
import UpdateAccDetailModal from "./updateAccDetailModal";

import { offset, limit } from "../../../Constants/constant";
import EditUserForm from './editUserForm'
import "../../../index.css";
import queryString from "query-string";

class EditUser extends Component {
  constructor() {
    super();
    this.state = {
      showAccModal: false,
    };
  }

  componentDidMount = () => {
    //let custIds = this.props.match.params.custId;
  };

  updateUserInfo = (values) => {
    alert(JSON.stringify(values));
  };

  pageChange = (e, data) => {
    let page = data.activePage;
    let pageNo = page === 1 ? 0 : (page - 1) * limit;
  };

  getBankAccInfo = () => {
    this.setState({ showAccModal: true });
  };

  confirmAction = (accountId, type, currency) => {
    if (window.confirm("Are you sure want to close this acount?")) {
      alert("Done");
    }

    this.setState({ showAccModal: false });
  };

  editUserInfo = (values) => {
   alert(JSON.stringify(values))
  };

  render() {
    return (
      <Container>

<Card>
          <Card.Header as="h2">USER INFORMATION</Card.Header>
          <Card.Body>
            <Card.Text>
              
                <EditUserForm
                  handleEditUserForm={this.editUserInfo}
                 
                />
              
            </Card.Text>
          </Card.Body>
        </Card>
        <br />

        <Accordion defaultActiveKey="0">
          <Card>
            <Card.Header as="h2">
              <Accordion.Toggle as={Button} variant="link" eventKey="0">
                <a name="cutomer" id="customer">
                  <h3>CUSTOMER DETAILS</h3>
                </a>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Card.Text>
                  <Row>
                    <Col xs={2}>
                      <label class="font-weight-bold">Customer ID</label>
                      <p></p>
                    </Col>
                    <Col xs={2}>
                      <label class="font-weight-bold">Type</label>
                      <p></p>
                    </Col>
                    <Col xs={2}>
                      <label class="font-weight-bold">First Name</label>
                      <p></p>
                    </Col>
                    <Col xs={2}>
                      <label class="font-weight-bold">Last Name</label>
                      <p></p>
                    </Col>
                    <Col xs={2}>
                      <label class="font-weight-bold">Email Address</label>
                      <p></p>
                    </Col>
                    <Col xs={2}>
                      <label class="font-weight-bold">Date Of Birth</label>
                      <p></p>
                    </Col>
                  </Row>
                  <br />
                  <br />
                  <br />

                  <Row>
                    <Col xs={3}>
                      <label class="font-weight-bold">Address</label>
                      <p></p>
                    </Col>

                    <Col xs={3}>
                      <label class="font-weight-bold">
                        Expected Monthly Spend
                      </label>
                      <p></p>
                    </Col>
                    <Col xs={2}>
                      <label class="font-weight-bold">Customer Status</label>
                      <p></p>
                    </Col>
                    <Col />
                    <Col />
                    <Col />
                  </Row>
                  <br />
                </Card.Text>

                <div id="account"></div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <br />
        <Accordion defaultActiveKey="1">
          <Card>
            <Card.Header as="h2">
              <Accordion.Toggle as={Button} variant="link" eventKey="1">
                <h3 id="account">ACCOUNT DETAILS</h3>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <Card.Text>
                  <div>
                    <Row>
                      <Col>
                        <label class="font-weight-bold">Account ID</label>
                        <p></p>
                      </Col>

                      <Col>
                        <label class="font-weight-bold">Customer ID</label>
                        <p></p>
                      </Col>

                      <Col>
                        <label class="font-weight-bold">Currency</label>
                        <p></p>
                      </Col>
                      <Col>
                        <label class="font-weight-bold">Country</label>
                        <p></p>
                      </Col>
                      <Col>
                        <label class="font-weight-bold">Account Status</label>
                        <p></p>
                      </Col>

                      <Col xs={2}>
                        <Button variant="warning" onClick={this.getBankAccInfo}>
                          More Info
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Card.Text>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        <br />

        <UpdateAccDetailModal
          show={this.state.showAccModal}
          confirmAction={this.confirmAction}
          closeModal={() => this.setState({ showAccModal: false })}
        />

        <Accordion defaultActiveKey="2">
          <Card>
            <Card.Header as="h2">
              <Accordion.Toggle as={Button} variant="link" eventKey="2">
                <h3>CARD DETAILS</h3>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                <Card.Text>
                  <Row>
                    <Col xs={2}>
                      <label class="font-weight-bold">Card Status</label>
                      <p></p>
                    </Col>
                    <Col xs={2}>
                      <label class="font-weight-bold">Card ID</label>
                      <p></p>
                    </Col>
                    <Col xs={2}>
                      <label class="font-weight-bold">Expiry</label>
                      <p></p>
                    </Col>
                    <Col xs={2}>
                      <label class="font-weight-bold">Card Number</label>
                      <p></p>
                    </Col>
                    <Col xs={2}>
                      <label class="font-weight-bold">Account Number</label>
                      <p></p>
                    </Col>
                    <Col xs={2}>
                      <label class="font-weight-bold">First Name</label>
                      <p></p>
                    </Col>
                  </Row>
                  <br />
                  <br />
                  <br />
                  <Row>
                    <Col>
                      <label class="font-weight-bold">Last Name</label>
                      <p></p>
                    </Col>
                    <Col xs={3}>
                      <label class="font-weight-bold">Address</label>

                      <p></p>
                    </Col>
                    <Col>
                      <label class="font-weight-bold">Spend</label>
                      <p></p>
                    </Col>
                    <Col>
                      <label class="font-weight-bold">Limit</label>
                      <p></p>
                    </Col>
                    <Col>
                      <label class="font-weight-bold">Max Limit</label>
                      <p></p>
                    </Col>
                    <div id="transactions"></div>
                    <Col>
                      <Button variant="danger" style={{ margin: "3px" }}>
                        Cancel Card
                      </Button>
                    </Col>
                    <Col />
                  </Row>

                  <br />
                </Card.Text>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        <br />

        <Card>
          <Card.Header as="h2">
            <h3>TRANSACTIONS</h3>
          </Card.Header>

          <Card.Body>
            <Card.Text>
              <Row className="justify-content-end mt-4 p-3 bg-white">
                <Col sm={12} className="text-center text-dark bg-white">
                  <Table striped bordered hover responsive>
                    <thead>
                      <tr>
                        {/* <th className="pl-3">Customer ID</th> */}
                        <th className="pl-3">TXID</th>

                        <th className="pl-3">Amount</th>
                        <th className="pl-3">Currency</th>
                        {/* <th className="pl-3">Reason</th> */}
                        {/*  <th className="pl-3">Device Token</th> */}
                        <th className="pl-3">Fee</th>
                        <th className="pl-3">Reference</th>
                        <th className="pl-3">Created At</th>
                        <th className="pl-3">Status</th>
                        <th className="pl-3">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        {/* <td className="pl-3">{transData.customer_id}</td> */}
                        <td className="pl-3"></td>

                        <td className="pl-3"></td>
                        <td className="pl-3"></td>

                        <td className="pl-3">
                          <br />
                          <span
                            style={{ fontSize: "10px", color: "grey" }}
                          ></span>
                        </td>

                        <td className="pl-3"></td>

                        <td className="pl-3"></td>
                        <td className="pl-3"></td>

                        <td className="pl-3">
                          <Button disabled variant="danger">
                            {" "}
                            Cancelled{" "}
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <br />

                <Pagination
                  defaultActivePage={1}
                  onPageChange={this.pageChange}
                  ellipsisItem={{
                    content: <Icon name="ellipsis horizontal" />,
                    icon: true,
                  }}
                  firstItem={{
                    content: <Icon name="angle double left" />,
                    icon: true,
                  }}
                  lastItem={{
                    content: <Icon name="angle double right" />,
                    icon: true,
                  }}
                  prevItem={{
                    content: <Icon name="angle left" />,
                    icon: true,
                  }}
                  nextItem={{
                    content: <Icon name="angle right" />,
                    icon: true,
                  }}
                  totalPages={20}
                  // style={{marginTop:"50px"}}
                />
              </Row>

              <br />
            </Card.Text>
          </Card.Body>
        </Card>
        <br />
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //singleCustomerData: state.Banking.singleCustomerData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //getCustomerByIdFn: (data, history) => dispatch(getCustomerByIdFn(data, history)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
