import React from "react";
import { Button, Card, Col, Container, Row, Table } from "react-bootstrap";

import {
  currencyPairAuthFn,
  currencyPairFn,
  EnableSwapPairs,
  swapPairFn,
} from "../../../Redux/Actions/currency/currency.action";

import "moment/locale/en-gb";
import "rc-datepicker/lib/style.css";
import { connect } from "react-redux";
import "../../../App.css";
import { MoneyFormatWithoutSmallUnit } from "../../../Constants/constant";
import { toast } from "../../Toast/toast.component";
import CurrencyPairAuthModal from "../currency/currencyPairAuth.modal";
class SwapPairs extends React.Component {
  constructor() {
    super();
    this.state = {
      currencyData: [],
      showLimit: false,
      showAuthModal: false,
      swappairid: "",
      pairCoin: "",
    };
  }

  componentDidMount = () => {
    let { swapPairFn } = this.props;
    swapPairFn();
  };

  componentWillReceiveProps = (nextProps) => {
    /* if(nextProps.activeCoins !== this.props.activeCoins){
        
    } */
  };

  getData = (value) => {
    let { currencyPairAuthFn } = this.props;

    if ("Tn$E4y6Ghj^gUmC#" === value) {
      // alert(';;')
      this.props.history.push(
        // `/auth/swappair/edit/${this.state.pairId}/${this.state.pairCoin}`
        `/auth/swappairedit/${this.state.swappairid}`
      );
      currencyPairAuthFn(true);
    } else {
      currencyPairAuthFn(false);
      toast.error("This is incorrect Password please try again.");
    }
  };

  EnableSwapPairs = (data) => {
    this.props
      .EnableSwapPairs(data)
      .then((res) => {
        // dispatch(currencyPairData(res.data?.data));
        // this.getData();
        let { swapPairFn } = this.props;
        swapPairFn();
      })
      .catch((error) => {
        toast.error(error?.data?.message);
      });
  };

  render() {
    let { swapPairData } = this.props;

    return (
      <Container fixed="lg" className={"py-4"}>
        <Card>
          <Card.Body>
            <Row className="page-header pb-3 justify-content-start">
              <Col>
                <h3 className="page-title">{"Swap Pairs"}</h3>
              </Col>
            </Row>
            <Row flex-wrap>
              {swapPairData?.map((pairItem, key) => {
                return (
                  <>
                    <Col xs={12} xl={6} key={key}>
                      <Card border="warning" className="mb-4">
                        <Card.Body>
                          <Row className="pb-3 justify-content-start">
                            <Col>
                              <h5 className="page-title text-bold">
                                {pairItem.pair.replace("_", "/")}
                              </h5>
                            </Col>
                            <Col className={"d-flex justify-content-end"}>
                              {pairItem.is_sell_status ? (
                                <Button
                                  size="sm"
                                  variant="outline-danger"
                                  className="mr-2"
                                  onClick={() =>
                                    this.EnableSwapPairs({
                                      pairId: pairItem.id,
                                      status: "0",
                                    })
                                  }
                                >
                                  Disable
                                </Button>
                              ) : (
                                <Button
                                  size="sm"
                                  variant="outline-success"
                                  className="mr-2"
                                  onClick={() =>
                                    this.EnableSwapPairs({
                                      pairId: pairItem.id,
                                      status: "1",
                                    })
                                  }
                                >
                                  Enable
                                </Button>
                              )}

                              <Button
                                size="sm"
                                className="custom-btn-outlined-primary"
                                variant="outline-warning"
                                onClick={() =>
                                  this.setState({
                                    showAuthModal: this.state.showAuthModal
                                      ? false
                                      : true,
                                    swappairid: pairItem.id && pairItem.id,
                                  })
                                }
                                disabled={this.state.showAuthModal}
                              >
                                Set Fee
                              </Button>
                            </Col>
                          </Row>
                          <Table bordered size="sm">
                            <tbody>
                              <tr>
                                <td colSpan={2} className="text-center">
                                  <span className=" text-bold">Buy</span>
                                </td>
                                <td colSpan={2} className="text-center">
                                  <span className=" text-bold">Sell</span>
                                </td>
                              </tr>
                              <tr>
                                <td width={"25%"}>
                                  <span className="text-muted">Min Order:</span>
                                </td>
                                <td width={"25%"}>
                                  <span className="text-muted">Fee:</span>
                                </td>
                                <td width={"25%"}>
                                  <span className="text-muted">Min Order:</span>
                                </td>
                                <td width={"25%"}>
                                  <span className="text-muted">Fee:</span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <span className="text-bold">
                                    {MoneyFormatWithoutSmallUnit(
                                      pairItem.min_order
                                    )}
                                  </span>
                                </td>
                                <td>
                                  <span className="text-bold">
                                    {MoneyFormatWithoutSmallUnit(pairItem.fee)}
                                  </span>
                                </td>
                                <td>
                                  <span className="text-bold">
                                    {MoneyFormatWithoutSmallUnit(
                                      pairItem.sell_min_order
                                    )}
                                  </span>
                                </td>
                                <td>
                                  <span className="text-bold">
                                    {MoneyFormatWithoutSmallUnit(
                                      pairItem.sell_fee
                                    )}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={2}>
                                  <span className="text-muted">
                                    Normal Max Order:
                                  </span>
                                </td>

                                <td colSpan={2}>
                                  <span className="text-muted">
                                    Normal Max Order:
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={2} className="text-right">
                                  <span className="text-bold">
                                    {MoneyFormatWithoutSmallUnit(
                                      pairItem.max_order
                                    )}
                                  </span>
                                </td>
                                <td colSpan={2} className="text-right">
                                  <span className="text-bold">
                                    {MoneyFormatWithoutSmallUnit(
                                      pairItem.sell_max_order
                                    )}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={2}>
                                  <span className="text-muted">Buy Price:</span>
                                </td>
                                <td colSpan={2}>
                                  <span className="text-muted">Price:</span>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={2} className="text-right">
                                  <span className="text-bold">
                                    {MoneyFormatWithoutSmallUnit(
                                      pairItem.buy_price
                                    )}
                                  </span>
                                </td>

                                <td colSpan={2} className="text-right">
                                  <span className="text-bold">
                                    {MoneyFormatWithoutSmallUnit(
                                      pairItem.sell_price
                                    )}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={2} className="text-center">
                                  <span className="text-muted">
                                    VIP Max Order:
                                  </span>
                                </td>
                                <td colSpan={2} className="text-center">
                                  <span className="text-muted">
                                    VIP Max Order:
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={2} className="text-center">
                                  {MoneyFormatWithoutSmallUnit(
                                    pairItem.vip_max_order_buy
                                  )}
                                </td>
                                <td colSpan={2} className="text-center">
                                  {MoneyFormatWithoutSmallUnit(
                                    pairItem.vip_max_order_sell
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Card>
                    </Col>
                  </>
                );
              })}
            </Row>
          </Card.Body>
        </Card>
        <CurrencyPairAuthModal
          show={this.state.showAuthModal}
          handleClose={() => {
            this.setState({ showAuthModal: false });
          }}
          getPassword={this.getData}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    swapPairData: state.currency.swapPairData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    swapPairFn: (data) => dispatch(swapPairFn(data)),
    currencyPairFn: (data) => dispatch(currencyPairFn(data)),
    currencyPairAuthFn: (data) => dispatch(currencyPairAuthFn(data)),
    EnableSwapPairs: (data) => dispatch(EnableSwapPairs(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SwapPairs);
