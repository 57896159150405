import React, { useEffect, useState } from "react";
import { Container, Row, Nav, NavItem, NavLink } from "shards-react";
import { Link } from "react-router-dom";
import { PERMISSONS } from "../../../Constants/constant";
import Sell from './sell'
import Buy from './buy'
import Widthdrow from './withdrow'
import Exchange from "./exchange";
import {useLocation} from 'react-router-dom'
import './pnl.css'


const  NavPills = (props)  =>{
  const location = useLocation()
  // console.log("location",location)
  // console.log("props", props)
  const [selected, setSelected] = useState([])
  const [currentpath,setCurrentpath] = useState("")

  useEffect(() => {
    const selectedOptions = []
    PERMISSONS.forEach((element) => {
      selectedOptions.push(element.name)

    });
    setSelected(selectedOptions)
    console.log("selected",selectedOptions)
let pathname = location.pathname
setCurrentpath(pathname)

  }, [])


  const getchilderncomponent = () => {
    let path = props.location.pathname
    switch (path) {
      case "/auth/pnl/withdrow":
        return <Widthdrow />
      case "/auth/pnl/buy":
        return < Buy />
      case "/auth/pnl/exchange":
        return <Sell />
      case "/auth/pnl/sell":
        return<Exchange />
    }
  }


  return (
    <Container fluid className="main-content-container tabs-design mt-5 px-4">
     
      <Nav  justified  >
        {selected.includes("Withdrow") && (
        <NavItem className={`  ${location.pathname === '/auth/pnl/withdrow' ? "pngbackground" : null} `} >  
          <NavLink tag={Link} to="/auth/pnl/withdrow" className={` tabsetting${location.pathname === '/auth/pnl/withdrow' ? "pngcolor" : null} `}>Withdraw</NavLink>
        </NavItem>)}

        <NavItem  className={`${location.pathname === '/auth/pnl/exchanges' ? "pngbackground" : null} `}>
          <NavLink tag={Link} to="/auth/pnl/exchanges" className={`${location.pathname === '/auth/pnl/exchanges' ? "pngcolor" : null} `}>Exchange</NavLink>
        </NavItem>
        <NavItem  className={`${location.pathname === '/auth/pnl/buy' ? "pngbackground" : null} `}>
          <NavLink tag={Link} to="/auth/pnl/buy" className={`  ${location.pathname === '/auth/pnl/buy' ? "pngcolor" : null} `}>Buy </NavLink>
        </NavItem>
        <NavItem  className={`${location.pathname === '/auth/pnl/sell' ? "pngbackground" : null} `}>
          <NavLink tag={Link} to="/auth/pnl/sell" className={`${location.pathname === '/auth/pnl/sell' ? "pngcolor" : null} `}>
            Sell
          </NavLink>
        </NavItem>
      </Nav>
     

    </Container>
  );
}

export default NavPills
