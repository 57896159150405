import React, { Component } from "react";
import { connect } from "react-redux";
import FadeLoader from "react-spinners/FadeLoader";

class LoaderComponent extends Component {
  render() {
    return (
      <div
        className="text-center"
        style={
          this.props.loading === true
            ? {
                height: "100vh",
                zIndex: "99999",
                position: "relative",
                display: "block",
                backgroundColor: "rgba(0,0,0,0.5)",
              }
            : { display: "none" }
        }
      >
        <div style={{ position: "absolute", top: "45%", left: "50%" }}>
          <FadeLoader type="border" color="white" size="2" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loader.loading,
  };
};

export default connect(mapStateToProps, null)(LoaderComponent);
