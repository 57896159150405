import { fetch } from "../axios.service";
import { USERS_BASE_URL, TRADING_BASE_URL, WALLET_BASE_URL } from "../../Constants/constant";


export const getSubadminListApi = (data, options) => {
  return fetch("post", `${USERS_BASE_URL}/admin/subadmin/list`, data, options);
};



export const deleteSubadmin = (data, options) => {
  return fetch("post", `${USERS_BASE_URL}/admin/subadmin/deleteSubAdminUser`, data, options);
};

export const changePassword = (data, options) => {
  return fetch("post", `${USERS_BASE_URL}/admin/subadmin/subadmin_reset_password`, data, options);
};

export const unlockUser = (data, options) => {
  return fetch("put", `${USERS_BASE_URL}/admin/subadmin_activate`, data, options);
};


export const getSingleSubadmin = (data, options) => {
  return fetch("post", `${USERS_BASE_URL}/admin/subadmin/getUser`, data, options);
};


export const updateSubadminApi = (data, options) => {
  return fetch("post", `${USERS_BASE_URL}/admin/subadmin/update`, data, options);
};



export const addSubadminApi = (data, options) => {
  return fetch("post", `${USERS_BASE_URL}/admin/subadmin/add`, data, options);
};
