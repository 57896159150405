import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col } from "shards-react";
import { Table, Form, Button, Dropdown, Card } from "react-bootstrap";
import PageTitle from "../../common/PageTitle";

import { connect } from "react-redux";
import "moment/locale/en-gb";
import "rc-datepicker/lib/style.css";
import "../../../App.css";

import { useForm } from "react-hook-form";
import _ from "lodash/fp";

import {  addSubadminFn } from "../../../Redux/Actions/subadmin/subadmin.action";
import { PERMISSONS } from '../../../Constants/constant'

function AddSubadmin(props) {
  const [permission, setPermission] = useState([])
  const [allotedPermission, setallotedPermission] = useState([])
  const [email, setEmail] = useState("")


  const { register, handleSubmit, watch, errors, setValue } = useForm({
    reValidateMode: "onChange",
    defaultValues: {

    },
  });


  useEffect(() => {
    let id = props.match.params.id
    let data = {
      id: id
    }
  }, [])


  const password = useRef({});
  password.current = watch("password", "");



  const onSubmit = (data) => {
    let { singleSubadminData } = props
    delete data.confirmPassword
    data.role = permission
   // data.accessRole = 1
    props.addSubadminFn(data, props.history)
  };

  let permissionData = []

  const getPermission = (e) => {
    permissionData.push(e.target.checked ? e.target.name : "")
    permission.map((perDta, i) => {
      if (perDta === e.target.name && !e.target.checked) {
        permission.splice(i, 1)
      }
    })
    if (e.target.checked) {
      setPermission(oldData => [...oldData, e.target.name])
    }
  }
  


  // const getData = () => {
  //   console.log(permission, '>>>>>>>>>>>>>>>')
  // }



  return (
    <div>
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <Col lg={12}>
            <PageTitle
              title="Sub-Admin-Management"
              subtitle=""
              className="text-sm-left mb-3"
            />
          </Col>
        </Row>

        <Card>
          <Card.Header></Card.Header>
          <Card.Body>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
              <Col>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>First Name</Form.Label>
                    <input

                      id="firstname"
                      type="firstname"
                      name="firstname"
                      placeholder="First Name"
                      ref={register({
                        required: true,
                        /* maxLength: 20, */
                      })}
                      className="form-control"
                    />

                    {_.get("firstname.type", errors) === "required" && (
                      <p style={{ color: "red" }}>This field is required</p>
                    )}
                  </Form.Group>
                </Col>
               
                <Col>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Last Name</Form.Label>
                    <input

                      id="lastname"
                      type="lastname"
                      name="lastname"
                      placeholder="Last Name"
                      ref={register({
                        required: true,
                        /* maxLength: 20, */
                      })}
                      className="form-control"
                    />

                    {_.get("lastname.type", errors) === "required" && (
                      <p style={{ color: "red" }}>This field is required</p>
                    )}
                  </Form.Group>
                </Col>
               
                <Col>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <input

                      id="email"
                      type="email"
                      name="email"
                      placeholder="Email"
                      ref={register({
                        required: true,
                        /* maxLength: 20, */
                      })}
                      className="form-control"
                    />

                    {_.get("email.type", errors) === "required" && (
                      <p style={{ color: "red" }}>This field is required</p>
                    )}
                  </Form.Group>
                </Col>
              
               
              </Row>
              <Row>
              <Col>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Phone Number</Form.Label>
                    <input

                      id="mobile"
                      type="mobile"
                      name="mobile"
                      placeholder="Phone Number"
                      ref={register({
                        required: true,
                        /* maxLength: 20, */
                      })}
                      className="form-control"
                    />

                    {_.get("mobilenumber.type", errors) === "required" && (
                      <p style={{ color: "red" }}>This field is required</p>
                    )}
                  </Form.Group>
                </Col>
                
                <Col>
                  <Form.Group controlId="formBasicPassword">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      placeholder="New Password"
                      ref={register({
                        required: true,//validation_messages.password_required,
                        /* pattern: {
                            value: /(?=^.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/,
                            message: 'Password must contain atleast 1 small-case letter, 1 Capital letter, 1 digit, 1 special character and the length should be minium 8 characters.'
                        } */
                      })}
                    />

                    {_.get("password.type", errors) === "required" && (
                      <p style={{ color: "red" }}>This field is required</p>
                    )}
                    {/*  {errors.password && <p>{errors.password.message}</p>} */}
                  </Form.Group>
                </Col>
               
                <Col>
                  <Form.Group controlId="formBasicPassword">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      ref={register({
                        required: true,

                        validate: (value) =>
                          value === password.current || "The passwords do not match",

                        //pattern: /^[A-Za-z]+$/i
                      })}
                    />

                    {_.get("confirmPassword.type", errors) === "required" && (
                      <p style={{ color: "red" }}>This field is required</p>
                    )}

                    {errors.confirmPassword && <p style={{ color: "red" }}>{errors.confirmPassword.message}</p>}
                  </Form.Group>


                </Col>
              
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="formBasicPermission">
                    <Form.Label>Permission</Form.Label>

                    <div key={`default-checkbox`} className="mb-3">
                      {
                        PERMISSONS.map((permissionDta,index) => {

                          return (
                            permissionDta.name !== "manage_subadmin" && <Form.Check 
                              type="checkbox"
                              id={`default-checkbox${index}`}
                              label={permissionDta.label}
                              name={permissionDta.name}
                              onClick={getPermission}
                              defaultChecked={allotedPermission != undefined && allotedPermission.find((x) => {
                                return x === permissionDta.name ? true : false
                              })}
                            //ref={register}
                            />
                          )
                        })
                      }

                    </div>

                  </Form.Group>
                </Col>
                <Col>

                </Col>
                <Col></Col>
              </Row>

              <Button variant="primary" type="submit">
                {" "}
                Update{" "}
              </Button>
            </Form>
          </Card.Body>
        </Card>
        <br />
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    //pairListData: state.orders.pairListData,
    singleSubadminData: state.subadmin.singleSubadminData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // getPairListFn: (data) => dispatch(getPairListFn(data))
    addSubadminFn: (data, history) => dispatch(addSubadminFn(data, history))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSubadmin);
