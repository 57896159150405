import React from 'react'
import { Form, Button, Modal, Card, Tabs, Tab, Label, Accordion } from "react-bootstrap";
import { Container, Row, Col } from "shards-react";

export default class UpdateAccDetailModal extends React.Component {


    
    render() {
        
        
        
        return (
            <div>
                <Modal
                    {...this.props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton  onClick={this.props.closeModal}>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Account Info
        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/*   <h4>Centered Modal</h4> */}
                        <Row>

                            <Col >
                                <label class="font-weight-bold">Status</label>
                                <p></p>
                            </Col>

                            <Col >
                                <label class="font-weight-bold">Balance</label>
                                <p></p>
                            </Col>


                            <Col >
                                <label class="font-weight-bold">Type</label>
                                <p></p>
                            </Col>
                            </Row>
                            <br/>
                            
                            <br/>
                            <Row>

                            <Col >
                                <label class="font-weight-bold">Name</label>
                                <p></p>
                            </Col>
                            <Col >
                                <label class="font-weight-bold">Customer ID</label>
                                <p></p>
                            </Col>
                            <Col >
                                <label class="font-weight-bold">Account ID</label>
                                <p></p>
                            </Col>
                            </Row>
                            <br/>
                            <br/>

                            <Row>
                             <Col >
                                <label class="font-weight-bold">Account No.</label>
                                <p></p>
                            </Col>
                            <Col >
                                <label class="font-weight-bold"></label>
                                <p></p>
                            </Col>

                            <Col >
                                <label class="font-weight-bold">Currency</label>
                                <p></p>
                            </Col>
                           
                            </Row>
                            
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.props.confirmAction}>Close</Button>
                       
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}
