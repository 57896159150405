import React from "react";
import {
  Button,
  Card,
  Table,
  Col,
  Container,
  Row,
  Badge,
} from "react-bootstrap";
import {
  currencyPairAuthFn,
  currencyPairFn,
} from "../../../Redux/Actions/currency/currency.action";
import { toggleCurrencyPair } from "../../../Services/API/currency.service";
import PageTitle from "../../common/PageTitle";

import "moment/locale/en-gb";
import "rc-datepicker/lib/style.css";
import { connect } from "react-redux";
import "../../../App.css";
import { toast } from "../../../Components/Toast/toast.component";
import CurrencyPairAuthModal from "./currencyPairAuth.modal";
import { MoneyFormat } from "../../../Constants/constant";
import "./currencyStyle.css";
class Users extends React.Component {
  constructor() {
    super();
    this.state = {
      currencyData: [],
      showLimit: false,
      showAuthModal: false,
      pairId: "",
      pairCoin: "",
    };
  }

  componentDidMount = () => {
    let { currencyPairFn } = this.props;
    currencyPairFn();
  };

  componentWillReceiveProps = (nextProps) => {
    /* if(nextProps.activeCoins !== this.props.activeCoins){
        
    } */
  };

  getData = (value) => {
    let { currencyPairAuthFn } = this.props;

    if ("Tn$E4y6Ghj^gUmC#" === value) {
      // alert(';;')
      // console.log(this.state.pairId, this.state.pairCoin)
      currencyPairAuthFn(true);
      this.props.history.push(
        `/auth/currency-manage/currency-pairs-fee-edit/${this.state.pairId}/${this.state.pairCoin}`
      );
    } else {
      currencyPairAuthFn(false);
      toast.error("This is incorrect Password please try again.");
    }
  };

  toggleCurrencyPairFn = async (data) => {
    return toggleCurrencyPair(data, {
      "api-access-token": this.props.token,
    })
      .then((res) => {
        // dispatch(currencyPairData(res.data?.data));
        // this.getData();
        let { currencyPairFn } = this.props;
        currencyPairFn();
      })
      .catch((error) => {
        toast.error(error?.data?.message);
      });
  };

  render() {
    let { currencyPairData } = this.props;

    return (
      <Container fixed="lg" className={"py-4"}>
        <Card>
          <Card.Body>
            <Row className="page-header pb-3 justify-content-start">
              <Col>
                <h3 className="page-title">{"Currency Pairs"}</h3>
              </Col>
            </Row>
            <Row flex-wrap>
              {currencyPairData?.map((pairItem) => {
                return (
                  <>
                    <Col xs={12} xl={6}>
                      <Card border="warning" className="mb-4">
                        <Card.Body>
                          <Row className=" pb-3 justify-content-start">
                            <Col>
                              <h5 className="page-title">
                                {" "}
                                {pairItem.pair.replace("_", "/")}
                              </h5>
                            </Col>
                            <Col className={"d-flex justify-content-end"}>
                              {pairItem.is_active ? (
                                <Button
                                  size="sm"
                                  variant="outline-danger"
                                  className="mr-2"
                                  onClick={() =>
                                    this.toggleCurrencyPairFn({
                                      pair: pairItem.pair,
                                      status: "0",
                                    })
                                  }
                                >
                                  Disable
                                </Button>
                              ) : (
                                <Button
                                  size="sm"
                                  variant="outline-success"
                                  className="mr-2"
                                  onClick={() =>
                                    this.toggleCurrencyPairFn({
                                      pair: pairItem.pair,
                                      status: "1",
                                    })
                                  }
                                >
                                  Enable
                                </Button>
                              )}

                              <Button
                                size="sm"
                                className="custom-btn-outlined-primary"
                                variant="outline-warning"
                                onClick={() =>
                                  this.setState({
                                    showAuthModal: true,
                                    pairId: pairItem.tarding_pair_id,
                                    pairCoin: pairItem.pair && pairItem.pair,
                                  })
                                }
                                disabled={this.state.showAuthModal}
                              >
                                Set Fee
                              </Button>
                            </Col>
                          </Row>
                          <Table bordered size="sm">
                            <tbody>
                              <tr>
                                <td colSpan={2} className="text-center">
                                  <span className=" text-bold">Buy</span>
                                </td>
                                <td colSpan={2} className="text-center">
                                  <span className=" text-bold">Sell</span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <span className="text-muted">
                                    Trade Limit:
                                  </span>
                                </td>
                                <td>
                                  <span className="text-muted">Trade Fee:</span>
                                </td>
                                <td>
                                  <span className="text-muted">
                                    Trade Limit:
                                  </span>
                                </td>
                                <td>
                                  <span className="text-muted">
                                    Binance Fee:
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <span className="text-bold">
                                    {MoneyFormat(pairItem.minTradeLimit)}
                                  </span>
                                </td>
                                <td>
                                  <span className="text-bold">
                                    {MoneyFormat(pairItem.liquidity_fee)}
                                  </span>
                                </td>
                                <td>
                                  <span className="text-bold">
                                    {MoneyFormat(pairItem.minTradeLimit)}
                                  </span>
                                </td>
                                <td>
                                  <span className="text-bold">
                                    {MoneyFormat(pairItem.binance_fee)}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={2} className="text-center">
                                  <span className="text-muted">Status</span>
                                </td>
                                <td colSpan={2} className="text-center">
                                  {pairItem.is_active ? (
                                    <Badge variant="success">Active</Badge>
                                  ) : (
                                    <Badge variant="danger">Inactive</Badge>
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Card>
                    </Col>
                  </>
                );
              })}
            </Row>
          </Card.Body>
        </Card>

        <CurrencyPairAuthModal
          show={this.state.showAuthModal}
          handleClose={() => {
            this.setState({ showAuthModal: false });
          }}
          getPassword={this.getData}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currencyPairData: state.currency.currencyPairData,
    token: state.auth.tokens,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    currencyPairFn: (data) => dispatch(currencyPairFn(data)),
    currencyPairAuthFn: (data) => dispatch(currencyPairAuthFn(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
