import React from "react";
import { Container, Row, Col } from "shards-react";
import { Card, Button, Form } from "react-bootstrap";
import PageTitle from "../../common/PageTitle";
import { connect } from "react-redux";
import "../../../App.css";
import { toast } from "../../../Components/Toast/toast.component"
import { withdrawAuthFn } from '../../../Redux/Actions/withdrawTransactions/withdrawTrans.action'


class WithdrawRequest extends React.Component {

  constructor() {
    super()
    this.state = {
      withdrawPass: ''
    }
  }
  

  onSubmit = () => {
    let { withdrawAuthFn } = this.props
    this.props.history.push('/auth/withdrawal_transactions')
    if('$gS6#YRbv*fWqXB6' === this.state.withdrawPass){
      withdrawAuthFn(true)
     } else{
      withdrawAuthFn(false)
       toast.error('This is incorrect Password please try again.')
     }
  }



  render() {
    return (
      <div>
        <Container fluid className="main-content-container px-4">
          {/* Page Header */}
          <Row noGutters className="page-header py-4">
            <Col lg={12}>
              <PageTitle
                title="Withdraw - Request"
                subtitle=""
                className="text-sm-left mb-3"
              />
            </Col>
          </Row>

          <Card>
            <Card.Body>
              <Row>
                <Col>
                  <Card.Title>Please Enter Step 1 Password</Card.Title>
                  <Card.Text>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Control type="email" placeholder="Enter email" value={this.state.withdrawPass} onChange={(e) => this.setState({withdrawPass: e.target.value})}/>
                    </Form.Group>
                  </Card.Text>
                  <Button variant="primary" onClick={this.onSubmit}>Submit</Button>
                </Col>
                <Col />
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    withdrawAuth: state.withdraw.withdrawAuth
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    withdrawAuthFn: (data) => dispatch(withdrawAuthFn(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawRequest);
