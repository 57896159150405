import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { connectRouter } from "connected-react-router";
import CounterReducer from './CounterReducer.js';
import Loader from "./Loader/loader.reducer";
import Auth from "./User/auth.reducer";
import User from "./User/user.reducer";
import Dashboard from "./Dashboard/dashobard.reducer";
import Deposite from "./DepositeTrans/Deposite.reducer";
import Orders from "./orders/order.reducer";

import Currency from "./Currency/currency.reducer";
import Withdraw from "./withdrawTransactions/withdrawTrans.reducer";
import Subadmin from "./subadmin/subadmin.reducer";
import Profile from "./profile/profile.reducer"
import Pair from "./pair/pair.reducer";
import Pnl from './pnl/pnl.reducer'
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();



export default combineReducers({
    
    routing: routerReducer,
    loader: Loader,
    auth: Auth,
    user:User,
    dashboard: Dashboard,
    deposite: Deposite,
    orders: Orders,
    currency: Currency,
    withdraw: Withdraw,
    subadmin: Subadmin,
    profile: Profile,
    Pair:Pair,
    Pnl:Pnl,
    router: connectRouter(history),
    
});
