import React from "react";
import { Button, Card, Col, Container, Row, Table } from "react-bootstrap";
import { Icon } from "semantic-ui-react";

import {
  setTradeData,
  tradeSummaryFn,
} from "../../../Redux/Actions/orders/orders.action";

import { getPairListFn } from "../../../Redux/Actions/pair/pair.action";

import "moment/locale/en-gb";
import "rc-datepicker/lib/style.css";
import { connect } from "react-redux";
import { limit } from "../../../Constants/constant";
//import "./Users.css";
import "../../../App.css";

import { CSVLink } from "react-csv";

class Users extends React.Component {
  constructor() {
    super();
    this.state = {
      disabled: true,
      totalDataCount: 0,
      enableFilterInput: true,
      stateCsvData: [],
      page: 1,
      coin: "",
      filterData: {
        page: 1,
        pagination: 1,
        pair: "all",
        search: "",
        status: "",
        type: "buy",
        limit: 10,
      },
      tradeData: [],
      tradeDatacsv: [],
      // add is loading csv
      isLoadingCSV: false,
    };
    this.csvInstance = React.createRef();
  }

  componentDidMount = () => {
    this.getTradingList();
    // getAllDepositesFn();
  };

  getTradingList = () => {
    let { getPairListFn, tradeSummaryFn } = this.props;
    let searchedParams = { page: this.state.page };
    // console.log("getPairListFn",getPairListFn)
    let tradeSummaryData = [];

    getPairListFn(searchedParams).then((res) => {
      // debugger;
      res?.PairList?.map((tradeData) => {
        tradeSummaryFn(tradeData.tarding_pair_key)
          .then((tradeRes) => {
            if (tradeRes) {
              tradeRes.coins = tradeData.tarding_pair_key;
              tradeSummaryData.push(tradeRes);
            }
          })
          .then(() => {
            this.setState({ tradeData: tradeSummaryData });
            let statementCsvData = [];
            tradeSummaryData.map((ele, index) => {
              let data = {
                "#": index + 1,
                Pair: ele.coins,
                Trade: ele.trade !== null ? ele.trade : 0,
                Volume: ele.volume !== null ? ele.volume / 100000000 : 0,
                "Average Price":
                  ele.average_price !== null
                    ? ele.average_price / 100000000
                    : 0,
                "Max Price":
                  ele.maxprice !== null ? ele.maxprice / 100000000 : 0,
                "Min Price":
                  ele.minprice !== null ? ele.minprice / 100000000 : 0,
              };
              statementCsvData.push(data);
            });

            this.setState({ tradeDatacsv: statementCsvData });
          });
      });

      // debugger;
    });
  };

  componentWillReceiveProps = (nextProps) => {
    let statementCsvData = [];
    let pairList = nextProps.pairListData;
    if (
      nextProps.allDeposites !== undefined &&
      nextProps.allDeposites.length > 0
    ) {
      nextProps.allDeposites.map((stateData) => {
        let data = {
          "Tx ID": stateData.tx_id,
          Coin: this.state.coin,
          email: stateData.email,
          Amount: stateData.amount,
          "Address From": stateData.address_from,
          Date: stateData.created_at.split("T")[0],
          Status:
            stateData.status == 1
              ? "Confirmed"
              : stateData.status == 2
              ? "Unconfirmed"
              : stateData.status == 0 && "Pending",
        };

        statementCsvData.push(data);
      });
      this.setState({
        stateCsvData: statementCsvData,
        dataLength: nextProps.customerDataLength,
      });
    } else {
      this.setState({
        stateCsvData: "",
        // dataLength: nextProps.customerDataLength,
      });
    }
  };

  // export as csv function
  handleExport = () => {
    this.setState({
      stateCsvData: this.state.tradeDatacsv,
      isLoadingCSV: false,
    });
  };
  // trigger csv download after fetched data
  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.stateCsvData.length &&
      this.csvInstance.current &&
      this.csvInstance.current.link
    ) {
      setTimeout(() => {
        this.csvInstance.current.link.click();
        this.setState({ stateCsvData: [], isLoadingCSV: false });
      });
    }
  }
  clearSearch = () => {
    let data = {
      page: 1,
      pagination: 1,
      search: "",
      status: "",
      type: "",
      pair: "",
    };
    this.props.depositeFilterDataFn(data);
  };

  pageChange = (e, data) => {
    let page = data.activePage;
    let pageNo = page === 1 ? 0 : (page - 1) * limit;

    let searchedParams = { page: page };
    this.setState({
      page: page,
    });
    setTimeout(() => {
      this.getTradingList();
    });
  };

  render() {
    // alert(totalRecords);
    let { tradeData } = this.state;
    // console.log("tradeData", tradeData)
    return (
      <Container fixed="lg" className={"py-4"}>
        <Card>
          <Card.Body>
            <Row className="page-header pb-3 justify-content-space-between">
              <Col>
                <h3 className="page-title">{"Trade-Summary"}</h3>
              </Col>
              <Col className={"d-flex justify-content-end"}>
                {/* react csv */}
                <>
                  <div onClick={this.handleExport}>
                    <Button variant="white" disabled={this.state.isLoadingCSV}>
                      <Icon name="download" />
                      {this.state.isLoadingCSV ? "Loading..." : "Export as CSV"}
                    </Button>
                  </div>
                  {this.state.stateCsvData.length ? (
                    <CSVLink
                      data={this.state.stateCsvData}
                      filename={`trade_summary${new Date().toISOString()}.csv`}
                      ref={this.csvInstance}
                    />
                  ) : undefined}
                </>
              </Col>
            </Row>
            <Table bordered hover responsive size="sm">
              <thead>
                <tr
                  style={{
                    height: "3rem",
                    color: "#3d5170",
                  }}
                >
                  <th className="text-center align-middle">#</th>
                  <th className="align-middle">Pair</th>
                  <th className="align-middle">Trade</th>
                  <th className="align-middle">Volume</th>
                  <th className="align-middle">Average Price</th>
                  <th className="align-middle">Max Price</th>
                  <th className="align-middle">Min Price</th>
                </tr>
              </thead>
              <tbody>
                {tradeData.length > 0 ? (
                  tradeData.map((tradeDta, key) => {
                    function preciseTodecimal(num) {
                      return num / 100000000;
                    }

                    return (
                      <tr>
                        <td className="text-center align-middle">{key + 1}</td>
                        <td className="align-middle">
                          {tradeDta.coins.replace("_", " / ").toUpperCase()}
                        </td>
                        <td className="align-middle">{tradeDta?.trade}</td>
                        <td className="align-middle">
                          {preciseTodecimal(tradeDta?.volume)}
                        </td>
                        <td className="align-middle">
                          {preciseTodecimal(tradeDta?.average_price)}
                        </td>
                        <td className="align-middle">
                          {preciseTodecimal(tradeDta?.maxprice)}
                        </td>
                        <td className="align-middle">
                          {preciseTodecimal(tradeDta?.minprice)}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr style={{ height: "8rem" }}>
                    <td colSpan="7" className="text-center align-middle">
                      No Record Found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pairListData: state.Pair.pairListData,
    totalRecords: state.Pair.totalRecords,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPairListFn: (data) => dispatch(getPairListFn(data)),
    tradeSummaryFn: (data) => dispatch(tradeSummaryFn(data)),
    setTradeData: (data) => dispatch(setTradeData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
