import * as userService from "../../../Services/API/dashboard.service";
import { toast } from "../../../Components/Toast/toast.component";
import * as types from "../../../Constants/_Types/types.reduxStore";
import { startLoader, stopLoader } from "../Loader/loader.action";
import { BASEURL, SUCCESS_MESSAGE } from "../../../Constants/constant";
import { logoutUser } from "../user/user.action";
//import { reset } from 'redux-form';

const getDashboardCounts = (dashboardCounts) => {
  return {
    type: types.DASHBOARD_COUNTS,
    payload: {
      dashboardCounts: dashboardCounts,
    },
  };
};

const usersList = (usersList, totalRecords) => {
  return {
    type: types.USERS_LIST,
    payload: {
      usersList: usersList,
      totalRecords: totalRecords,
    },
  };
};

const getUser = (getUser, totalRecords) => {
  return {
    type: types.GET_USER,
    payload: {
      getUser: getUser,
      totalRecords: totalRecords,
    },
  };
};

const getActiveUser = (getActiveUser) => {
  return {
    type: types.GET_ACTIVE_USER,
    payload: {
      getActiveUser: getActiveUser,
    },
  };
};

const tradeVolume = (tradeVolume) => {
  return {
    type: types.TRADE_VOLUME,
    payload: {
      tradeVolume: tradeVolume,
    },
  };
};

const tradeByMonth = (tradeByMonth) => {
  return {
    type: types.TRADE_BY_MONTH,
    payload: {
      tradeByMonth: tradeByMonth,
    },
  };
};

const activeCoins = (activeCoins) => {
  return {
    type: types.ACTIVE_COINS,
    payload: {
      activeCoins: activeCoins,
    },
  };
};


const getETHUser = (getEThuser, totalRecords) => {
  return {
    type: types.ETH_WALLET,
    payload: {
      getETHuser: getEThuser,
      totalRecords: totalRecords,
    },
  };
};

const getWalletBalance = (getwalletbinance) => {
  return {
    type: types.WALLET_BINACANCE,
    payload: {
      getwalletbinance: getwalletbinance,
      
    },
  };
};

export function getDashboardCountsFn(history) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .getDashboardCountsApi(options)
      .then((res) => {
        dispatch(getDashboardCounts(res.data?.data));
        dispatch(stopLoader());
      })
      .catch((error) => {
        if (error?.status == 401) {
          dispatch(logoutUser("401"));
        }
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function getUsersFn(data, history) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    let page = { page: 1 };
    dispatch(startLoader());
    return userService

      .getUsersApi(page, options)
      .then((res) => {
      
        dispatch(getUser(res.data.data.listing, res.data.data.totalRecords));
        dispatch(stopLoader());
      })
      .catch((error) => {
        if (error?.status == 401) {
          dispatch(logoutUser("401"));
        }
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}



export function getETHUsersFn(data, history) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    let page = { page: 1 };
    dispatch(startLoader());
    return userService
      .getdashboardbininshApi(options)
      .then((res) => {
        console.log("responsedata", res)
        dispatch(getETHUser(res.data.data.assets))
        dispatch(stopLoader());
      })
      .catch((error) => {
        if (error?.status == 401) {
          dispatch(logoutUser("401"));
        }
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function getWalletBinancedataFn(data, history) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };

    dispatch(startLoader());
    return userService
      .getwalletbinanceApi(options)
      .then((res) => {
      console.log("restttt",res)
        dispatch(getWalletBalance(res.data.data.balances))
        dispatch(stopLoader());
      })
      .catch((error) => {
        if (error?.status == 401) {
          dispatch(logoutUser("401"));
        }
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

// export function getActiveUsersFn(history) {
//   return (dispatch, getState) => {
//     let options = {
//       "api-access-token": getState().auth.tokens,
//     };
//     dispatch(startLoader());
//     return userService
//       .getActiveUsersApi(options)
//       .then((res) => {
//         dispatch(activeUsers(res.data?.data[0]?.activeUser));
//         dispatch(stopLoader());
//       })
//       .catch((error) => {
//         toast.error(error?.data?.message);
//         dispatch(stopLoader());
//       });
//   };
// }

export function getActiveUsersFn(history) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .getActiveUsersApi(options)
      .then((res) => {
        dispatch(getActiveUser(res.data));
        dispatch(stopLoader());
      })
      .catch((error) => {
        if (error?.status == 401) {
          dispatch(logoutUser("401"));
        }
        toast.error(error?.data?.message);

        dispatch(stopLoader());
      });
  };
}

export function tradeVolumeFn(history) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .tradeVolumeApi(options)
      .then((res) => {
        dispatch(tradeVolume(res.data.data));
        dispatch(stopLoader());
      })
      .catch((error) => {
        if (error?.status == 401) {
          dispatch(logoutUser("401"));
        }
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function tradeByMonthFn(history) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .tradeByMonthApi(options)
      .then((res) => {
        dispatch(tradeByMonth(res.data));
        dispatch(stopLoader());
      })
      .catch((error) => {
        if (error?.status == 401) {
          dispatch(logoutUser("401"));
        }
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function activeCoinsFn(history) {
  return (dispatch, getState) => {
    //alert(';;')
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .activeCoinsApi(options)
      .then((res) => {
        dispatch(activeCoins(res.data?.coins));
        dispatch(stopLoader());
        return res.data?.coins;
      })
      .catch((error) => {
        if (error?.status == 401) {
          dispatch(logoutUser("401"));
        }
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}
