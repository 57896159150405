export const START_LOADER = "[LOADER] START LOADER";
export const STOP_LOADER = "[LOADER] STOP LOADER";

export const SIGN_IN = "[AUTH] SIGN_IN";
export const TEMP_SIGN_IN = "[AUTH] TEMP_SIGN_IN";
export const SIGN_OUT = "[AUTH] SIGN_OUT";
export const TWO_FA_AUTHENTICATE = "TWO_FA_AUTHENTICATE";
export const DEVICE_AUTHENTICATE = "DEVICE_AUTHENTICATE";
export const USERS_LIST = "USERS_LIST";
export const ACTIVE_USERS = "ACTIVE_USERS";

export const DASHBOARD_COUNTS = "DASHBOARD_COUNTS";
export const GET_USER = "GET_USER";
export const GET_ACTIVE_USER = "GET_ACTIVE_USER";
export const TRADE_VOLUME = "TRADE_VOLUME";
export const TRADE_BY_MONTH = "TRADE_BY_MONTH";
export const ACTIVE_COINS = "ACTIVE_COINS";

export const STATISTICS_DATA = "STATISTICS_DATA";
export const DOWNLINE = "DOWNLINE";
export const SINGLE_TRADER_DATA = "SINGLE_TRADER_DATA";
export const USER_WITHDRAD_TRANS = "USER_WITHDRAD_TRANS";

export const ETH_BALANCE = "ETH_BALANCE";
export const BTC_BALANCE = "BTC_BALANCE";
export const BCH_BALANCE = "BCH_BALANCE";
export const USDT_BALANCE = "USDT_BALANCE";
export const USER_GET_BALANCE = "USER_GET_BALANCE";
export const FXT_BALANCE = "FXT_BALANCE";
export const ETH_WALLET = "ETH_WALLET";
export const WALLET_BINACANCE = "WALLET_BINACANCE";

export const SUBMITTED_KYC_DATA = "SUBMITTED_KYC_DATA";
export const SINGLE_USER_KYC = "SINGLE_USER_KYC";
export const KYC_COUNT = "KYC_COUNT";

export const DEPOSITE_TRANS = "DEPOSITE_TRANS";
export const PAIR_LIST = "PAIR_LIST";
export const TRADE_SUMMARY = "TRADE_SUMMARY";
export const CURRENCY_DATA = "CURRENCY_DATA";
export const CURRENCY_PAIR_DATA = "CURRENCY_PAIR_DATA";
export const CURRENCY_AUTH = "CURRENCY_AUTH";
export const CURRENCY_PAIR_AUTH = "CURRENCY_PAIR_AUTH";

export const WITHDRAW_LIST = "WITHDRAW_LIST";
export const WITHDRAW_AUTH = "WITHDRAW_AUTH";

export const SUBADMIN_LIST = "SUBADMIN_LIST";
export const SINGLE_SUBADMIN_DATA = "SINGLE_SUBADMIN_DATA";
export const TWO_FASTATUS = "TWO_FASTATUS";
export const GOOGLE_AUTH_DATA = "GOOGLE_AUTH_DATA";
export const ORDER_LISTING = "ORDER_LISTING";
export const PERMISSION_LISTING = "PERMISSION_LISTING";
export const BASE_URL_IMAGE =
  "https://stage-bitqik.s3.ap-south-1.amazonaws.com/";
export const ADMIN_PROFILE_DATA = "ADMIN_PROFILE_DATA";
export const REGISTER_FORM_UPDATE = "REGISTER_FORM_UPDATE";

export const SWAP_PAIR_DATA = "SWAP_PAIR_DATA";
export const EDIT_SWAP_PAIR_DATA = "EDIT_SWAP_PAIR_DATA";
export const SWAP_ORDER_DATA = "SWAP_ORDER_DATA";
export const SWAP_ORDER_SELL_DATA = "SWAP_ORDER_SELL_DATA";

export const SAVE_LOGIN_STATUS = "SAVE_LOGIN_STATUS";

export const BUY_CRYPTO = "BUY_CRYPTO";

export const SELL_CRYPTO = "SELL_CRYPTO";

export const With_draw = "With_draw";

export const Exchange_trade = "Exchange_trade";

