import React, { useEffect, useState } from "react";
import { Container, Row, Nav, NavItem, NavLink } from "shards-react";
import { Link } from "react-router-dom";
import { PERMISSONS } from "../../../Constants/constant";
import Sell from './sell'
import Buy from './buy'
import Widthdrow from './withdrow'
import Exchange from "./exchange";
import Tabss from './tab'


function Index(props) {
  
  const getchilderncomponent = () => {
    let path = props.location.pathname
    switch (path) {
      case "/auth/pnl/withdrow":
        return <Widthdrow />
      case "/auth/pnl/buy":
        return < Buy />
      case "/auth/pnl/exchange":
        return <Sell />
      case "/auth/pnl/sell":
        return<Exchange />
    }
  }


  return (
    <Container fluid className="main-content-container px-4">
      <Tabss />
      {/* <div className="container">
{getchilderncomponent}
      </div> */}
     

    </Container>
  );
}

export default Index
