import React, { Component, lazy, Suspense } from "react";
import { withRouter } from "react-router";
import { Route, Redirect } from "react-router-dom";
import { rootName, BASEURL } from "../Constants/constant";
import { connect } from "react-redux";

import Dashboard from "../Components/pages/Dashboard";
import Traders from "../Components/pages/user/users";
import EditUser from "../Components/pages/user/editUser";
import UserDetails from "../Components/pages/user/userDetails";
import SubmittedKyc from "../Components/pages/Kyc/submittedKyc";
import ApprovedKyc from "../Components/pages/Kyc/approvedKyc/approvedKyc";
import KycDetail from "../Components/pages/Kyc/kycDetail";
import EditApprovedKyc from "../Components/pages/Kyc/approvedKyc/editApprovedKyc";

import DepositeTrans from "../Components/pages/depositeTrans/depositeTransactions";
import OrderListing from "../Components/pages/orderManagement/orderListing";
import TradeSummary from "../Components/pages/orderManagement/tradeSummary";

import Currency from "../Components/pages/currency/currency";
import CurrencyPair from "../Components/pages/currency/currencyPairs";
import CurrencyPairEdit from "../Components/pages/currency/currencyPairEdit";
import CurrencyEditLimit from "../Components/pages/currency/currencyEditLimit";

import WithdrawTransaction from "../Components/pages/Withdraw_transaction/withdrawTransaction";
import WithdrawAuth from "../Components/pages/Withdraw_transaction/withdrawAuth";
import WithdrawGuard from "./Gurads/withdrawAuth.guard";

import CurrencyGuard from "./Gurads/currencyAuth.guard";
import CurrencyPairGuard from "./Gurads/currencyPair.guard";

import Subadmin from "../Components/pages/subAdmin/subadmin";
import AddSubadmin from "../Components/pages/subAdmin/addNewSubadmin";
import EditSubadmin from "../Components/pages/subAdmin/editSubadmin";

import Profile from "../Components/pages/profile/profile";
import ChangePassword from "../Components/pages/profile/changePassword";
import enableGoogleAuth from "../Components/pages/profile/googleAuth";
import AproveKycPage from "../Components/pages/Kyc/approvedKyc/approvekycdetail";
import { PERMISSONS } from "../Constants/constant";
import EditProfile from "../Components/pages/profile/editProfile";
import SwapPairs from "../Components/pages/swap/swapPairs";
import EditSwapPair from "../Components/pages/swap/swapPairEdit";
import SwapOrders from "../Components/pages/swap/swapOrders";
import Binash from '../Components/pages/Binances'
import Binash2 from '../Components/pages/Fireblocks'
import PNN from '../Components/pages/pnl/index'
import Buy from '../Components/pages/pnl/buy'
import Exchange from '../Components/pages/pnl/exchange'
import Sell from '../Components/pages/pnl/sell'
import Withdrow from '../Components/pages/pnl/withdrow'



import EditCurrencyForm from "../Components/pages/currency/editCurrencyForm";
// import ellOrders from "../Components/pages/swap/sellOrders";
import SellOrders from "../Components/pages/swap/sellOrders";


class PrivateRoutes extends Component {
  constructor(props) {
    super(props);
    let dynmRoutes = [];
    dynmRoutes["traders"] = Traders;
    var selectedOptions = [];
    if (this.props.userData.accessRole == 1) {
      selectedOptions = this.props.userData.role.split(",");
    } else {
      PERMISSONS.forEach((element) => {

        selectedOptions.push(element.name);
      });
    }
    // })

    this.state = {
      dynmRoutes: dynmRoutes,
      alottedTabsData: [],
      selectedMenuBar: selectedOptions,
    };
  }

  render() {
    let { userInfo, allTabsData } = this.props;

    return (
      <div className="PrivateArea__content">
        <Route
          path={`${BASEURL}/auth/dashboard`}
          component={Dashboard}
          exact={true}
        />

        {this.state.selectedMenuBar.includes("traders") && (
          <Route
            path={`${BASEURL}/auth/traders/edit/:id`}
            component={EditUser}
            exact={true}
          />
        )}
        {this.state.selectedMenuBar.includes("traders") && (
          <Route
            path={`${BASEURL}/auth/trader-view/:traderId`}
            component={UserDetails}
            exact={true}
          />
        )}
        {this.state.selectedMenuBar.includes("traders") && (
          <Route
            path={`${BASEURL}/auth/traders`}
            component={this.state.dynmRoutes["traders"]}
            exact={true}
          />
        )}
        {this.state.selectedMenuBar.includes("submitted_kyc") && (
          <Route
            path={`${BASEURL}/auth/submittedKyc`}
            component={SubmittedKyc}
            exact={true}
          />
        )}
        {this.state.selectedMenuBar.includes("approved_kyc") && (
          <Route
            path={`${BASEURL}/auth/approvedKyc`}
            component={ApprovedKyc}
            exact={true}
          />
        )}

        {this.state.selectedMenuBar.includes("approved_kyc") && (
          <Route
            path={`${BASEURL}/auth/kycDetail/:userId`}
            component={KycDetail}
            exact={true}
          />
        )}
        {this.state.selectedMenuBar.includes("approved_kyc") && (
          <Route
            path={`${BASEURL}/auth/approvekycDetail/:userId`}
            component={AproveKycPage}
            exact={true}
          />
        )}
        {this.state.selectedMenuBar.includes("approved_kyc") && (
          <Route
            path={`${BASEURL}/auth/member-approved-edit/:userId`}
            component={EditApprovedKyc}
            exact={true}
          />
        )}
        {this.state.selectedMenuBar.includes("deposit_transaction") && (
          <Route
            path={`${BASEURL}/auth/deposite_transaction`}
            component={DepositeTrans}
            exact={true}
          />
        )}
        {this.state.selectedMenuBar.includes("orders_management") && (
          <Route
            path={`${BASEURL}/auth/order-list`}
            component={OrderListing}
            exact={true}
          />
        )}
        {this.state.selectedMenuBar.includes("trade_summary") && (
          <Route
            path={`${BASEURL}/auth/trade-summary`}
            component={TradeSummary}
            exact={true}
          />
        )}
        {this.state.selectedMenuBar.includes("currency_management") && (
          <Route
            path={`${BASEURL}/auth/currency-manage`}
            component={Currency}
            exact={true}
          />
        )}
        {this.state.selectedMenuBar.includes("currency_pairs") && (
          <Route
            path={`${BASEURL}/auth/currency-pairs`}
            component={CurrencyPair}
            exact={true}
          />
        )}
        {this.state.selectedMenuBar.includes("currency_pairs") && (
          <CurrencyPairGuard
            path={`${BASEURL}/auth/currency-manage/currency-pairs-fee-edit/:pairId/:pairCoin`}
            component={EditCurrencyForm}
            exact={true}
          />
        )}
        {this.state.selectedMenuBar.includes("currency_management") && (
          <CurrencyGuard
            path={`${BASEURL}/auth/currency-manage/edit-fee-limit/:coinname`}
            component={CurrencyEditLimit}
            exact={true}
          />
        )}
        {this.state.selectedMenuBar.includes("withdraw_transaction") && (
          <WithdrawGuard
            path={`${BASEURL}/auth/withdrawal_transactions`}
            component={WithdrawTransaction}
            exact={true}
          />
        )}
        {this.state.selectedMenuBar.includes("withdraw_transaction") && (
          <Route
            path={`${BASEURL}/auth/withdrawal_request`}
            component={WithdrawAuth}
            exact={true}
          />
        )}
        {this.state.selectedMenuBar.includes("manage_subadmin") && (
          <Route
            path={`${BASEURL}/auth/sub-admin-management/sub-admin-users`}
            component={Subadmin}
            exact={true}
          />
        )}
        {this.state.selectedMenuBar.includes("manage_subadmin") && (
          <Route
            path={`${BASEURL}/auth/sub-admin-management/sub-admin-new`}
            component={AddSubadmin}
            exact={true}
          />
        )}
        {this.state.selectedMenuBar.includes("manage_subadmin") && (
          <Route
            path={`${BASEURL}/auth/sub-admin-management/sub-admin-edit/:id`}
            component={EditSubadmin}
            exact={true}
          />
        )}
        <Route
          path={`${BASEURL}/auth/admin-dashboard-section/admin-profile`}
          component={Profile}
          exact={true}
        />

        <Route
          path={`${BASEURL}/auth/admin-dashboard-section/change-password`}
          component={ChangePassword}
          exact={true}
        />

        <Route
          path={`${BASEURL}/auth/admin-dashboard-section/google-authentication-setting`}
          component={enableGoogleAuth}
          exact={true}
        />

        <Route
          path={`${BASEURL}/auth/admin-dashboard-section/edit-profile`}
          component={EditProfile}
          exact={true}
        />
        {this.state.selectedMenuBar.includes("sell_orders") && (
          <Route
            path={`${BASEURL}/auth/sellorder`}
            component={SellOrders}
            exact={true}
          />
        )}
        {this.state.selectedMenuBar.includes("swap_orders") && (
          <Route
            path={`${BASEURL}/auth/swappair`}
            component={SwapPairs}
            exact={true}
          />
        )}
        {this.state.selectedMenuBar.includes("swap_orders") && (
          <Route
            path={`${BASEURL}/auth/swappairedit/:swappairid`}
            component={EditSwapPair}
            exact={true}
          />
        )}
        {this.state.selectedMenuBar.includes("swap_orders") && (
          <Route
            path={`${BASEURL}/auth/swaporder`}
            component={SwapOrders}
            exact={true}
          />
        )}
        {this.state.selectedMenuBar.includes("binance_balance") && (
          <Route
            path={`${BASEURL}/auth/fireblocks`}
            component={Binash2}
            exact={true}
          />
        )}
        {this.state.selectedMenuBar.includes("fireblocks_balance") && (
          <Route
            path={`${BASEURL}/auth/binance`}
            component={Binash}
            exact={true}
          />
        )}

        {/* {this.state.selectedMenuBar.includes("PNL") && (
          <Route
            path={`${BASEURL}/auth/pnl`}
            component={PNN}
            exact={true}
          />
        )} */}

        {this.state.selectedMenuBar.includes("Withdrow") && (
        <Route
          path={`${BASEURL}/auth/pnl/withdrow`}
          component={Withdrow}
          exact={true}
        />)}
        {this.state.selectedMenuBar.includes("Buy") && (
          <Route
          path={`${BASEURL}/auth/pnl/buy`}
          component={Buy}
          exact={true} />

          )}

        {this.state.selectedMenuBar.includes("Sell") && (
          <Route
          path={`${BASEURL}/auth/pnl/sell`}
          component={Sell}
          exact={true}
           />)}

{this.state.selectedMenuBar.includes("Exchnage") && (
          <Route
          path={`${BASEURL}/auth/pnl/exchanges`}
          component={Exchange}
          exact={true}
           />)}




        {/* <Route path="">
            <Redirect to={`${BASEURL}/auth/dashboard`} />
        </Route> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: require('jsonwebtoken').decode(state.auth.tokens),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PrivateRoutes));
