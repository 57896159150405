import * as userService from "../../../Services/API/orders.service";
import { toast } from "../../../Components/Toast/toast.component";
import * as types from "../../../Constants/_Types/types.reduxStore";
import { startLoader, stopLoader } from "../Loader/loader.action";
import { BASEURL, SUCCESS_MESSAGE } from "../../../Constants/constant";
import { logoutUser } from "../user/user.action";
//import { reset } from 'redux-form';

const getOrderListing = (orderListing, totalRecords) => {
  return {
    type: types.ORDER_LISTING,
    payload: {
      orderListData: orderListing,
      totalRecords: totalRecords,
    },
  };
};
export const getTradeSummary = (tradeSummaryData) => {
  return {
    type: types.TRADE_SUMMARY,
    payload: {
      tradeSummaryData: tradeSummaryData,
    },
  };
};

export function tradeSummaryFn(type) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    // let tradeData = [];
// console.log("action call")
    // dispatch(startLoader());
    return userService
      .tradeSummaryApi(type, options)
      .then((res) => {
        // console.log("restrasummary",res)
        // dispatch(getTradeSummary(res.data[0]));
        // debugger;
        dispatch(stopLoader());
        return res.data.data[0];
      })
      .catch((error) => {
        if (error?.status == 401) {
          dispatch(logoutUser("401"));
        }
        // toast.error(error?.data?.message);
        // dispatch(stopLoader());
      });
  };
}

export function setTradeData(data) {
  return (dispatch, getState) => {
    dispatch(getTradeSummary(data));
  };
}

export function depositeFilterDataFn(filterData) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .getorderListPair(filterData, options)
      .then((res) => {
        dispatch(
          getOrderListing(res.data?.data?.orderlist, res.data?.data?.totalItems)
        );
        dispatch(stopLoader());
        return res.data.data;
      })
      .catch((error) => {
        if (error?.status == 401) {
          dispatch(logoutUser("401"));
        }
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function csvDataFn(filterData) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    // dispatch(startLoader());
    return userService
      .getorderListPair(filterData, options)
      .then((res) => {
        // dispatch(stopLoader());
        return res.data.data;
      })
      .catch((error) => {
        if (error?.status == 401) {
          dispatch(logoutUser("401"));
        }
        toast.error(error?.data?.message);
        // dispatch(stopLoader());
      });
  };
}
