import * as types from "../../../Constants/_Types/types.reduxStore";

const initialState = {
  withdrawListData: [],
  withdrawAuth: false
};

export default function Withdraw(state = initialState, action) {
  switch (action.type) {
    case types.WITHDRAW_LIST:
      return { ...state, withdrawListData: action.payload.withdrawListData, totalRecords:action.payload.totalRecords };

    case types.WITHDRAW_AUTH:
      return { ...state, withdrawAuth: action.payload.withdrawAuth };

    default:
      return state;
  }
}
