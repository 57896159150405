import React from "react";
import { Button } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { Icon } from "semantic-ui-react";

export default class ImportCsv extends React.Component {
  render() {
    let { stateCsvData } = this.props;

    return (
      <>
        <CSVLink
          data={
            this.props.stateCsvData && this.props.stateCsvData != undefined
              ? this.props.stateCsvData
              : [{}]
          }
          target="_blank"
          filename={`${
            this.props.file_name ? this.props.file_name.replace(" ", "_") : ""
          }_${new Date().toISOString()}.csv`}
          onClick={(event) => {
            return stateCsvData.length > 0 ? true : false;
          }}
        >
          <Button variant="white">
            <Icon name="download" />
            Export as CSV
          </Button>
        </CSVLink>
      </>
    );
  }
}
