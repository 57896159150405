import React from "react";
import {
  Modal,
  Button,
  InputGroup,
  FormControl,
  Row,
  Col,
} from "react-bootstrap";

export default class CurrencyAuthModal extends React.Component {
  constructor() {
    super();
    this.state = {
      authPassword: "",
    };
  }

  handleAuth = () => {
    this.props.getPassword(this.state.authPassword);
  };

  render() {
    return (
      <>
        <Modal
          show={this.props.show}
          onHide={this.props.handleClose}
          backdrop={this.props.backdrop}
        >
          {/* <Modal.Header>
            <Modal.Title className="mb-0"></Modal.Title>
          </Modal.Header> */}
          <Modal.Body>
            <Row>
              <Col>
                <h5 className="page-title mb-2">Please Enter Password</h5>
              </Col>
            </Row>
            <InputGroup className="mb-4">
              <FormControl
                aria-label="Default"
                aria-describedby="inputGroup-sizing-default"
                type="password"
                value={this.state.authPassword}
                onChange={(e) =>
                  this.setState({ authPassword: e.target.value })
                }
              />
            </InputGroup>
            <Row>
              <Col className={"text-center"}>
                <Button variant="secondary" onClick={this.props.handleClose}>
                  Close
                </Button>
                <Button
                  variant="primary"
                  className="ml-2"
                  onClick={this.handleAuth}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
