import React, { useState } from "react";
import { Button, Image, Modal } from "react-bootstrap";
import { Input } from "semantic-ui-react";

function RefSellOrderModal({ show, setShow, verify, data }) {
  const [input, setInput] = useState("");
  return (
    <Modal
      show={show}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="d-flex justify-content-end pt-1 pr-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          onClick={() => setShow()}
          style={{ cursor: "pointer" }}
          fill="currentColor"
          class="bi bi-x-circle"
          viewBox="0 0 16 16"
        >
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
        </svg>
      </div>
      <Modal.Body className="d-flex justify-content-center">
        <>
          <Input
            onChange={(e) => setInput(e.target.value)}
            placeholder="Reference number"
          />{" "}
          <br />
          <Button
            onClick={() => {
              input && verify({ orderId: data?.orderId, refNumber: input });
              setShow();
            }}
          >
            Submit
          </Button>
        </>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={() => setShow()}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
}

export default RefSellOrderModal;
