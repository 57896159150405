import React, {useEffect, useRef, useState } from 'react';
import { Container, Row, Col } from 'shards-react';
import { Table, Form, Button, Dropdown, Card } from 'react-bootstrap';
import PageTitle from '../../common/PageTitle';

import { connect } from 'react-redux';
import 'moment/locale/en-gb';
import 'rc-datepicker/lib/style.css';
import '../../../App.css';

import { useForm } from 'react-hook-form';
import _ from 'lodash/fp';

import { getSubadminListFn } from '../../../Redux/Actions/subadmin/subadmin.action';
import { changePasswordFn,updateAdminProfile } from '../../../Redux/Actions/profile/profile.actions';
import { PERMISSONS } from '../../../Constants/constant';
import HidePassword from './images/eye_close.png';
import showPassword from './images/eye_open.png';
import { useHistory } from 'react-router';
import './profile.css';

function EditProfile(props) {
    const history = useHistory();
    const [permission, setPermission] = useState([]);
    const [oldpassword, setshowoldpassword] = useState(false);
    const [newpassword, setnewpassword] = useState(false);
    const [confirmpwd, setconfirmpaswd] = useState(false);
  
    const { register, handleSubmit, errors,setValue } = useForm({
      reValidateMode: 'onChange',
      defaultValues: {},
    });
  
    useEffect(() => {
     
        setValue("firstname", props.adminData?.firstname);
        setValue("mobile", props.adminData?.mobile);
        setValue("lastname", props.adminData?.lastname)
    
    }, [props.adminData])
    
    const onSubmit = (data) => {
      props.updateAdminProfile(data,history);
    };

    return (
      <div>
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <Col lg={12}>
              <PageTitle
                title="Edit Profile"
                subtitle=""
                className="text-sm-left mb-3"
              />
            </Col>
          </Row>
  
          <Card>
            <Card.Header></Card.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col>
                    <Col>
                      <Form.Label>First Name</Form.Label>
                      <Form.Group controlId="formBasicPassword">
                        <Form.Control
                          type="firstname"
                          name="firstname"
                          placeholder="First Name"
                          ref={register({
                            required: true, //validation_messages.password_required,
                            /*  pattern: {
                                                           value: /(?=^.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/,
                                                           message: 'Password must contain atleast 1 small-case letter, 1 Capital letter, 1 digit, 1 special character and the length should be minium 8 characters.'
                                                       } */
                          })}
                        />
                       
                        {_.get('firstname.type', errors) === 'required' && (
                          <p style={{ color: 'red' }}>This field is required</p>
                        )}
                      </Form.Group>
                    </Col>{' '}
                  </Col>
  
                  <Col>
                <Form.Label>Last Name</Form.Label>
                <Form.Group controlId="formBasicPassword">
                    <Form.Control
                      type="lastname"
                      name="lastname"
                      placeholder="Last Name"
                      ref={register({
                        required: true,//validation_messages.password_required,
                        /* pattern: {
                            value: /(?=^.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/,
                            message: 'Password must contain atleast 1 small-case letter, 1 Capital letter, 1 digit, 1 special character and the length should be minium 8 characters.'
                        } */
                      })}
                    />

                    {_.get("lastname.type", errors) === "required" && (
                      <p style={{ color: "red" }}>This field is required</p>
                    )}
                    {/*  {errors.password && <p>{errors.password.message}</p>} */}
               </Form.Group>
                </Col>
                  
                  <Col>
                    <Form.Label>Mobile</Form.Label>
                    <Form.Group controlId="formBasicPassword">
                    <Form.Control
                      type="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      ref={register({
                        required: true,//validation_messages.password_required,
                        /* pattern: {
                            value: /(?=^.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/,
                            message: 'Password must contain atleast 1 small-case letter, 1 Capital letter, 1 digit, 1 special character and the length should be minium 8 characters.'
                        } */
                      })}
                    />

                    {_.get("mobile.type", errors) === "required" && (
                      <p style={{ color: "red" }}>This field is required</p>
                    )}
                    {/*  {errors.password && <p>{errors.password.message}</p>} */}
               </Form.Group>
                </Col>
                </Row>
  
                <Col>
                  <Button variant="primary" type="submit">
                    {' '}
                    Update{' '}
                  </Button>
                </Col>
              </Form>
            </Card.Body>
          </Card>
        </Container>
      </div>
    );
  }
  
const mapStateToProps = (state) => {
      
      return {
        adminData: state.profile.adminData
      //pairListData: state.orders.pairListData,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      // getPairListFn: (data) => dispatch(getPairListFn(data))
        changePasswordFn: (data) => dispatch(changePasswordFn(data)),
        updateAdminProfile:(data,history) => dispatch(updateAdminProfile(data,history)),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);